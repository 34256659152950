import { Player, PlayerRef } from "@remotion/player";
import {
  CaptionPlayerParams,
  CaptionStyleConfig,
  PropertyValue,
} from "@/src/types";
import { CaptionPlayerComponent } from "./component";
import { Loader2Icon } from "lucide-react";
import { useRef } from "react";
import { CaptionPlayerControls } from "./controls";
import { usePlayer } from "../../helpers/player.hook";
import { CaptionPlayerInteractiveLayer } from "./interactive-layer";
import { useMediaQuery } from "react-responsive";

type Props = {
  captionPlayerParams: CaptionPlayerParams;
  captionStyleConfig: CaptionStyleConfig;
  captionStylePropertyValues: Record<string, PropertyValue>;
};

const FPS = 30;

export function CaptionPlayer({
  captionPlayerParams,
  captionStyleConfig,
  captionStylePropertyValues,
}: Props) {
  const durationInFrames = Math.floor(
    (captionPlayerParams.videoDuration / 1000) * FPS
  );

  const playerRef = useRef<PlayerRef>(null);

  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  const { isFullscreen } = usePlayer(playerRef, { durationInFrames, fps: FPS });

  return (
    <div className="flex flex-col items-stretch">
      <div className="opacity-70 mb-2 text-center">Preview</div>
      <div className="relative outline outline-black/20">
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex flex-col justify-center items-center gap-2">
            <Loader2Icon className="w-6 h-6 animate-spin" />
            <div>Loading preview</div>
          </div>
        </div>
        <Player
          ref={playerRef}
          component={CaptionPlayerComponent}
          inputProps={{
            captionPlayerParams,
            captionStyleConfig,
            captionStylePropertyValues,
            videoRenderMode: "default",
          }}
          durationInFrames={durationInFrames}
          compositionWidth={1080}
          compositionHeight={1920}
          fps={FPS}
          className="w-full md:min-w-[360px]"
          style={{
            width: `100%`,
          }}
          controls={isFullscreen || isMobile}
        />
        {!isFullscreen && (
          <CaptionPlayerInteractiveLayer
            playerRef={playerRef}
            durationInFrames={durationInFrames}
            fps={FPS}
          />
        )}
      </div>
      {!isFullscreen && !isMobile && (
        <CaptionPlayerControls
          playerRef={playerRef}
          durationInFrames={durationInFrames}
          fps={FPS}
        />
      )}
    </div>
  );
}

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../stores/auth.store";

export function UnuthenticatedDialog() {
  const loggedIn = useAuthStore((state) => state.loggedIn);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // On change
    if (!loggedIn) {
      setOpen(true);
    }
  }, [loggedIn]);

  const handleLogin = () => {
    // Handle login
    setOpen(false);
    navigate("/");
  };

  return (
    <AlertDialog open={open} defaultOpen={false} onOpenChange={setOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>You're not logged in</AlertDialogTitle>
          <AlertDialogDescription>
            You need to be logged in to perform this action.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction onClick={handleLogin}>Log in</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

import { create } from "zustand";
import { subscribeWithSelector, devtools } from "zustand/middleware";
import fastDeepEqual from "fast-deep-equal";
import { debounce, isEmpty } from "radash";

import type {} from "@redux-devtools/extension"; // required for devtools typing
import { Nullable, PropertyValue, VideoAsset } from "../types";
import {
  UnauthorizedError,
  exportProject,
  saveCaptionStyleSettings,
} from "../api/lit-captions";
import { migrateCaptionStylePropertyOverrides } from "../caption-styles/migrate-caption-style-property-overrides";
import { useAuthStore } from "./auth.store";

type EditorVariables = {
  id?: number;
  playerParams?: {
    transcript: Nullable<any>;
    videoUrl: Nullable<string>;
    videoAsset: Nullable<VideoAsset>;
  };
  captionStyle?: string;
  captionStylePropertyOverrides: Record<string, PropertyValue>;
};

type EditorState = EditorVariables & ReturnType<typeof getActions>;

function getInitialVariables(): EditorVariables {
  return {
    id: undefined,
    playerParams: undefined,
    captionStyle: undefined,
    captionStylePropertyOverrides: {},
  };
}

function getActions(set, get) {
  return {
    setPlayerParams: ({
      id,
      playerParams,
    }: {
      id: number;
      playerParams: EditorVariables["playerParams"];
    }) => {
      set({ id, playerParams });
    },
    changeCaptionStyle: (captionStyle: string) => {
      const currentCaptionStyle = get().captionStyle;
      if (currentCaptionStyle === captionStyle) {
        return;
      }
      const currentCaptionStylePropertyOverrides =
        get().captionStylePropertyOverrides;

      // Need to update caption style property overrides
      //   const captionStylePropertyOverrides =
      //     migrateCaptionStylePropertyOverrides(
      //       currentCaptionStyle,
      //       captionStyle,
      //       currentCaptionStylePropertyOverrides
      //     );
      set({ captionStyle, captionStylePropertyOverrides: {} });
    },
    resetStore: () => {
      // May have to deal with pending updates before this
      set(getInitialVariables());
    },
    setCaptionStylePropertyOverrides: (
      values: Record<string, PropertyValue>
    ) => {
      set({ captionStylePropertyOverrides: values });
    },
    flushChanges: async () => {
      debouncedSaveChanges.cancel();
      await saveChanges();
    },
  };
}

export const useEditorStore = create<EditorState>()(
  devtools(
    subscribeWithSelector((set, get) => ({
      ...getInitialVariables(),
      ...getActions(set, get),
    }))
  )
);

const saveChanges = async () => {
  // save changes
  console.log("Saving changes");
  const { id, captionStyle, captionStylePropertyOverrides } =
    useEditorStore.getState();
  if (!id) {
    console.log("No project id actively editing");
    return;
  }
  if (!captionStyle) {
    console.error("No caption style");
    return;
  }
  let captionStyleSettings: Nullable<typeof captionStylePropertyOverrides> =
    null;
  if (!isEmpty(captionStylePropertyOverrides)) {
    captionStyleSettings = captionStylePropertyOverrides;
  }
  try {
    await saveCaptionStyleSettings({
      projectId: id,
      captionStyle,
      captionStyleSettings,
    });
  } catch (error) {
    console.error("Failed to save caption style settings", error);
    if (error instanceof UnauthorizedError) {
      useAuthStore.setState({ loggedIn: false });
    }
    throw error;
  }
};

const debouncedSaveChanges = debounce({ delay: 500 }, async () => {
  // save changes
  await saveChanges();
});

useEditorStore.subscribe(
  (state) => ({
    captionStyle: state.captionStyle,
    captionStylePropertyOverrides: state.captionStylePropertyOverrides,
  }),
  (state) => {
    console.log("useEditorStore changed", state);
    debouncedSaveChanges();
  },
  { equalityFn: fastDeepEqual }
);

// export function useSlideshowPreviewStoreHasChanges() {
//   const slideshow = useEditorStore((state) => state.slideshow);
//   const sizeFormat = useEditorStore((state) => state.sizeFormat);
//   return sizeFormat !== slideshow?.settings.sizeFormat;
// }

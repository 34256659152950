import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { canExportProject, exportProject } from "@/src/api/lit-captions";
import { CHECKOUT_SESSION_URL } from "@/src/helpers/billing";
import { useManagedApi } from "@/src/helpers/managed-api.hook";
import { useEditorStore } from "@/src/stores/editor-store";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  trigger: React.ReactNode;
};

function toMinutesString(minutes: number) {
  return minutes === 1 ? "1 minute" : `${minutes} minutes`;
}

export function ExportConfirmationDialog({ trigger }: Props) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const id = useEditorStore((state) => state.id);
  const flushChanges = useEditorStore((state) => state.flushChanges);

  const canExportProjectM = useManagedApi(canExportProject);
  const exportProjectM = useManagedApi(exportProject);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [exportEligibilityState, setExportEligibilityState] = useState<{
    canExport: boolean;
    minutesRemaining: number;
    minutesToConsume: number;
  } | null>(null);

  const handleExport = async () => {
    await flushChanges();
    const exportId = await exportProjectM(id);
    setDialogOpen(false);

    console.log("Exported video", exportId);
    navigate(`/exports/${exportId}`);
  };

  const dialogContent = (() => {
    if (!exportEligibilityState) {
      return <Loader2 className="animate-spin h-8 w-8" />;
    }
    if (!exportEligibilityState.canExport) {
      return (
        <p className="my-4">
          You do not have enough minutes to export this video.
          <br />
          <br />
          You have{" "}
          <strong>
            {toMinutesString(exportEligibilityState.minutesRemaining)}
          </strong>{" "}
          remaining.
          <br />
          You need{" "}
          <strong>
            {toMinutesString(exportEligibilityState.minutesToConsume)}
          </strong>{" "}
          for this export.
        </p>
      );
    }
    if (exportEligibilityState.canExport) {
      return (
        <p className="my-4">
          This export will consume{" "}
          <strong>
            {toMinutesString(exportEligibilityState.minutesToConsume)}
          </strong>
          .
          <br />
          You have{" "}
          <strong>
            {toMinutesString(exportEligibilityState.minutesRemaining)}
          </strong>{" "}
          remaining.
        </p>
      );
    }
  })();

  const dialogFooterContent = (() => {
    if (!exportEligibilityState) {
      return null;
    }
    if (!exportEligibilityState.canExport) {
      return (
        <>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <form action={CHECKOUT_SESSION_URL} method="POST">
            <Button variant="default" onClick={() => setDialogOpen(false)}>
              Buy minutes
            </Button>
          </form>
        </>
      );
    }
    if (exportEligibilityState.canExport) {
      return (
        <>
          <Button variant="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button type="button" onClick={handleExport}>
            Confirm Export
          </Button>
        </>
      );
    }
  })();

  const onExportTriggerClick = async () => {
    await flushChanges();
    if (!id) {
      throw new Error("Project ID is missing");
    }
    try {
      setExportEligibilityState(await canExportProjectM(id));
    } catch (e) {
      console.error("Failed to check export eligibility", e);
      toast({
        variant: "destructive",
        title: "Failed to check export eligibility",
      });
      setDialogOpen(false);
    }
  };

  return (
    <Dialog open={dialogOpen} onOpenChange={(isOpen) => setDialogOpen(isOpen)}>
      <DialogTrigger onClick={onExportTriggerClick} asChild>
        {trigger}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Export Video</DialogTitle>
        </DialogHeader>
        {dialogContent}
        <DialogFooter className="gap-2 sm:gap-0">
          {dialogFooterContent}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

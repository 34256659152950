import { Logo } from "../components/logo";
import { Link } from "react-router-dom";
import LayoutWithSidebar from "./sidebar";

type Props = {
  activePage?: "projects" | "exports";
  children: React.ReactNode;
};

type LayoutWithoutSidebarProps = {
  children: React.ReactNode;
};

function LayoutWithoutSidebar({ children }: LayoutWithoutSidebarProps) {
  return (
    <div className="flex flex-col w-full h-svh font-outfit">
      <div className="h-[60px] flex items-center justify-center">
        <Link to="/">
          <Logo withText />
        </Link>
      </div>
      {children}
    </div>
  );
}

export default function Layout({ activePage, children }: Props) {
  const showSidebar = !!activePage;

  return showSidebar ? (
    <LayoutWithSidebar activePage={activePage} children={children} />
  ) : (
    <LayoutWithoutSidebar children={children} />
  );
}

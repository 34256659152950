import { create } from "zustand";
import { getUser } from "../api/lit-captions";
import { User, UserCredits } from "../types";
import { devtools, subscribeWithSelector } from "zustand/middleware";
import { identifyPostHog } from "../helpers/posthog";
import fastDeepEqual from "fast-deep-equal";
import { identifyGtm } from "../helpers/gtm";

type UserVariables = {
  id?: number;
  user?: User;
  credits?: UserCredits;
};

type UserState = UserVariables & ReturnType<typeof getActions>;

function getInitialVariables(): UserVariables {
  return {};
}

function getActions(set, get) {
  return {
    fetchUser: async () => {
      try {
        const { user, credits } = await getUser();
        set({ user, credits });
      } catch (error) {
        console.error(error);
      }
    },
    resetStore: () => {
      set(getInitialVariables());
    },
  };
}

export const useUserStore = create<UserState>()(
  devtools(
    subscribeWithSelector((set, get) => ({
      ...getInitialVariables(),
      ...getActions(set, get),
    }))
  )
);

// track on posthog when user is authenticated
useUserStore.subscribe(
  ({ user }) => {
    return user
      ? {
          id: user.id,
          email: user.email,
          name: user.name,
        }
      : null;
  },
  (userInfo) => {
    if (userInfo) {
      identifyPostHog(userInfo.id, {
        email: userInfo.email,
        name: userInfo.name,
      });
      identifyGtm(userInfo.id, {
        email: userInfo.email,
        name: userInfo.name,
      });
    }
  },
  { equalityFn: fastDeepEqual }
);

import { SequencesRenderer } from "@/src/components/caption-renderer/sequences-renderer";
import {
  CaptionStyleComponentProps,
  CaptionStyleSpec,
  ColorPropertyValue,
  GradientColorPropertyValue,
  PropertyValue,
} from "@/src/types";
import { useMemo } from "react";
import Color from "color";

const Properties = {
  textColor: {
    label: "Text Gradient",
    propertyType: "gradientColor",
  },
  bgColor: {
    label: "Backdrop",
    propertyType: "color",
  },
} as const;
type Property = keyof typeof Properties;

export const GradientPhrasesWordsSpec = {
  properties: Properties,
} satisfies CaptionStyleSpec<Property>;

export function GradientPhrasesWordsDefaultValues(): Record<
  Property,
  PropertyValue
> {
  return {
    textColor: [
      "#6366f1", // indigo-500
      "#ec4899", // pink-500
    ],
    bgColor: "#000000",
  };
}

export function GradientPhrasesWordsComponent({
  values,
  phrases,
}: CaptionStyleComponentProps<Property>) {
  const textGradientColors = values.textColor as GradientColorPropertyValue;
  const bgColor = values.bgColor as ColorPropertyValue;
  if (!textGradientColors || !bgColor) {
    console.error("Missing required options");
    return null;
  }
  const bgRgb = useMemo(() => {
    const rgb = Color(bgColor).rgb().array();
    if (!rgb) {
      console.error("Invalid bg color");
      return null;
    }
    return rgb;
  }, [bgColor]);

  const bgFromRgba = `rgba(${bgRgb?.join(", ")}, 1)`;
  const bgViaRgba = `rgba(${bgRgb?.join(", ")}, 0.75)`;

  return (
    <SequencesRenderer
      sequences={phrases}
      rootClassName=""
      sequenceTextClassName="text-center px-10 py-10 rounded-[32px] leading-[1.2] bg-gradient-to-r bg-clip-text text-transparent"
      sequenceTextStyles={{
        // @ts-ignore
        "--tw-gradient-from": textGradientColors[0],
        // @ts-ignore
        "--tw-gradient-to": textGradientColors[1],
        // @ts-ignore
        "--tw-gradient-stops": `var(--tw-gradient-from), var(--tw-gradient-to)`,
      }}
      wrapperClassName="px-8 py-64 bg-gradient-to-t via-80% to-transparent"
      wrapperStyles={{
        // @ts-ignore
        "--tw-gradient-from": bgFromRgba,
        // @ts-ignore
        "--tw-gradient-stops": `var(--tw-gradient-from), ${bgViaRgba} var(--tw-gradient-via-position), var(--tw-gradient-to)`,
      }}
      renderWord={(word, index, highlighted, show) => (
        <span
          key={index}
          className="whitespace-pre-wrap"
          style={{
            fontFamily: `"Barlow"`,
            fontWeight: 800,
            fontOpticalSizing: "auto",
            fontSize: 120,
            letterSpacing: 2,
            opacity: show ? 1 : 0,
          }}
        >
          {word.text}{" "}
        </span>
      )}
    />
  );
}

import { useNavigate } from "react-router-dom";
import { authCheck, login, logout, signup } from "../api/lit-captions";
import { useToast } from "@/components/ui/use-toast";
import { useEffect, useState } from "react";

const GOOGLE_AUTH_BASE_URL =
  process.env.NODE_ENV === "production" ? "" : process.env.BACKEND_API_HOST;
const GOOGLE_AUTH_URL = `${GOOGLE_AUTH_BASE_URL}/auth/login/google`;

export function useAuth() {
  const navigate = useNavigate();
  const { toast } = useToast();
  return {
    signupWithEmail: async (email: string, password: string) => {
      try {
        await signup(email, password);
        navigate("/");
      } catch (error) {
        console.error(error);
        toast({
          variant: "destructive",
          title: "Signup failed",
          description: error.message,
        });
      }
    },
    loginWithEmail: async (email: string, password: string) => {
      try {
        await login(email, password);
        navigate("/");
      } catch (error) {
        console.error(error);
        toast({
          variant: "destructive",
          title: "Login failed",
          description: error.message,
        });
      }
    },
    loginWithGoogle: async () => {
      window.location.assign(GOOGLE_AUTH_URL);
      // window.location.href = GOOGLE_AUTH_URL;
    },
    logout: async () => {
      await logout();
      navigate("/");
    },
  };
}

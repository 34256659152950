import { SequencesRenderer } from "@/src/components/caption-renderer/sequences-renderer";
import {
  CaptionStyleComponentProps,
  CaptionStyleSpec,
  ColorPropertyValue,
  PropertyValue,
} from "@/src/types";
import { useMemo } from "react";
import Color from "color";

const Properties = {
  textColor: {
    label: "Text",
    propertyType: "color",
  },
  highlightColor: {
    label: "Highlighted Text",
    propertyType: "color",
  },
  bgColor: {
    label: "Background",
    propertyType: "color",
  },
} as const;
type Property = keyof typeof Properties;

export const NeonPhrases2Spec = {
  properties: Properties,
} satisfies CaptionStyleSpec<Property>;

export function NeonPhrases2DefaultValues(): Record<Property, PropertyValue> {
  return {
    textColor: "#FE259B",
    highlightColor: "#25fe59",
    bgColor: "#2E101C",
  };
}

export function NeonPhrases2Component({
  values,
  phrases,
}: CaptionStyleComponentProps<Property>) {
  const textColor = values.textColor as ColorPropertyValue;
  const highlightColor = values.highlightColor as ColorPropertyValue;
  const bgColor = values.bgColor as ColorPropertyValue;
  if (!textColor || !highlightColor || !bgColor) {
    console.error("Missing required options");
    return null;
  }
  return (
    <SequencesRenderer
      sequences={phrases}
      rootClassName="px-8 py-16 pb-64"
      sequenceTextClassName="text-center text-[72px] px-10 py-10 rounded-[32px] leading-[1.2]"
      sequenceTextStyles={{
        backgroundColor: bgColor,
      }}
      renderWord={(word, index, highlighted) => (
        <span
          key={index}
          className="uppercase"
          style={{
            fontFamily: "Poiret One",
            fontSize: 120,
            color: "#ffffff",
            letterSpacing: 2,
            WebkitTextStrokeColor: highlighted ? highlightColor : textColor,
            WebkitTextStrokeWidth: "2px",
            // drop shadow
            filter: new Array(3)
              .fill(
                `drop-shadow(0px 0px 3px ${highlighted ? highlightColor : textColor})`
              )
              .join(" "),
          }}
        >
          {word.text}{" "}
        </span>
      )}
    />
  );
}

import {
  Export,
  Project,
  PropertyValue,
  UserCredits,
  User,
  VideoAsset,
  ProjectWithDetails,
  ExportWithDetails,
} from "../types";

export class UnauthorizedError extends Error {
  constructor() {
    super("Unauthorized");
    this.name = "UnauthorizedError";
  }
}

const API_BASE_URL =
  process.env.NODE_ENV === "production" ? "" : process.env.BACKEND_API_HOST;

export async function authHandlingApiFetch<T>(
  url: string,
  options?: RequestInit
) {
  const response = await fetch(`${API_BASE_URL}${url}`, {
    ...options,
    credentials: "include",
  });
  // check response status
  if (response.status === 401) {
    throw new UnauthorizedError();
  }
  return response.json() as T;
}

export async function authCheck() {
  const responseData = await authHandlingApiFetch<{
    success: boolean;
    authenticated: boolean;
    hasLoggedInBefore: boolean;
  }>(`/auth/check`);
  const { success, authenticated, hasLoggedInBefore } = responseData;
  if (!success) {
    throw new Error("Failed to check authentication");
  }
  return { authenticated, hasLoggedInBefore };
}

export async function logout() {
  const responseData = await authHandlingApiFetch<{ success: boolean }>(
    `/auth/logout`,
    {
      method: "POST",
    }
  );
  const { success } = responseData;
  if (!success) {
    throw new Error("Failed to logout");
  }
}

export async function signup(email: string, password: string) {
  const responseData = await authHandlingApiFetch<{
    success: boolean;
    message?: string;
  }>(`/auth/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  const { success, message } = responseData;
  if (!success) {
    throw new Error(message || "Failed to signup");
  }
}

export async function login(email: string, password: string) {
  const responseData = await authHandlingApiFetch<{
    success: boolean;
    message?: string;
  }>(`/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  const { success, message } = responseData;
  if (!success) {
    throw new Error(message || "Failed to login");
  }
}

type GetUserResponse = {
  success: boolean;
  user: User;
  credits: UserCredits;
};

export async function getUser() {
  const responseData = await authHandlingApiFetch<GetUserResponse>(`/api/user`);
  const { success, user, credits } = responseData;
  if (!success) {
    throw new Error("Failed to fetch user");
  }
  return { user, credits };
}

type CreateVideoUploadUrlResponse = {
  success: boolean;
  uploadUrl: string;
  uploadPath: string;
  identifier: string;
};

export async function createVideoUploadUrl() {
  const responseData = await authHandlingApiFetch(
    `/api/create-video-upload-url`,
    {
      method: "POST",
    }
  );
  const { success, uploadUrl, uploadPath, identifier } =
    responseData as CreateVideoUploadUrlResponse;
  if (!success) {
    throw new Error("Failed to create video upload URL");
  }
  return { uploadPath, uploadUrl, identifier };
}

type InitProjectWithVideoResponse = {
  success: boolean;
  projectId: number;
};

export async function initProjectWithVideo({
  uploadPath,
  identifier,
}: {
  uploadPath: string;
  identifier: string;
}) {
  const responseData = await authHandlingApiFetch<InitProjectWithVideoResponse>(
    `/api/init-project-with-video`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uploadPath,
        identifier,
      }),
    }
  );
  const { success, projectId } = responseData;
  if (!success) {
    throw new Error("Failed to init project with video");
  }
  return projectId;
}

type GetProjectsResponse = {
  success: boolean;
  projects: ProjectWithDetails[];
};

export async function getProjects() {
  const responseData =
    await authHandlingApiFetch<GetProjectsResponse>(`/api/projects`);
  const { success, projects } = responseData;
  if (!success) {
    throw new Error("Failed to fetch projects");
  }
  return projects;
}

type GetProjectResponse = {
  success: boolean;
  project: ProjectWithDetails;
};

export async function getProject(projectId: number) {
  const responseData = await authHandlingApiFetch<GetProjectResponse>(
    `/api/projects/${projectId}`
  );
  const { success, project } = responseData;
  if (!success) {
    throw new Error("Failed to fetch project");
  }
  return project;
}

export async function deleteProject(projectId: number) {
  const responseData = await authHandlingApiFetch<{ success: boolean }>(
    `/api/projects/${projectId}`,
    {
      method: "DELETE",
    }
  );
  const { success } = responseData;
  if (!success) {
    throw new Error("Failed to delete project");
  }
}

export async function saveCaptionStyleSettings({
  projectId,
  captionStyle,
  captionStyleSettings,
}: {
  projectId: number;
  captionStyle: string;
  captionStyleSettings: any;
}) {
  const responseData = await authHandlingApiFetch<{ success: boolean }>(
    `/api/projects/${projectId}/save-caption-style-settings`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        captionStyle,
        captionStyleSettings,
      }),
    }
  );
  const { success } = responseData;
  if (!success) {
    throw new Error("Failed to save caption style settings");
  }
}

type CanExportResponse = {
  success: boolean;
  canExport: boolean;
  minutesRemaining: number;
  minutesToConsume: number;
};

export async function canExportProject(projectId: number) {
  const responseData = await authHandlingApiFetch<CanExportResponse>(
    `/api/projects/${projectId}/can-export`
  );
  const { success, canExport, minutesRemaining, minutesToConsume } =
    responseData;
  if (!success) {
    throw new Error("Failed to check if can export");
  }
  return { canExport, minutesRemaining, minutesToConsume };
}

type ExportProjectResponse = {
  success: boolean;
  exportId: number;
};

export async function exportProject(projectId: number) {
  const responseData = await authHandlingApiFetch<ExportProjectResponse>(
    `/api/projects/${projectId}/export`,
    {
      method: "POST",
    }
  );
  const { success, exportId } = responseData;
  if (!success) {
    throw new Error("Failed to export project");
  }
  return exportId;
}

type GetExportResponse = {
  success: boolean;
  export: Export;
  previewUrl: string;
};

export async function getExport(exportId: number) {
  const responseData = await authHandlingApiFetch<GetExportResponse>(
    `/api/exports/${exportId}`
  );
  const { success, export: exportInst, previewUrl } = responseData;
  if (!success) {
    throw new Error("Failed to fetch export");
  }
  return { exportInst, previewUrl };
}

type GetExportsResponse = {
  success: boolean;
  exports: ExportWithDetails[];
};

export async function getExports() {
  const responseData =
    await authHandlingApiFetch<GetExportsResponse>(`/api/exports`);
  const { success, exports } = responseData;
  if (!success) {
    throw new Error("Failed to fetch exports");
  }
  return exports;
}

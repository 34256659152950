import { SequencesRenderer } from "@/src/components/caption-renderer/sequences-renderer";
import {
  CaptionStyleComponentProps,
  CaptionStyleSpec,
  ColorPropertyValue,
  PropertyValue,
} from "@/src/types";

const Properties = {
  textColor: {
    label: "Text",
    propertyType: "color",
  },
  strokeColor: {
    label: "Outline",
    propertyType: "color",
  },
} as const;
type Property = keyof typeof Properties;

export const BasicPhrasesWordsSpec = {
  properties: Properties,
} satisfies CaptionStyleSpec<Property>;

export function BasicPhrasesWordsDefaultValues(): Record<
  Property,
  PropertyValue
> {
  return {
    textColor: "#ffffff",
    strokeColor: "#111111",
  };
}

export function BasicPhrasesWordsComponent({
  values,
  phrases,
}: CaptionStyleComponentProps<Property>) {
  const textColor = values.textColor as ColorPropertyValue;
  const strokeColor = values.strokeColor as ColorPropertyValue;
  if (!textColor || !strokeColor) {
    console.error("Missing required options");
    return null;
  }

  return (
    <SequencesRenderer
      sequences={phrases}
      rootClassName="px-8 py-16 pb-64"
      sequenceTextClassName="text-center px-10 py-10 rounded-[32px] leading-[1.2]"
      renderWord={(word, index, highlighted, show) => (
        <div
          key={index}
          className="inline-block whitespace-pre-wrap relative z-0"
          style={{
            fontFamily: `"Exo 2"`,
            fontOpticalSizing: "auto",
            fontSize: 120,
            color: textColor,
            letterSpacing: 2,
            opacity: show ? 1 : 0,
          }}
        >
          <span
            className="absolute top-0 left-0 w-full h-full z-[-1]"
            style={{
              fontFamily: `"Exo 2"`,
              fontOpticalSizing: "auto",
              whiteSpace: "pre",
              fontSize: 120,
              color: strokeColor,
              letterSpacing: 2,
              WebkitTextStrokeColor: strokeColor,
              WebkitTextStrokeWidth: "20px",
            }}
          >
            {word.text}{" "}
          </span>
          {word.text}{" "}
        </div>
      )}
    />
  );
}

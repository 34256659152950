import { PlayerRef } from "@remotion/player";
import { usePlayer } from "../../helpers/player.hook";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import Draggable, {
  DraggableCore,
  DraggableEventHandler,
} from "react-draggable";

type Props = {
  playerRef: React.RefObject<PlayerRef>;
  durationInFrames: number;
  fps: number;
};
export function CaptionPlayerSeekbar({
  playerRef,
  durationInFrames,
  fps,
}: Props) {
  const { currentTime, duration, seekTo } = usePlayer(
    playerRef,
    { durationInFrames, fps },
    { listenForTimeUpdate: true }
  );

  const [xPercent, setXPercent] = useState(0);

  const trackRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);

  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    console.log("currentTime", currentTime, duration);
  }, [currentTime, duration]);

  const handleStart: DraggableEventHandler = () => {
    setIsDragging(true);
  };

  const handleDrag: DraggableEventHandler = (e, { x }) => {
    if (isDragging) {
      console.log("x", x, "trackWidth", trackRef.current?.clientWidth);
      const trackWidth = trackRef.current?.clientWidth || 1;
      const handleWidth = handleRef.current?.clientWidth || 1;
      const frac = Math.max(0, Math.min(1, x / (trackWidth - handleWidth)));
      const newTime = frac * duration;
      console.log("newTime", newTime);
      seekTo(newTime);
      setXPercent(frac * 100);
    }
  };

  const handleStop: DraggableEventHandler = (e, { x }) => {
    setIsDragging(false);
    const trackWidth = trackRef.current?.clientWidth || 1;
    const handleWidth = handleRef.current?.clientWidth || 0;
    const frac = Math.max(0, Math.min(1, (x / (trackWidth - handleWidth)) * 1));
    const newTime = frac * duration;
    seekTo(newTime);
    setXPercent(frac * 100);
  };

  const handleTrackClick = (e: React.MouseEvent) => {
    const trackWidth = trackRef.current?.clientWidth || 1;
    const clickX = e.clientX - trackRef.current?.getBoundingClientRect().left!;
    const frac = Math.max(0, Math.min(1, clickX / trackWidth));
    const newTime = frac * duration;
    seekTo(newTime);
    setXPercent(frac * 100);
  };

  return (
    <div className="flex flex-1 relative h-3 group mx-2 parent">
      <div className="flex flex-1 relative border border-lit-purple h-full rounded-xl overflow-clip">
        <div
          className={clsx(
            "absolute top-0 h-full rounded-xl left-0 bg-lit-purple transition-[width]"
          )}
          style={{
            width: `${duration === 0 ? 0 : Math.min(100, (currentTime / duration) * 100)}%`,
          }}
        ></div>
      </div>
      <div
        ref={trackRef}
        className="absolute track w-full h-full flex items-center cursor-pointer"
        onClick={handleTrackClick}
      >
        <DraggableCore
          handle=".handle"
          scale={1}
          onStart={handleStart}
          onDrag={handleDrag}
          onStop={handleStop}
        >
          <div
            className={clsx("relative w-0 h-0", {
              "transition-[left]": !isDragging,
            })}
            style={{
              left: `${isDragging ? xPercent : (currentTime / duration) * 100}%`,
            }}
          >
            <div
              ref={handleRef}
              className={clsx(
                "handle w-5 h-5 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white border-2 border-lit-purple"
              )}
            ></div>
          </div>
        </DraggableCore>
        <div className="absolute left-0 top-4 text-xs font-medium">
          {getDisplayTime(currentTime)}
        </div>
        <div className="absolute right-0 top-4 text-xs font-medium">
          {getDisplayTime(duration)}
        </div>
      </div>
    </div>
  );
}

function getDisplayTime(time: number) {
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time) % 60;
  if (hours > 0) {
    return `${hours}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  }
  return `${minutes}:${String(seconds).padStart(2, "0")}`;
}

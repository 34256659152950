import { SequencesRenderer } from "@/src/components/caption-renderer/sequences-renderer";
import {
  CaptionStyleComponentProps,
  CaptionStyleSpec,
  ColorPropertyValue,
  PropertyValue,
} from "@/src/types";
import { useMemo } from "react";
import Color from "color";

const Properties = {
  textColor: {
    label: "Text",
    propertyType: "color",
  },
  shadowColor: {
    label: "Shadow",
    propertyType: "color",
  },
} as const;
type Property = keyof typeof Properties;

export const ShadowPhrasesWordsSpec = {
  properties: Properties,
} satisfies CaptionStyleSpec<Property>;

export function ShadowPhrasesWordsDefaultValues(): Record<
  Property,
  PropertyValue
> {
  return {
    textColor: "#cde6ff",
    shadowColor: "#093f55",
  };
}

export function ShadowPhrasesWordsComponent({
  values,
  phrases,
}: CaptionStyleComponentProps<Property>) {
  const textColor = values.textColor as ColorPropertyValue;
  const shadowColor = values.shadowColor as ColorPropertyValue;
  if (!textColor || !shadowColor) {
    console.error("Missing required options");
    return null;
  }
  const shadowRgb = useMemo(() => {
    const rgb = Color(shadowColor).rgb().array();
    if (!rgb) {
      console.error("Invalid shadow color");
      return null;
    }
    return rgb;
  }, [shadowColor]);

  const textShadowRgba = `rgba(${shadowRgb?.join(", ")}, 1)`;

  return (
    <SequencesRenderer
      sequences={phrases}
      rootClassName="px-8 py-16 pb-64"
      sequenceTextClassName="text-center px-10 py-10 rounded-[32px] leading-[1.2]"
      renderWord={(word, index, highlighted, show) => (
        <div
          key={index}
          className="inline-block whitespace-pre-wrap relative z-0"
          style={{
            fontFamily: "Barlow",
            fontWeight: 800,
            fontOpticalSizing: "auto",
            fontSize: 120,
            color: textColor,
            letterSpacing: 2,
            opacity: show ? 1 : 0,
            textShadow: show ? `5px 3px 3px ${textShadowRgba}` : "none",
          }}
        >
          {word.text}{" "}
        </div>
      )}
    />
  );
}

import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";

type AuthVariables = {
  loggedIn: boolean;
};

type AuthState = AuthVariables & ReturnType<typeof getActions>;

function getInitialVariables(): AuthVariables {
  return {
    // Start with login until the first API fails
    loggedIn: true,
  };
}

function getActions(set, get) {
  return {
    setLoggedIn: (loggedIn: boolean) => {
      set({ loggedIn });
    },
    resetStore: () => {
      set(getInitialVariables());
    },
  };
}

export const useAuthStore = create<AuthState>()(
  devtools(
    subscribeWithSelector((set, get) => ({
      ...getInitialVariables(),
      ...getActions(set, get),
    }))
  )
);

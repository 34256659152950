import { Player, Thumbnail } from "@remotion/player";
import {
  CaptionPlayerParams,
  CaptionStyleConfig,
  PropertyValue,
} from "@/src/types";
import { CaptionPlayerComponent } from "./caption-player/component";

type Props = {
  captionPlayerParams: CaptionPlayerParams;
  captionStyleConfig: string | CaptionStyleConfig;
  captionStylePropertyValues: Record<string, PropertyValue>;
};

const FPS = 30;

export function CaptionThumbnail({
  captionPlayerParams,
  captionStyleConfig,
  captionStylePropertyValues,
}: Props) {
  const durationInFrames = Math.floor(
    (captionPlayerParams.videoDuration / 1000) * FPS
  );
  return (
    <div className="flex flex-col items-center relative">
      <Thumbnail
        component={CaptionPlayerComponent}
        inputProps={{
          captionPlayerParams,
          captionStyleConfig,
          captionStylePropertyValues,
          videoRenderMode: "skip",
        }}
        durationInFrames={durationInFrames}
        frameToDisplay={Math.min(durationInFrames / 2, 5 * FPS)}
        compositionWidth={1080}
        compositionHeight={1920}
        fps={FPS}
        style={{
          width: `${180}px`,
          height: `${320}px`,
        }}
      />
    </div>
  );
}

import { CaptionStyleConfig } from "../types";
import {
  BasicPhrasesComponent,
  BasicPhrasesDefaultValues,
  BasicPhrasesSpec,
} from "./definitions/basic-phrases";
import {
  BasicPhrasesWordsComponent,
  BasicPhrasesWordsDefaultValues,
  BasicPhrasesWordsSpec,
} from "./definitions/basic-phrases-words";
import {
  FadedWordsComponent,
  FadedWordsDefaultValues,
  FadedWordsSpec,
} from "./definitions/faded-words";
import {
  GradientPhrasesWordsComponent,
  GradientPhrasesWordsDefaultValues,
  GradientPhrasesWordsSpec,
} from "./definitions/gradient-phrases-words";
import {
  NeonPhrasesComponent,
  NeonPhrasesDefaultValues,
  NeonPhrasesSpec,
} from "./definitions/neon-phrases";
import {
  NeonPhrases2Component,
  NeonPhrases2DefaultValues,
  NeonPhrases2Spec,
} from "./definitions/neon-phrases-2";
import {
  ShadowPhrasesWordsComponent,
  ShadowPhrasesWordsDefaultValues,
  ShadowPhrasesWordsSpec,
} from "./definitions/shadow-phrases-words";
import {
  SlantPhrasesComponent,
  SlantPhrasesDefaultValues,
  SlantPhrasesSpec,
} from "./definitions/slant-phrases";

const BG_IMAGES = [
  new URL(
    "../assets/theme-backgrounds/1.jpg?as=webp&width=200",
    import.meta.url
  ).toString(),
  new URL(
    "../assets/theme-backgrounds/2.jpg?as=webp&width=200",
    import.meta.url
  ).toString(),
  new URL(
    "../assets/theme-backgrounds/3.png?as=webp&width=200",
    import.meta.url
  ).toString(),
  new URL(
    "../assets/theme-backgrounds/4.png?as=webp&width=200",
    import.meta.url
  ).toString(),
  new URL(
    "../assets/theme-backgrounds/5.png?as=webp&width=200",
    import.meta.url
  ).toString(),
  new URL(
    "../assets/theme-backgrounds/6.png?as=webp&width=200",
    import.meta.url
  ).toString(),
];

export const CaptionStylesConfig: CaptionStyleConfig[] = [
  {
    key: "faded-words",
    name: "Faded Words",
    spec: FadedWordsSpec,
    component: FadedWordsComponent,
    defaultValues: FadedWordsDefaultValues(),
    bgImg: BG_IMAGES[1],
    bgPreviewVideo: {
      video: new URL(
        "../assets/caption-style-previews/faded-words.mp4",
        import.meta.url
      ).toString(),
      poster: new URL(
        "../assets/caption-style-previews/faded-words.jpg",
        import.meta.url
      ).toString(),
    },
  },
  {
    key: "slant-phrases",
    name: "Slant Phrases",
    spec: SlantPhrasesSpec,
    component: SlantPhrasesComponent,
    defaultValues: SlantPhrasesDefaultValues(),
    bgImg: BG_IMAGES[1],
    bgPreviewVideo: {
      video: new URL(
        "../assets/caption-style-previews/slant-phrases.mp4",
        import.meta.url
      ).toString(),
      poster: new URL(
        "../assets/caption-style-previews/slant-phrases.jpg",
        import.meta.url
      ).toString(),
    },
  },
  {
    key: "neon-phrases-2",
    name: "Neon Phrases 2",
    spec: NeonPhrases2Spec,
    component: NeonPhrases2Component,
    defaultValues: NeonPhrases2DefaultValues(),
    bgImg: BG_IMAGES[5],
    bgPreviewVideo: {
      video: new URL(
        "../assets/caption-style-previews/neon-phrases-2.mp4",
        import.meta.url
      ).toString(),
      poster: new URL(
        "../assets/caption-style-previews/neon-phrases-2.jpg",
        import.meta.url
      ).toString(),
    },
  },
  {
    key: "basic-phrases",
    name: "Basic Phrases",
    spec: BasicPhrasesSpec,
    component: BasicPhrasesComponent,
    defaultValues: BasicPhrasesDefaultValues(),
    bgImg: BG_IMAGES[3],
    bgPreviewVideo: {
      video: new URL(
        "../assets/caption-style-previews/basic-phrases.mp4",
        import.meta.url
      ).toString(),
      poster: new URL(
        "../assets/caption-style-previews/basic-phrases.jpg",
        import.meta.url
      ).toString(),
    },
  },
  {
    key: "gradient-phrases-words",
    name: "Gradient Phrases Words",
    spec: GradientPhrasesWordsSpec,
    component: GradientPhrasesWordsComponent,
    defaultValues: GradientPhrasesWordsDefaultValues(),
    bgImg: BG_IMAGES[3],
    bgPreviewVideo: {
      video: new URL(
        "../assets/caption-style-previews/gradient-phrases-words.mp4",
        import.meta.url
      ).toString(),
      poster: new URL(
        "../assets/caption-style-previews/gradient-phrases-words.jpg",
        import.meta.url
      ).toString(),
    },
  },
  {
    key: "neon-phrases",
    name: "Neon Phrases",
    spec: NeonPhrasesSpec,
    component: NeonPhrasesComponent,
    defaultValues: NeonPhrasesDefaultValues(),
    bgImg: BG_IMAGES[4],
    bgPreviewVideo: {
      video: new URL(
        "../assets/caption-style-previews/neon-phrases.mp4",
        import.meta.url
      ).toString(),
      poster: new URL(
        "../assets/caption-style-previews/neon-phrases.jpg",
        import.meta.url
      ).toString(),
    },
  },
  {
    key: "shadow-phrases-words",
    name: "Shadow Phrases Words",
    spec: ShadowPhrasesWordsSpec,
    component: ShadowPhrasesWordsComponent,
    defaultValues: ShadowPhrasesWordsDefaultValues(),
    bgImg: BG_IMAGES[2],
    bgPreviewVideo: {
      video: new URL(
        "../assets/caption-style-previews/shadow-phrases-words.mp4",
        import.meta.url
      ).toString(),
      poster: new URL(
        "../assets/caption-style-previews/shadow-phrases-words.jpg",
        import.meta.url
      ).toString(),
    },
  },
  {
    key: "basic-phrases-words",
    name: "Basic Phrases Words",
    spec: BasicPhrasesWordsSpec,
    component: BasicPhrasesWordsComponent,
    defaultValues: BasicPhrasesWordsDefaultValues(),
    bgImg: BG_IMAGES[1],
    bgPreviewVideo: {
      video: new URL(
        "../assets/caption-style-previews/basic-phrases-words.mp4",
        import.meta.url
      ).toString(),
      poster: new URL(
        "../assets/caption-style-previews/basic-phrases-words.jpg",
        import.meta.url
      ).toString(),
    },
  },
];

export const DEFAULT_CAPTION_STYLE_CONFIG = CaptionStylesConfig[0];

import { authCheck } from "@/src/api/lit-captions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../layout";
import { EmailLoginCard } from "./email-login-card";
import { LoginOptionsCard } from "./login-options-card";

export function Login() {
  const [emailSignInSelected, setEmailSignInSelected] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { authenticated } = await authCheck();
        if (authenticated) {
          navigate("/projects");
        }
      } catch (e) {
        console.error(e);
      }
    };
    checkAuth();
  }, []);

  return (
    <Layout>
      <div className="mt-40 flex justify-center items-center ">
        <div className="flex flex-col w-96 mx-10">
          {!emailSignInSelected ? (
            <LoginOptionsCard
              key="login-options-card"
              selectEmailLogin={() => setEmailSignInSelected(true)}
            />
          ) : (
            <EmailLoginCard key="email-login-card" />
          )}
          <p className="text-sm px-6 py-2 text-muted-foreground">
            Please note: Lit Captions requires a separate account from your
            Typito account
          </p>
        </div>
      </div>
    </Layout>
  );
}

import posthog from "posthog-js";

const POSTHOG_API_TOKEN = process.env.POSTHOG_API_TOKEN;
const POSTHOG_API_HOST_BASE = process.env.POSTHOG_API_HOST_BASE;

export function initPostHog() {
  // posthog
  if (!POSTHOG_API_TOKEN) {
    return;
  }
  posthog.init(POSTHOG_API_TOKEN, {
    ...(POSTHOG_API_HOST_BASE
      ? {
          api_host: `${POSTHOG_API_HOST_BASE}/ingest`,
          ui_host: "https://us.i.posthog.com",
        }
      : {
          api_host: "https://us.i.posthog.com",
        }),
    person_profiles: "always",
  });
}

export function identifyPostHog(
  userId: string,
  {
    email,
    name,
  }: {
    email: string;
    name?: string;
  }
) {
  if (!POSTHOG_API_TOKEN) {
    return;
  }
  console.log("Identifying user in PostHog", { userId, email, name });
  posthog.identify(userId, {
    email,
    name,
  });
}

export function trackPageViewPostHog(url: string) {
  if (!POSTHOG_API_TOKEN) {
    return;
  }
  posthog.capture("$pageview", { $current_url: url });
}

export function trackEventPostHog(
  eventName: string,
  properties: Record<string, any>
) {
  if (!POSTHOG_API_TOKEN) {
    return;
  }
  posthog.capture(eventName, properties);
}

import { SequencesRenderer } from "@/src/components/caption-renderer/sequences-renderer";
import {
  CaptionStyleComponentProps,
  CaptionStyleSpec,
  ColorPropertyValue,
  PropertyValue,
} from "@/src/types";

const Properties = {
  textColor: {
    label: "Text",
    propertyType: "color",
  },
  highlightColor: {
    label: "Highlighted Text",
    propertyType: "color",
  },
  bgColor: {
    label: "Background",
    propertyType: "color",
  },
} as const;
type Property = keyof typeof Properties;

export const BasicPhrasesSpec = {
  properties: Properties,
} satisfies CaptionStyleSpec<Property>;

export function BasicPhrasesDefaultValues(): Record<Property, PropertyValue> {
  return {
    textColor: "#ffffff",
    highlightColor: "#ffff00",
    bgColor: "#042f2e",
  };
}

export function BasicPhrasesComponent({
  values,
  phrases,
}: CaptionStyleComponentProps<Property>) {
  const backgroundColor = values.bgColor as ColorPropertyValue;
  const textColor = values.textColor as ColorPropertyValue;
  const highlightColor = values.highlightColor as ColorPropertyValue;
  if (!backgroundColor || !textColor || !highlightColor) {
    console.error("Missing required options");
    return null;
  }
  return (
    <SequencesRenderer
      sequences={phrases}
      rootClassName="px-8 py-16 pb-[300px]"
      sequenceTextClassName="text-center text-[72px] px-10 py-10 rounded-[32px] leading-[1.2]"
      sequenceTextStyles={{
        fontFamily: "Outfit",
        color: textColor,
        backgroundColor,
      }}
      wordTextStyles={{
        color: textColor,
      }}
      hightlightedWordTextStyles={{
        color: highlightColor,
      }}
    />
  );
}

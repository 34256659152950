import { useRef } from "react";
import { useMediaQuery } from "react-responsive";

type Props = {
  video: string;
  poster?: string;
};
export function CaptionThemePreviewVideo({ video, poster }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  return (
    <div
      className="w-full h-full rounded-[2px] relative"
      onMouseOver={(e) => {
        if (isMobile) return;
        if (!videoRef.current) return;
        videoRef.current.play();
      }}
      onMouseOut={(e) => {
        if (isMobile) return;
        if (!videoRef.current) return;
        videoRef.current.pause();
        videoRef.current.src = video;
      }}
    >
      <video
        ref={videoRef}
        muted
        loop
        className="w-full h-full object-cover object-center rounded-[2px]"
        poster={poster}
      >
        <source src={video} type="video/mp4" />
      </video>
      {/* Need this overlay to prevent interaction with the video on iOS */}
      <div className="absolute inset-0 bg-transparent" />
    </div>
  );
}

const logoUrl = new URL(
  "../assets/logo.png?as=webp&height=64",
  import.meta.url
);
const logoWithTextUrl = new URL(
  "../assets/logo_with_text.png?as=webp&height=64",
  import.meta.url
);

type Props = {
  withText?: boolean;
};

export function Logo({ withText = false }: Props) {
  return (
    <div className="flex gap-2 items-center">
      <img
        src={withText ? logoWithTextUrl.toString() : logoUrl.toString()}
        alt="Logo"
        className="h-8 w-auto"
      />
      {/* {withText && (
        <span className="font-outfit font-bold text-xl bg-gradient-to-l from-lit-purple to-lit-pink bg-clip-text text-transparent">
          Lit Captions
        </span>
      )} */}
    </div>
  );
}

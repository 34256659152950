import Layout from "./layout";
import { UploadDropzone } from "../components/upload-dropzone";
import {
  createVideoUploadUrl,
  initProjectWithVideo,
} from "../api/lit-captions";
import { useToast } from "@/components/ui/use-toast";
import { useNavigate } from "react-router-dom";
import { getVideoMetadata } from "../helpers/video";
import { useState } from "react";
import { ProgressBar } from "../components/progress-bar";
import axios from "axios";
import { Button } from "@/components/ui/button";
import { useManagedApi } from "../helpers/managed-api.hook";
import { trackEventPostHog } from "../helpers/posthog";

const MAX_FILE_SIZE = 500 * 1024 * 1024; // 500MB
const MAX_VIDEO_DURATION = 300; // 5 minutes

const UPLOAD_STAGES = {
  NONE: "NONE",
  UPLOADING: "UPLOADING",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
} as const;
type UploadStage = (typeof UPLOAD_STAGES)[keyof typeof UPLOAD_STAGES];

export default function CreateNewProject() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [uploadStage, setUploadStage] = useState<UploadStage>(
    UPLOAD_STAGES.NONE
  );
  const createVideoUploadUrlM = useManagedApi(createVideoUploadUrl);
  const initProjectWithVideoM = useManagedApi(initProjectWithVideo);
  const [uploadProgress, setUploadProgress] = useState(0);
  const onFileSelected = async (file: File) => {
    trackEventPostHog("Video file selected", {
      fileSize: file.size,
      fileName: file.name,
      fileType: file.type,
    });
    let videoMetadata: Awaited<ReturnType<typeof getVideoMetadata>>;

    try {
      videoMetadata = await getVideoMetadata(file);
      trackEventPostHog("Video get metadata success", {
        fileSize: file.size,
        fileName: file.name,
        fileType: file.type,
        duration: videoMetadata.duration,
        width: videoMetadata.width,
        height: videoMetadata.height,
      });
    } catch (error) {
      console.warn(error);
      trackEventPostHog("Video get metadata error", {
        fileSize: file.size,
        fileName: file.name,
        fileType: file.type,
        error: error.message,
      });
    }

    let uploadUrlParams: Awaited<ReturnType<typeof createVideoUploadUrlM>>;
    try {
      setUploadStage(UPLOAD_STAGES.UPLOADING);
      uploadUrlParams = await createVideoUploadUrlM();
      trackEventPostHog("Video get upload url success", {
        fileSize: file.size,
        fileName: file.name,
        fileType: file.type,
        uploadUrl: uploadUrlParams.uploadUrl,
        uploadPath: uploadUrlParams.uploadPath,
        identifier: uploadUrlParams.identifier,
      });
    } catch (error) {
      trackEventPostHog("Video get upload url error", {
        fileSize: file.size,
        fileName: file.name,
        fileType: file.type,
        error: error.message,
      });
      setUploadStage(UPLOAD_STAGES.ERROR);
      console.error(error);
      toast({
        variant: "destructive",
        title: "Failed to get upload URL",
        description: "Please try again.",
      });
      return;
    }

    try {
      const response = await axios.put(uploadUrlParams.uploadUrl, file, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.progress || 0) * 100;
          setUploadProgress(progress);
        },
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error("Failed to upload video");
      }
      trackEventPostHog("Video upload success", {
        fileSize: file.size,
        fileName: file.name,
        fileType: file.type,
        uploadUrl: uploadUrlParams.uploadUrl,
        uploadPath: uploadUrlParams.uploadPath,
        identifier: uploadUrlParams.identifier,
      });
    } catch (error) {
      trackEventPostHog("Video upload error", {
        fileSize: file.size,
        fileName: file.name,
        fileType: file.type,
        uploadUrl: uploadUrlParams.uploadUrl,
        uploadPath: uploadUrlParams.uploadPath,
        identifier: uploadUrlParams.identifier,
        error: error.message,
      });
      setUploadStage(UPLOAD_STAGES.ERROR);
      console.error(error);
      toast({
        variant: "destructive",
        title: "Failed to upload video",
        description: "Please try again.",
      });
      return;
    }
    try {
      const projectId = await initProjectWithVideoM({
        uploadPath: uploadUrlParams.uploadPath,
        identifier: uploadUrlParams.identifier,
      });
      setUploadStage(UPLOAD_STAGES.COMPLETED);
      navigate(`/projects/${projectId}/status`);
    } catch (error) {
      setUploadStage(UPLOAD_STAGES.ERROR);
      console.error(error);
      toast({
        variant: "destructive",
        title: "Failed to create project",
        description: "Please try again.",
      });
    }
  };
  const resetUploadStage = () => {
    setUploadStage(UPLOAD_STAGES.NONE);
    setUploadProgress(0);
  };
  return (
    <Layout activePage="projects">
      <div className="flex justify-center flex-1">
        <div className="flex-1 max-w-4xl px-8 pt-8 pb-4 flex flex-col gap-8">
          <h1>Create new project</h1>
          {uploadStage === UPLOAD_STAGES.NONE ? (
            <div className="flex flex-col gap-8 max-w-3xl">
              <div>
                <h3>Upload video</h3>
                <p className="mt-2">
                  Please upload a video file to get started.
                  <br />
                  Videos must be in <strong>.mp4 format</strong>, with a{" "}
                  <strong>9:16 (vertical) aspect ratio</strong> for best
                  results.
                  <br />
                  We currently only support videos <strong>
                    up to 500MB
                  </strong>{" "}
                  in size and <strong>5 minutes</strong> in length.
                </p>
              </div>
              <UploadDropzone
                maxFileSize={MAX_FILE_SIZE}
                onFileSelected={onFileSelected}
              />
            </div>
          ) : uploadStage === UPLOAD_STAGES.UPLOADING ? (
            <div className="flex flex-col gap-8">
              <div>
                <h3>Uploading video</h3>
                <p className="mt-2">
                  Your video is being uploaded to our servers.
                </p>
                <div className="mt-4 w-2/3 flex items-center gap-2">
                  <ProgressBar type="determinate" value={uploadProgress} />
                  <small className="w-16 text-sm font-medium leading-none">
                    {Math.floor(uploadProgress)}%
                  </small>
                </div>
              </div>
            </div>
          ) : uploadStage === UPLOAD_STAGES.COMPLETED ? (
            <div className="flex flex-col gap-8">
              <div>
                <h3 className="text-green-800">Video uploaded successfully</h3>
              </div>
            </div>
          ) : uploadStage === UPLOAD_STAGES.ERROR ? (
            <div className="flex flex-col gap-8">
              <div>
                <h3 className="text-red-800">Something went wrong</h3>
                <p className="mt-2">
                  We couldn't create your project. Please try again.
                </p>
                <div className="mt-4 w-2/3 flex items-center gap-2">
                  <Button onClick={() => resetUploadStage()}>Try again</Button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
}

import { useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { trackPageViewPostHog } from "../helpers/posthog";

export function LocationChangeTracker() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const url = useMemo(() => {
    let url = window.origin + location.pathname;
    if (searchParams.toString()) {
      url = url + `?${searchParams.toString()}`;
    }
    return url;
  }, [location.pathname, searchParams.toString()]);

  useEffect(() => {
    trackPageViewPostHog(url);
  }, [url]);
  return null;
}

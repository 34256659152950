export type VideoMetadata = {
  duration: number;
  width: number;
  height: number;
};

export async function getVideoMetadata(file: File): Promise<VideoMetadata> {
  return new Promise<VideoMetadata>((resolve, reject) => {
    const video = document.createElement("video");
    video.onloadedmetadata = () => {
      console.log(
        "onloadedmetadata",
        video.duration,
        video.videoWidth,
        video.videoHeight
      );
      const duration = video.duration;
      const width = video.videoWidth;
      const height = video.videoHeight;
      video.remove();
      URL.revokeObjectURL(video.src);
      resolve({ duration, width, height });
    };
    video.onerror = reject;
    video.src = URL.createObjectURL(file);
  });
}

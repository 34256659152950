import { useToast } from "@/components/ui/use-toast";
import { useCallback } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import prettyBytes from "pretty-bytes";

type Props = {
  maxFileSize: number;
  onFileSelected: (file: File) => void;
};

export function UploadDropzone({ maxFileSize, onFileSelected }: Props) {
  const { toast } = useToast();

  const onDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    const file = acceptedFiles[0];
    onFileSelected(file);
  };

  const onDropRejected = (files: FileRejection[]) => {
    const file = files[0];
    const error = file.errors[0];
    toast({
      variant: "destructive",
      title: error.code,
      description: error.message,
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "video/mp4": [".mp4"],
    },
    multiple: false,
    validator: (file) => {
      if (file.size > maxFileSize) {
        const humanReadableFileSize = prettyBytes(maxFileSize);
        return {
          code: "File is too large",
          message: `Your file was too large (${humanReadableFileSize}). We only support files up to ${prettyBytes(
            maxFileSize
          )}.`,
        };
      }

      if (file.type !== "video/mp4") {
        return {
          code: "Invalid file type",
          message: "We only support .mp4 files right now.",
        };
      }

      return null;
    },
  });

  return (
    <div
      className="border-2 border-dashed border-primary bg-primary/10 hover:bg-primary/5 transition-colors rounded-lg p-8 cursor-pointer flex flex-col items-center justify-center min-h-40 gap-2"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <p className="text-primary/70">
        Drag 'n' drop your video here, or click to select a video file
      </p>
    </div>
  );
}

import {
  CaptionPlayerParams,
  CaptionStyleConfig,
  PropertyValue,
  Word,
  WordSequence,
} from "@/src/types";

import "@/src/player-fonts.css";
import { useEffect, useMemo } from "react";
import { AbsoluteFill, OffthreadVideo, Video, staticFile } from "remotion";
import { CaptionStylesConfig } from "../../caption-styles";

type Props = {
  captionPlayerParams: CaptionPlayerParams;
  captionStyleConfig: string | CaptionStyleConfig;
  captionStylePropertyValues: Record<string, PropertyValue>;
  videoRenderMode: "offthread" | "default" | "skip";
};

const PHRASE_DURATION = 2000;
function splitSentencesIntoPhrases(sentences: WordSequence[]) {
  const phrases: WordSequence[] = [];
  sentences.forEach((sentence) => {
    const words = sentence.words;
    let phrase: Word[] = [];
    let firstWord = words[0];
    words.forEach((word) => {
      if (phrase.length === 0) {
        phrase.push(word);
      } else {
        if (word.end - firstWord.start <= PHRASE_DURATION) {
          phrase.push(word);
        } else {
          phrases.push({
            text: phrase.map((word) => word.text).join(" "),
            start: firstWord.start,
            end: word.end,
            words: phrase,
          });
          phrase = [word];
          firstWord = word;
        }
      }
    });
    if (phrase.length > 0) {
      phrases.push({
        text: phrase.map((word) => word.text).join(" "),
        start: firstWord.start,
        end: phrase[phrase.length - 1].end,
        words: phrase,
      });
    }
  });
  return phrases;
}

export function CaptionPlayerComponent({
  captionPlayerParams,
  captionStyleConfig,
  captionStylePropertyValues,
  videoRenderMode,
}: Props) {
  useEffect(() => {
    console.log("captionPlayerParams", captionPlayerParams);
  }, [captionPlayerParams]);

  const words = captionPlayerParams.transcript.words;
  const sentences = captionPlayerParams.transcript.sentences.sentences;

  const phrases = splitSentencesIntoPhrases(sentences);

  let StyleRenderComponent;
  let styleValues: Record<string, PropertyValue> = {};
  if (typeof captionStyleConfig === "string") {
    const styleConfig = CaptionStylesConfig.find(
      (style) => style.key === captionStyleConfig
    );
    if (!styleConfig) {
      console.error(`Invalid style config: ${captionStyleConfig}`);
      return null;
    }
    StyleRenderComponent = styleConfig.component;
    styleValues = {
      ...styleConfig.defaultValues,
      ...captionStylePropertyValues,
    };
  } else {
    StyleRenderComponent = captionStyleConfig.component;
    styleValues = {
      ...captionStyleConfig.defaultValues,
      ...captionStylePropertyValues,
    };
  }

  const videoUrl = useMemo(() => {
    if (!captionPlayerParams.videoUrl) return;
    const url = new URL(captionPlayerParams.videoUrl);
    if (url.protocol === "file:") {
      return staticFile(url.pathname);
    }
    return captionPlayerParams.videoUrl;
  }, [captionPlayerParams.videoUrl]);

  return (
    <AbsoluteFill>
      {videoUrl && videoRenderMode === "default" && (
        <Video src={videoUrl} pauseWhenBuffering />
      )}
      {videoUrl && videoRenderMode === "offthread" && (
        <OffthreadVideo src={videoUrl} pauseWhenBuffering />
      )}
      <StyleRenderComponent
        words={words}
        sentences={sentences}
        phrases={phrases}
        values={styleValues}
      />
    </AbsoluteFill>
  );
}

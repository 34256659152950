import { useEffect, useState } from "react";
import Layout from "./layout";
import { useNavigate } from "react-router-dom";
import { authCheck } from "../api/lit-captions";

type AuthCheckStatus =
  | "loading"
  | "authenticated"
  | "not-authenticated"
  | "failed";

export const RootRedirector = () => {
  const [authCheckStatus, setAuthCheckStatus] =
    useState<AuthCheckStatus>("loading");

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      setAuthCheckStatus("loading");
      try {
        const { authenticated, hasLoggedInBefore } = await authCheck();
        if (authenticated) {
          setAuthCheckStatus("authenticated");
          navigate("/projects");
        } else {
          setAuthCheckStatus("not-authenticated");
          if (!hasLoggedInBefore) {
            navigate("/signup");
          } else {
            navigate("/login");
          }
        }
      } catch (e) {
        setAuthCheckStatus("failed");
        console.error(e);
      }
    };
    checkAuth();
  }, []);

  return (
    <Layout>
      <div className="flex-1 flex flex-col items-center justify-center pb-40">
        {authCheckStatus === "loading" ? (
          <p className="animate-pulse">Loading...</p>
        ) : authCheckStatus === "not-authenticated" ? (
          <p>Redirecting to login...</p>
        ) : authCheckStatus === "failed" ? (
          <p>Failed to load. Try refreshing the page.</p>
        ) : authCheckStatus === "authenticated" ? (
          <p className="animate-pulse">Redirecting to dashboard...</p>
        ) : null}
      </div>
    </Layout>
  );
};

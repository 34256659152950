import { PlayerRef } from "@remotion/player";
import { Fullscreen, Pause, Play } from "lucide-react";
import { usePlayer } from "../../helpers/player.hook";
import clsx from "clsx";
import { CaptionPlayerSeekbar } from "./seekbar";

type Props = {
  playerRef: React.RefObject<PlayerRef>;
  durationInFrames: number;
  fps: number;
};
export function CaptionPlayerControls({
  playerRef,
  durationInFrames,
  fps,
}: Props) {
  const { isPlaying, togglePlay, requestFullscreen } = usePlayer(playerRef, {
    durationInFrames,
    fps,
  });

  return (
    <div className="flex mt-2 gap-2">
      <div
        className="py-2 cursor-pointer text-lit-purple hover:opacity-70 transition-opacity"
        onClick={togglePlay}
      >
        {isPlaying ? <Pause /> : <Play />}
      </div>
      <div className="flex-1 flex items-center">
        <CaptionPlayerSeekbar
          playerRef={playerRef}
          durationInFrames={durationInFrames}
          fps={fps}
        />
      </div>
      <div
        className="py-2 cursor-pointer text-lit-purple hover:opacity-70 transition-opacity"
        onClick={requestFullscreen}
      >
        <Fullscreen />
      </div>
    </div>
  );
}

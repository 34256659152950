import SeriesWordRenderer from "@/src/components/caption-renderer/series-word-renderer";
import {
  CaptionStyleComponentProps,
  CaptionStyleSpec,
  ColorPropertyValue,
  PropertyValue,
  Word,
} from "@/src/types";
import { interpolate, useCurrentFrame, useVideoConfig } from "remotion";

type Property = "textColor" | "bgColor";

export const FadedWordsSpec = {
  properties: {
    textColor: {
      label: "Text",
      propertyType: "color",
    },
    bgColor: {
      label: "Background",
      propertyType: "color",
    },
  },
} satisfies CaptionStyleSpec<Property>;

export function FadedWordsDefaultValues(): Record<Property, PropertyValue> {
  return {
    textColor: "#E6FF94",
    bgColor: "#355070",
  };
}

function SingleFadedWord({
  word,
  textColor,
  bgColor,
}: {
  word: Word;
  textColor: string;
  bgColor: string;
}) {
  const frame = useCurrentFrame();
  const { fps, durationInFrames } = useVideoConfig();
  const animationDurationInFrames = Math.min(
    Math.floor(0.05 * fps),
    Math.floor(durationInFrames / 2) - 1
  );
  const opacity =
    animationDurationInFrames > 1 && durationInFrames > 5
      ? interpolate(
          frame,
          [
            0,
            animationDurationInFrames,
            durationInFrames - animationDurationInFrames,
            durationInFrames,
          ],
          [0, 1, 1, 0],
          {
            extrapolateLeft: "clamp",
            extrapolateRight: "clamp",
          }
        )
      : 1;
  return (
    <div
      className="w-full flex flex-col items-center justify-center"
      style={{ opacity }}
    >
      <div
        className="text-8xl px-10 py-5 rounded-3xl"
        style={{
          backgroundColor: bgColor,
          color: textColor,
        }}
      >
        {word.text}
      </div>
    </div>
  );
}

export function FadedWordsComponent({
  words,
  values,
}: CaptionStyleComponentProps<Property>) {
  const backgroundColor = values.bgColor as ColorPropertyValue;
  const textColor = values.textColor as ColorPropertyValue;
  if (!backgroundColor || !textColor) {
    console.error("Missing required options");
    return null;
  }
  return (
    <SeriesWordRenderer
      words={words}
      renderWord={(word) => (
        <SingleFadedWord
          word={word}
          bgColor={backgroundColor}
          textColor={textColor}
        />
      )}
    />
  );
}

import { Word, WordSequence } from "@/src/types";
import clsx from "clsx";
import { useCurrentFrame, useVideoConfig } from "remotion";
import { WordSequencesRenderer } from "./word-sequences-renderer";

export function SequencesRenderer({
  sequences,
  rootClassName,
  sequenceTextClassName,
  wrapperClassName,
  wrapperStyles,
  sequenceTextStyles,
  wordTextStyles,
  hightlightedWordTextStyles,
  renderWord,
}: {
  sequences: WordSequence[];
  rootClassName?: string;
  sequenceTextClassName?: string;
  wrapperClassName?: string;
  wrapperStyles?: React.CSSProperties;
  sequenceTextStyles?: React.CSSProperties;
  wordTextStyles?: React.CSSProperties;
  hightlightedWordTextStyles?: React.CSSProperties;
  renderWord?: (
    word: Word,
    index: number,
    highlighted: boolean,
    show: boolean
  ) => React.ReactNode;
}) {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const renderSequence = (sequence: WordSequence) => (
    <div
      className={clsx(
        "w-full flex flex-col items-center justify-end",
        rootClassName
      )}
    >
      <div
        className={clsx("w-full flex flex-col justify-end", wrapperClassName)}
        style={wrapperStyles}
      >
        <div className={sequenceTextClassName} style={sequenceTextStyles}>
          {sequence.words.map(
            (
              word: { text: string; start: number; end: number },
              index: number
            ) => {
              const highlight =
                frame >= (word.start / 1000) * fps &&
                frame <= (word.end / 1000) * fps;
              const show = frame >= (word.start / 1000) * fps;
              if (renderWord) {
                return renderWord(word, index, highlight, show);
              } else {
                return (
                  <span
                    key={index}
                    style={{
                      opacity: show ? 1 : 0,
                      ...wordTextStyles,
                      ...(highlight ? hightlightedWordTextStyles : {}),
                    }}
                  >
                    {word.text}{" "}
                  </span>
                );
              }
            }
          )}
        </div>
      </div>
    </div>
  );

  return (
    <WordSequencesRenderer
      sequences={sequences}
      renderSequence={renderSequence}
    />
  );
}

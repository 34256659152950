import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { getProject } from "../api/lit-captions";
import { Project } from "../types";
import { useManagedApi } from "../helpers/managed-api.hook";

export const ProjectPageRedirector = () => {
  const { id } = useParams();

  const getProjectM = useManagedApi(getProject);

  const [project, setProject] = useState<Project>();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        if (!id) {
          return;
        }
        const projectId = parseInt(id);
        const project = await getProjectM(projectId);
        setProject(project);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProject();
  }, []);

  if (!project) {
    return null;
  }
  const {
    videoAsset: { status, readyStatus },
  } = project;

  if (
    readyStatus !== null
      ? readyStatus === "READY"
      : status === "TRANSCRIPT_SAVED"
  ) {
    return <Navigate to={`/projects/${id}/edit`} replace />;
  }
  return <Navigate to={`/projects/${id}/status`} replace />;
};

import { TimedText, WordSequence } from "@/src/types";
import { useMemo } from "react";
import { Sequence, useVideoConfig } from "remotion";

function useDurationsWithGaps(timedTexts: TimedText[]) {
  const gapsAfterTexts = useMemo(() => {
    return timedTexts.map((text, index) => {
      if (index === timedTexts.length - 1) {
        return 0;
      }
      return timedTexts[index + 1].start - text.end;
    });
  }, [timedTexts]);
  const durations = useMemo(() => {
    return timedTexts.map((text, index) => {
      return text.end - text.start + gapsAfterTexts[index];
    });
  }, [timedTexts, gapsAfterTexts]);
  return durations;
}

export function WordSequencesRenderer({
  sequences,
  renderSequence,
}: {
  sequences: WordSequence[];
  renderSequence: (sequence: WordSequence) => React.ReactNode;
}) {
  const { fps } = useVideoConfig();
  const adjustedDurations = useDurationsWithGaps(sequences);
  return (
    <>
      {sequences.map((sequence, index) => (
        <Sequence
          key={index}
          from={(sequence.start / 1000) * fps}
          durationInFrames={Math.ceil((adjustedDurations[index] / 1000) * fps)}
        >
          {renderSequence(sequence)}
        </Sequence>
      ))}
    </>
  );
}

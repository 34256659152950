import clsx from "clsx";

type Props = {
  type: "determinate" | "indeterminate";
  value?: number;
};

export function ProgressBar({ type, value = 0 }: Props) {
  return (
    <div className="flex flex-1 relative border border-lit-purple h-3 rounded-xl overflow-clip">
      <div
        className={clsx(
          "absolute top-0 h-full rounded-xl left-0 bg-lit-purple",
          {
            "w-[25%] animate-progress-bar-slide": type === "indeterminate",
            "transition-[width]": type === "determinate",
          }
          // "animate-progress-bar-slide"
        )}
        style={
          type === "determinate"
            ? {
                width: `${Math.min(100, Math.max(0, value))}%`,
              }
            : {}
        }
      ></div>
    </div>
  );
}

export function identifyGtm(
  userId: string,
  {
    email,
    name,
  }: {
    email: string;
    name?: string;
  }
) {
  console.log("Identifying user in GTM", { userId, email, name });
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({
    event: "identify",
    userId,
    userEmail: email,
    userName: name,
  });
}

import { Button } from "@/components/ui/button";
import Layout from "./layout";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteProject, getProjects } from "../api/lit-captions";
import { Project, ProjectWithDetails } from "../types";
import { useToast } from "@/components/ui/use-toast";
import { Loader2Icon, Trash2, Trash2Icon } from "lucide-react";
import { CaptionThumbnail } from "../components/caption-thumbnail";
import { useManagedApi } from "../helpers/managed-api.hook";

export default function ProjectsHome() {
  const [projects, setProjects] = useState<ProjectWithDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { toast } = useToast();

  const getProjectsM = useManagedApi(getProjects);
  const deleteProjectM = useManagedApi(deleteProject);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const projects = await getProjectsM();
        setProjects(projects);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (id: number) => {
    try {
      await deleteProjectM(id);
      const projects = await getProjectsM();
      setProjects(projects);
    } catch (error) {
      console.error(error);
      toast({
        variant: "destructive",
        title: "Error deleting project",
        description: error.message,
      });
    }
  };

  const handleCreateProject = () => {
    navigate("/projects/new");
  };

  return (
    <Layout activePage="projects">
      <div className="flex justify-center flex-1">
        <div className="flex-1 max-w-4xl px-8 pt-8 pb-4 flex flex-col gap-8">
          <div className="flex justify-between">
            <h1>Projects</h1>
            <Link to="/projects/new">
              <Button variant="default">Upload video</Button>
            </Link>
          </div>
          {loading ? (
            <div className="flex items-center gap-2">
              <Loader2Icon className="w-6 h-6 animate-spin" />
              <p>Fetching your projects...</p>
            </div>
          ) : projects.length > 0 ? (
            <div className="flex gap-10 flex-wrap overflow-auto max-h-[80svh] vertical-visible-scrollbar">
              {projects.map((project) => {
                const {
                  snapshotUrl,
                  transcript,
                  name = "Untitled Project",
                } = project;
                return (
                  <div
                    key={project.id}
                    className="group flex flex-col gap-1 justify-start w-min items-center relative"
                  >
                    <Link
                      to={`/projects/${project.id}`}
                      className="flex flex-col gap-1"
                    >
                      <div
                        className="h-[320px] w-[180px] bg-slate-100 rounded-md bg-cover"
                        style={
                          snapshotUrl
                            ? {
                                // snapshot as background
                                backgroundImage: `url(${snapshotUrl})`,
                              }
                            : undefined
                        }
                      >
                        {project.captionStyle &&
                          transcript &&
                          project.videoAsset.duration && (
                            <CaptionThumbnail
                              captionStylePropertyValues={
                                project.captionStyleSettings || {}
                              }
                              captionStyleConfig={project.captionStyle}
                              captionPlayerParams={{
                                transcript: project.transcript || [],
                                videoDuration: project.videoAsset.duration,
                                videoUrl: "",
                              }}
                            />
                          )}
                      </div>

                      <small
                        className="line-clamp-2 text-wrap truncate text-left"
                        title={name || ""}
                      >
                        {name}
                      </small>
                    </Link>
                    <div
                      className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center cursor-pointer bg-slate-400/0 group-hover:bg-slate-400 transition-colors rounded-md"
                      onClick={() => handleDelete(project.id)}
                      title="Delete"
                    >
                      <Trash2Icon className="w-4 h-4 text-white/0 group-hover:text-white hover:opacity-70 transition-all" />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm">
              <div className="flex flex-col items-center gap-2 text-center flex-1">
                <h2>Welcome to Lit Captions</h2>
                <p className="text-sm text-muted-foreground">
                  Create your first project by uploading a video
                </p>
                <Button className="mt-4" onClick={handleCreateProject}>
                  Upload video
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

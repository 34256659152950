import { CaptionStyleConfig } from "@/src/types";
import clsx from "clsx";
import { CaptionThemePreviewVideo } from "./preview-video";
import { forwardRef } from "react";

type Props = {
  selected: boolean;
  styleConfig: CaptionStyleConfig;
  onClick: () => void;
};

export const CaptionThemeCard = forwardRef<HTMLDivElement, Props>(
  ({ selected, styleConfig, onClick }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          "aspect-[9/16] w-[120px] flex flex-col items-center justify-center basis-auto",
          "p-2 rounded border-2 bg-white cursor-pointer transition-colors",
          { "border-lit-purple": selected, "border-transparent": !selected }
        )}
        onClick={onClick}
      >
        <div className="relative w-full h-full object-cover object-center rounded-[2px]">
          {styleConfig.bgPreviewVideo ? (
            <CaptionThemePreviewVideo
              video={styleConfig.bgPreviewVideo.video}
              poster={styleConfig.bgPreviewVideo.poster}
            />
          ) : (
            <>
              <img
                src={styleConfig.bgImg.toString()}
                alt={styleConfig.name}
                className="w-full h-full object-cover object-center rounded-[2px]"
              />
              <div
                className="absolute inset-0 text-[12px] text-white  flex flex-col items-center justify-center text-center overflow-hidden break-words"
                style={{
                  textShadow: "0 0 4px rgba(0,0,0,0.5)",
                }}
              >
                {styleConfig.name}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

import { Button } from "@/components/ui/button";
import { Loader2Icon } from "lucide-react";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { CaptionPlayer } from "../components/caption-player";
import { CaptionThemeSelectorCarousel } from "../components/caption-theme-selector/carousel";
import { ColorPickerButton } from "../components/color-picker-button";
import { useEditorStore } from "../stores/editor-store";
import {
  CaptionStylesConfig,
  DEFAULT_CAPTION_STYLE_CONFIG,
} from "../caption-styles";
import { ColorPropertyValue, GradientColorPropertyValue } from "../types";
import { ExportConfirmationDialog } from "./edit/export-confirmation-dialog";
import Layout from "./layout";
import { getProject } from "../api/lit-captions";
import { useManagedApi } from "../helpers/managed-api.hook";

export default function ProjectEditPage() {
  const { id } = useParams();
  const playerParams = useEditorStore((state) => state.playerParams);
  const captionStyle = useEditorStore((state) => state.captionStyle);
  const captionStylePropertyOverrides = useEditorStore(
    (state) => state.captionStylePropertyOverrides
  );
  const resetStore = useEditorStore((state) => state.resetStore);
  const setPlayerParams = useEditorStore((state) => state.setPlayerParams);
  const setCaptionStylePropertyOverrides = useEditorStore(
    (state) => state.setCaptionStylePropertyOverrides
  );
  const changeCaptionStyle = useEditorStore(
    (state) => state.changeCaptionStyle
  );

  const getProjectM = useManagedApi(getProject);

  const captionStyleConfig = useMemo(() => {
    if (!captionStyle) {
      return null;
    }
    return CaptionStylesConfig.find((style) => style.key === captionStyle);
  }, [captionStyle]);

  useEffect(() => {
    resetStore();
  }, [id]);

  const captionStylePropertyOptions = useMemo(() => {
    if (!captionStyleConfig) {
      return [];
    }
    const { spec, defaultValues } = captionStyleConfig;
    const { properties } = spec;
    return Object.entries(properties).map(([key, { label, propertyType }]) => {
      const value = captionStylePropertyOverrides[key] || defaultValues[key];
      return {
        key,
        label,
        value,
        propertyType,
      };
    });
  }, [captionStyleConfig, captionStylePropertyOverrides]);

  useEffect(() => {
    console.log("captionStylePropertyOptions", captionStylePropertyOptions);
  }, [captionStylePropertyOptions]);

  useEffect(() => {
    if (!id) {
      return;
    }
    const fetchData = async () => {
      try {
        const {
          transcript,
          videoUrl,
          videoAsset,
          captionStyle: captionStyleFromApi,
          captionStyleSettings: captionStyleSettingsFromApi,
        } = await getProjectM(parseInt(id));
        console.log("transcript", transcript);
        setPlayerParams({
          id: parseInt(id),
          playerParams: {
            transcript,
            videoUrl,
            videoAsset,
          },
        });
        changeCaptionStyle(
          captionStyleFromApi || DEFAULT_CAPTION_STYLE_CONFIG.key
        );

        let propertyValues = {};
        if (captionStyleSettingsFromApi) {
          propertyValues = captionStyleSettingsFromApi;
          setCaptionStylePropertyOverrides(propertyValues);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Layout activePage="projects">
      <div className="flex justify-center flex-1 min-w-0">
        <div className="flex-1 max-w-4xl p-6 md:p-8 flex flex-col min-w-0">
          <div className="flex justify-between mb-8 md:mb-16">
            <h1>Edit</h1>
            <ExportConfirmationDialog
              trigger={<Button variant="default">Export Video</Button>}
            />
          </div>
          {playerParams &&
          playerParams.transcript &&
          playerParams.videoAsset &&
          playerParams.videoAsset.duration &&
          playerParams.videoUrl &&
          captionStyle &&
          captionStyleConfig ? (
            <>
              <div className="flex gap-20 flex-col md:flex-row">
                <CaptionPlayer
                  captionPlayerParams={{
                    transcript: playerParams.transcript,
                    videoUrl: playerParams.videoUrl,
                    videoDuration: playerParams.videoAsset.duration,
                  }}
                  captionStyleConfig={captionStyleConfig}
                  captionStylePropertyValues={captionStylePropertyOverrides}
                />
                <div className="flex-1 flex flex-col min-w-0">
                  <h2 className="border-b border-lit-purple/50 pb-2">
                    Select style
                  </h2>
                  <div className="mt-6">
                    <CaptionThemeSelectorCarousel
                      captionStyle={captionStyle}
                      setCaptionStyle={changeCaptionStyle}
                    />
                  </div>
                  <div className="mt-8">
                    <h3 className="inline border-b border-lit-purple/50 pb-1.5">
                      Customize style
                    </h3>
                  </div>

                  <h4 className="mt-8">Colors</h4>
                  <div className="ml-2 mt-4 grid grid-cols-[minmax(150px,1fr),2fr] gap-4 items-center">
                    {captionStylePropertyOptions.map(
                      ({ key, label, value, propertyType }) => {
                        return (
                          <>
                            <div
                              key={`${key}-label`}
                              className="text-lit-purple"
                            >
                              {label}
                            </div>
                            <div key={key} className="flex">
                              {propertyType === "color" ? (
                                <ColorPickerButton
                                  color={value as ColorPropertyValue}
                                  setColor={(color) => {
                                    setCaptionStylePropertyOverrides({
                                      ...captionStylePropertyOverrides,
                                      [key]: color,
                                    });
                                  }}
                                />
                              ) : propertyType === "gradientColor" ? (
                                <div className="flex gap-2">
                                  <ColorPickerButton
                                    color={
                                      (value as GradientColorPropertyValue)[0]
                                    }
                                    setColor={(color) => {
                                      setCaptionStylePropertyOverrides({
                                        ...captionStylePropertyOverrides,
                                        [key]: [
                                          color,
                                          (
                                            value as GradientColorPropertyValue
                                          )[1],
                                        ],
                                      });
                                    }}
                                  />
                                  <ColorPickerButton
                                    color={
                                      (value as GradientColorPropertyValue)[1]
                                    }
                                    setColor={(color) => {
                                      setCaptionStylePropertyOverrides({
                                        ...captionStylePropertyOverrides,
                                        [key]: [
                                          (
                                            value as GradientColorPropertyValue
                                          )[0],
                                          color,
                                        ],
                                      });
                                    }}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex items-center gap-2">
              <Loader2Icon className="w-6 h-6 animate-spin" />
              <p>Loading your project...</p>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

import { Link, useParams } from "react-router-dom";
import Layout from "./layout";
import { useCallback, useEffect, useState } from "react";
import { getExport } from "../api/lit-captions";
import { Export } from "../types";
import { ProgressBar } from "../components/progress-bar";
import { Button } from "@/components/ui/button";
import { useUserStore } from "../stores/user.store";
import { useManagedApi } from "../helpers/managed-api.hook";

export default function ExportPage() {
  const { id } = useParams();
  const exportId = parseInt(id);
  const [shouldPoll, setShouldPoll] = useState(true);
  const [exportInst, setExportInst] = useState<Export | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const fetchUser = useUserStore((state) => state.fetchUser);

  const fetchUserM = useManagedApi(fetchUser);
  const getExportM = useManagedApi(getExport);

  useEffect(() => {
    if (!exportId) {
      return;
    }

    const pollExportStatus = async () => {
      const { exportInst, previewUrl } = await getExportM(exportId);
      setExportInst(exportInst);
      setPreviewUrl(previewUrl);
      if (exportInst.status === "COMPLETED") {
        setShouldPoll(false);
        // Fetch user to update credits
        fetchUserM();
      }
    };

    if (shouldPoll) {
      const interval = setInterval(pollExportStatus, 1000);
      return () => clearInterval(interval);
    }
  }, [exportId, shouldPoll]);

  const heading = (() => {
    if (!exportInst) {
      return "";
    }
    if (exportInst.status === "QUEUED" || exportInst.status === "PROCESSING") {
      return "Exporting";
    }
    if (exportInst.status === "COMPLETED" || exportInst.status === "FAILED") {
      return "Export";
    }
    return "";
  })();

  return (
    <Layout activePage="exports">
      <div className="flex justify-center flex-1">
        <div className="flex-1 max-w-4xl px-8 pt-8 pb-4 flex flex-col">
          <div className="flex justify-between">
            <h1>{heading}</h1>
            {exportInst && (
              <Link
                className="mt-4 underline underline-offset-4"
                to={`/projects/${exportInst.project.id}`}
              >
                Back to project
              </Link>
            )}
          </div>
          <div className="mt-8">
            {(exportInst?.status === "QUEUED" ||
              exportInst?.status === "PROCESSING") && (
              <>
                <div>Your project is being exported</div>
                <div>
                  This shouldn't take too long, but there's never a wrong time
                  to grab some coffee.
                </div>
                <div className="mt-8 md:mt-4 w-full md:w-2/3 flex items-center gap-2">
                  <ProgressBar
                    type={
                      exportInst?.status === "QUEUED"
                        ? "indeterminate"
                        : "determinate"
                    }
                    value={exportInst?.progress || 1}
                  />
                  <small className="w-8 text-right md:text-left text-sm font-medium leading-none">
                    {Math.floor(exportInst?.progress || 0)}%
                  </small>
                </div>
              </>
            )}
            {exportInst?.status === "COMPLETED" && (
              <div>
                <div className="mt-2">Your export is ready to download</div>
                {previewUrl && (
                  <div className="mt-8 md:mt-4 flex flex-col md:flex-row gap-8 mb-10 md:mb-0 md:gap-20">
                    <video
                      controls
                      className="w-full md:w-[405px]"
                      src={previewUrl}
                    ></video>
                    <div>
                      <Button>
                        <a href={previewUrl} download>
                          Download Video
                        </a>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {exportInst?.status === "FAILED" && (
              <div>
                <h3 className="text-red-800">Export failed</h3>
                <p className="mt-2">
                  Something went wrong while exporting your project.
                </p>
                <div className="mt-8 md:mt-4 flex gap-20">
                  <Button>Contact support</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

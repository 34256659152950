import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CreateNewProject from "./pages/create-new-project";
import { ProjectPageRedirector } from "./pages/project-redirector";
import ProjectPreparingPage from "./pages/project-status";
import ProjectEditPage from "./pages/project-edit";
import ProjectFixCaptionTextPage from "./pages/project-fix-caption-text";
import ExportPage from "./pages/export";
import ProjectsHome from "./pages/projects-home";
import { Login } from "./pages/login";
import { Signup } from "./pages/signup";
import { NotFound } from "./pages/not-found";
import { PostCheckout } from "./pages/post-checkout";
import ExportsPage from "./pages/exports";
import { LocationChangeTracker } from "./components/location-change-tracker";
import { RootRedirector } from "./pages/root-redirector";
import { UnuthenticatedDialog } from "./components/unauthenticated-dialog";
import ProjectStatusPage from "./pages/project-status";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/exports/:id" element={<ExportPage />} />
        <Route path="/exports" element={<ExportsPage />} />
        <Route
          path="/projects/:id/fix-caption-text"
          element={<ProjectFixCaptionTextPage />}
        />
        <Route path="/projects/:id/edit" element={<ProjectEditPage />} />
        <Route path="/projects/:id/status" element={<ProjectStatusPage />} />
        <Route path="/projects/:id" element={<ProjectPageRedirector />} />
        <Route path="/projects/new" element={<CreateNewProject />} />
        <Route path="/projects" element={<ProjectsHome />} />
        <Route path="/postcheckout" element={<PostCheckout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/" element={<RootRedirector />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <LocationChangeTracker />
      <UnuthenticatedDialog />
    </BrowserRouter>
  );
}

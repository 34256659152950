import { useNavigate, useParams } from "react-router-dom";
import { useManagedApi } from "../helpers/managed-api.hook";
import { getProject } from "../api/lit-captions";
import { useCallback, useEffect, useRef, useState } from "react";
import { Project } from "../types";
import Layout from "./layout";
import { ProgressBar } from "../components/progress-bar";
import { Button } from "@/components/ui/button";

export default function ProjectStatusPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const getProjectM = useManagedApi(getProject);

  const [project, setProject] = useState<Project>();

  const fetchProject = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      const projectId = parseInt(id);
      const project = await getProjectM(projectId);
      setProject(project);
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  useEffect(() => {
    fetchProject();
  }, [id]);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchProject();
    }, 3000);
    intervalRef.current = interval;
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [id]);

  const isProjectReady = project
    ? project.videoAsset.readyStatus !== null
      ? project.videoAsset.readyStatus === "READY"
      : project.videoAsset.status === "TRANSCRIPT_SAVED"
    : false;

  useEffect(() => {
    if (isProjectReady) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      setTimeout(() => {
        navigate(`/projects/${id}/edit`);
      }, 1000);
    }
  }, [isProjectReady, id]);

  if (!project) {
    return null;
  }

  const isPreparing = project
    ? project.videoAsset.readyStatus !== null
      ? project.videoAsset.readyStatus === "PROCESSING" ||
        project.videoAsset.readyStatus === "UPLOADED"
      : project.videoAsset.status !== "TRANSCRIPT_SAVED"
    : false;

  const isError = project
    ? project.videoAsset.readyStatus !== null
      ? project.videoAsset.readyStatus === "ERROR"
      : false
    : false;

  return (
    <Layout activePage="projects">
      <div className="flex justify-center flex-1">
        <div className="flex-1 max-w-4xl px-8 pt-8 pb-4 flex flex-col gap-8">
          <h1>Create new project</h1>
          {isPreparing ? (
            <div className="flex flex-col gap-8">
              <div>
                <h3>Preparing your project</h3>
                <p className="mt-2">
                  Preparing your project for editing and captioning.
                  <br />
                  This should only take at most a minute or two.
                </p>
                <div className="mt-4 w-2/3 flex items-center gap-2">
                  <ProgressBar type="indeterminate" />
                </div>
              </div>
            </div>
          ) : isError ? (
            <div className="flex flex-col gap-8">
              <div>
                <h3 className="text-red-800">Something went wrong</h3>
                <p className="mt-2">
                  We couldn't create your project. Please try again later.
                </p>
                <div className="mt-4 w-2/3 flex items-center gap-2">
                  <Button onClick={() => navigate("/projects/new")}>
                    Try again
                  </Button>
                </div>
              </div>
            </div>
          ) : isProjectReady ? (
            <div className="flex flex-col gap-8">
              <div>
                <h3 className="text-green-800">Project created successfully</h3>
                <p className="mt-2">Redirecting you to your project page...</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
}

import { PlayerRef } from "@remotion/player";
import { usePlayer } from "../../helpers/player.hook";

type Props = {
  playerRef: React.RefObject<PlayerRef>;
  durationInFrames: number;
  fps: number;
};
export function CaptionPlayerInteractiveLayer({
  playerRef,
  durationInFrames,
  fps,
}: Props) {
  const { togglePlay } = usePlayer(playerRef, {
    durationInFrames,
    fps,
  });

  return (
    <div
      className="absolute inset-0 bg-transparent"
      onClick={() => togglePlay()}
    />
  );
}

var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, {
      get: all[name],
      enumerable: true,
      configurable: true,
      set: (newValue) => all[name] = () => newValue
    });
};

// src/_check-rsc.ts
import {createContext} from "react";
if (typeof createContext !== "function") {
  const err = [
    'Remotion requires React.createContext, but it is "undefined".',
    'If you are in a React Server Component, turn it into a client component by adding "use client" at the top of the file.',
    "",
    "Before:",
    '  import {useCurrentFrame} from "remotion";',
    "",
    "After:",
    '  "use client";',
    '  import {useCurrentFrame} from "remotion";'
  ];
  throw new Error(err.join("\n"));
}

// src/Clipper.tsx
import {useContext, useEffect} from "react";

// src/NativeLayers.tsx
import {createContext as createContext2, useLayoutEffect, useMemo, useState} from "react";
import {
jsx
} from "react/jsx-runtime";
var NativeLayersContext = createContext2({
  setClipRegion: () => {
    throw new Error("NativeLayers not set");
  },
  clipRegion: null
});
var NativeLayersProvider = ({
  children
}) => {
  const [clipRegion, setClipRegion] = useState(null);
  const context = useMemo(() => {
    return {
      setClipRegion,
      clipRegion
    };
  }, [clipRegion, setClipRegion]);
  if (typeof window !== "undefined") {
    useLayoutEffect(() => {
      window.remotion_getClipRegion = () => {
        return clipRegion;
      };
    }, [clipRegion, setClipRegion]);
  }
  return jsx(NativeLayersContext.Provider, {
    value: context,
    children
  });
};

// src/Clipper.tsx
var Clipper = ({ height, width, x, y }) => {
  const { setClipRegion } = useContext(NativeLayersContext);
  useEffect(() => {
    setClipRegion((c) => {
      if (c === "hide") {
        throw new Error("Cannot render <Clipper>, because another <Null> is already rendered");
      }
      if (c === null) {
        return { height, width, x, y };
      }
      throw new Error("Cannot render <Clipper>, because another component clipping the region was already rendered (most likely <Clipper>)");
    });
    return () => {
      setClipRegion(null);
    };
  }, [height, setClipRegion, width, x, y]);
  return null;
};

// src/enable-sequence-stack-traces.ts
import React from "react";

// src/get-remotion-environment.ts
var getNodeEnvString = function() {
  return ["NOD", "E_EN", "V"].join("");
};
var getEnvString = () => {
  return ["e", "nv"].join("");
};
var getRemotionEnvironment = () => {
  const isPlayer = typeof window !== "undefined" && window.remotion_isPlayer;
  const isRendering = typeof window !== "undefined" && typeof window.process !== "undefined" && typeof window.process.env !== "undefined" && (window.process[getEnvString()][getNodeEnvString()] === "test" || window.process[getEnvString()][getNodeEnvString()] === "production" && typeof window !== "undefined" && typeof window.remotion_puppeteerTimeout !== "undefined");
  const isStudio = typeof window !== "undefined" && window.remotion_isStudio;
  return {
    isStudio,
    isRendering,
    isPlayer
  };
};

// src/enable-sequence-stack-traces.ts
var originalCreateElement = React.createElement;
var componentsToAddStacksTo = [];
var enableSequenceStackTraces = () => {
  if (!getRemotionEnvironment().isStudio) {
    return;
  }
  const proxy = new Proxy(originalCreateElement, {
    apply(target, thisArg, argArray) {
      if (componentsToAddStacksTo.includes(argArray[0])) {
        const [first, props, ...rest] = argArray;
        const newProps = {
          ...props ?? {},
          stack: new Error().stack
        };
        return Reflect.apply(target, thisArg, [first, newProps, ...rest]);
      }
      return Reflect.apply(target, thisArg, argArray);
    }
  });
  React.createElement = proxy;
};
var addSequenceStackTraces = (component) => {
  componentsToAddStacksTo.push(component);
  enableSequenceStackTraces();
};

// src/is-player.tsx
import {createContext as createContext3, useContext as useContext2} from "react";
import {
jsx as jsx2
} from "react/jsx-runtime";
var IsPlayerContext = createContext3(false);
var IsPlayerContextProvider = ({
  children
}) => {
  return jsx2(IsPlayerContext.Provider, {
    value: true,
    children
  });
};
var useIsPlayer = () => {
  return useContext2(IsPlayerContext);
};

// src/truthy.ts
function truthy(value) {
  return Boolean(value);
}

// src/version.ts
var VERSION = "4.0.200";

// src/multiple-versions-warning.ts
var checkMultipleRemotionVersions = () => {
  if (typeof globalThis === "undefined") {
    return;
  }
  const alreadyImported = globalThis.remotion_imported || typeof window !== "undefined" && window.remotion_imported;
  if (alreadyImported) {
    if (alreadyImported === VERSION) {
      return;
    }
    throw new TypeError(`\uD83D\uDEA8 Multiple versions of Remotion detected: ${[
      VERSION,
      typeof alreadyImported === "string" ? alreadyImported : "an older version"
    ].filter(truthy).join(" and ")}. This will cause things to break in an unexpected way.\nCheck that all your Remotion packages are on the same version. If your dependencies depend on Remotion, make them peer dependencies. You can also run \`npx remotion versions\` from your terminal to see which versions are mismatching.`);
  }
  globalThis.remotion_imported = VERSION;
  if (typeof window !== "undefined") {
    window.remotion_imported = VERSION;
  }
};

// src/Null.tsx
import {useContext as useContext3, useEffect as useEffect2} from "react";
var Null = () => {
  const { setClipRegion } = useContext3(NativeLayersContext);
  useEffect2(() => {
    setClipRegion((c) => {
      if (c === null) {
        return "hide";
      }
      if (c === "hide") {
        return "hide";
      }
      throw new Error("Cannot render <Null>, because another component clipping the region was already rendered (most likely <Clipper>)");
    });
    return () => {
      setClipRegion(null);
    };
  }, [setClipRegion]);
  return null;
};

// src/Sequence.tsx
import {
forwardRef as forwardRef2,
useContext as useContext12,
useEffect as useEffect5,
useMemo as useMemo10,
useState as useState5
} from "react";

// src/AbsoluteFill.tsx
import {forwardRef, useMemo as useMemo2} from "react";
import {
jsx as jsx3
} from "react/jsx-runtime";
var AbsoluteFillRefForwarding = (props, ref) => {
  const { style, ...other } = props;
  const actualStyle = useMemo2(() => {
    return {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      ...style
    };
  }, [style]);
  return jsx3("div", {
    ref,
    style: actualStyle,
    ...other
  });
};
var AbsoluteFill = forwardRef(AbsoluteFillRefForwarding);

// src/SequenceContext.tsx
import {createContext as createContext4} from "react";
var SequenceContext = createContext4(null);

// src/SequenceManager.tsx
import React3, {useCallback, useMemo as useMemo3, useState as useState2} from "react";
import {
jsx as jsx4
} from "react/jsx-runtime";
var SequenceManager = React3.createContext({
  registerSequence: () => {
    throw new Error("SequenceManagerContext not initialized");
  },
  unregisterSequence: () => {
    throw new Error("SequenceManagerContext not initialized");
  },
  sequences: []
});
var SequenceVisibilityToggleContext = React3.createContext({
  hidden: {},
  setHidden: () => {
    throw new Error("SequenceVisibilityToggle not initialized");
  }
});
var SequenceManagerProvider = ({ children }) => {
  const [sequences, setSequences] = useState2([]);
  const [hidden, setHidden] = useState2({});
  const registerSequence = useCallback((seq) => {
    setSequences((seqs) => {
      return [...seqs, seq];
    });
  }, []);
  const unregisterSequence = useCallback((seq) => {
    setSequences((seqs) => seqs.filter((s) => s.id !== seq));
  }, []);
  const sequenceContext = useMemo3(() => {
    return {
      registerSequence,
      sequences,
      unregisterSequence
    };
  }, [registerSequence, sequences, unregisterSequence]);
  const hiddenContext = useMemo3(() => {
    return {
      hidden,
      setHidden
    };
  }, [hidden]);
  return jsx4(SequenceManager.Provider, {
    value: sequenceContext,
    children: jsx4(SequenceVisibilityToggleContext.Provider, {
      value: hiddenContext,
      children
    })
  });
};

// src/nonce.ts
import {createContext as createContext5, useContext as useContext4, useEffect as useEffect3, useRef, useState as useState3} from "react";
var NonceContext = createContext5({
  getNonce: () => 0,
  fastRefreshes: 0
});
var useNonce = () => {
  const context = useContext4(NonceContext);
  const [nonce, setNonce] = useState3(() => context.getNonce());
  const lastContext = useRef(context);
  useEffect3(() => {
    if (lastContext.current === context) {
      return;
    }
    lastContext.current = context;
    setNonce(context.getNonce);
  }, [context]);
  return nonce;
};

// src/timeline-position-state.ts
var exports_timeline_position_state = {};
__export(exports_timeline_position_state, {
  useTimelineSetFrame: () => useTimelineSetFrame,
  useTimelinePosition: () => useTimelinePosition,
  usePlayingState: () => usePlayingState,
  persistCurrentFrame: () => persistCurrentFrame,
  getInitialFrameState: () => getInitialFrameState,
  getFrameForComposition: () => getFrameForComposition,
  TimelineContext: () => TimelineContext,
  SetTimelineContext: () => SetTimelineContext
});
import {createContext as createContext9, useContext as useContext7, useMemo as useMemo7} from "react";

// src/use-video.ts
import {useContext as useContext6, useMemo as useMemo6} from "react";

// src/CompositionManagerContext.tsx
import {createContext as createContext6} from "react";
var CompositionManager = createContext6({
  compositions: [],
  registerComposition: () => {
    return;
  },
  unregisterComposition: () => {
    return;
  },
  registerFolder: () => {
    return;
  },
  unregisterFolder: () => {
    return;
  },
  setCurrentCompositionMetadata: () => {
    return;
  },
  updateCompositionDefaultProps: () => {
    return;
  },
  folders: [],
  currentCompositionMetadata: null,
  canvasContent: null,
  setCanvasContent: () => {
    return;
  }
});

// src/ResolveCompositionConfig.tsx
import {
createContext as createContext8,
createRef,
useCallback as useCallback3,
useContext as useContext5,
useEffect as useEffect4,
useImperativeHandle as useImperativeHandle2,
useMemo as useMemo5,
useState as useState4
} from "react";

// src/EditorProps.tsx
import React4, {
createContext as createContext7,
useCallback as useCallback2,
useImperativeHandle,
useMemo as useMemo4
} from "react";
import {
jsx as jsx5
} from "react/jsx-runtime";
var EditorPropsContext = createContext7({
  props: {},
  updateProps: () => {
    throw new Error("Not implemented");
  },
  resetUnsaved: () => {
    throw new Error("Not implemented");
  }
});
var editorPropsProviderRef = React4.createRef();
var EditorPropsProvider = ({ children }) => {
  const [props, setProps] = React4.useState({});
  const updateProps = useCallback2(({
    defaultProps,
    id,
    newProps
  }) => {
    setProps((prev) => {
      return {
        ...prev,
        [id]: typeof newProps === "function" ? newProps(prev[id] ?? defaultProps) : newProps
      };
    });
  }, []);
  const resetUnsaved = useCallback2(() => {
    setProps({});
  }, []);
  useImperativeHandle(editorPropsProviderRef, () => {
    return {
      getProps: () => props,
      setProps
    };
  }, [props]);
  const ctx = useMemo4(() => {
    return { props, updateProps, resetUnsaved };
  }, [props, resetUnsaved, updateProps]);
  return jsx5(EditorPropsContext.Provider, {
    value: ctx,
    children
  });
};

// src/static-file.ts
var problematicCharacters = {
  "%3A": ":",
  "%2F": "/",
  "%3F": "?",
  "%23": "#",
  "%5B": "[",
  "%5D": "]",
  "%40": "@",
  "%21": "!",
  "%24": "$",
  "%26": "&",
  "%27": "'",
  "%28": "(",
  "%29": ")",
  "%2A": "*",
  "%2B": "+",
  "%2C": ",",
  "%3B": ";"
};
var didWarn = {};
var warnOnce = (message) => {
  if (didWarn[message]) {
    return;
  }
  console.warn(message);
  didWarn[message] = true;
};
var includesHexOfUnsafeChar = (path) => {
  for (const key of Object.keys(problematicCharacters)) {
    if (path.includes(key)) {
      return { containsHex: true, hexCode: key };
    }
  }
  return { containsHex: false };
};
var trimLeadingSlash = (path) => {
  if (path.startsWith("/")) {
    return trimLeadingSlash(path.substring(1));
  }
  return path;
};
var inner = (path) => {
  if (typeof window !== "undefined" && window.remotion_staticBase) {
    if (path.startsWith(window.remotion_staticBase)) {
      throw new Error(`The value "${path}" is already prefixed with the static base ${window.remotion_staticBase}. You don't need to call staticFile() on it.`);
    }
    return `${window.remotion_staticBase}/${trimLeadingSlash(path)}`;
  }
  return `/${trimLeadingSlash(path)}`;
};
var encodeBySplitting = (path) => {
  const splitBySlash = path.split("/");
  const encodedArray = splitBySlash.map((element) => {
    return encodeURIComponent(element);
  });
  const merged = encodedArray.join("/");
  return merged;
};
var staticFile = (path) => {
  if (path.startsWith("http://") || path.startsWith("https://")) {
    throw new TypeError(`staticFile() does not support remote URLs - got "${path}". Instead, pass the URL without wrapping it in staticFile(). See: https://remotion.dev/docs/staticfile-remote-urls`);
  }
  if (path.startsWith("..") || path.startsWith("./")) {
    throw new TypeError(`staticFile() does not support relative paths - got "${path}". Instead, pass the name of a file that is inside the public/ folder. See: https://remotion.dev/docs/staticfile-relative-paths`);
  }
  if (path.startsWith("/Users") || path.startsWith("/home") || path.startsWith("/tmp") || path.startsWith("/etc") || path.startsWith("/opt") || path.startsWith("/var") || path.startsWith("C:") || path.startsWith("D:") || path.startsWith("E:")) {
    throw new TypeError(`staticFile() does not support absolute paths - got "${path}". Instead, pass the name of a file that is inside the public/ folder. See: https://remotion.dev/docs/staticfile-relative-paths`);
  }
  if (path.startsWith("public/")) {
    throw new TypeError(`Do not include the public/ prefix when using staticFile() - got "${path}". See: https://remotion.dev/docs/staticfile-relative-paths`);
  }
  const includesHex = includesHexOfUnsafeChar(path);
  if (includesHex.containsHex) {
    warnOnce(`WARNING: You seem to pass an already encoded path (path contains ${includesHex.hexCode}). Since Remotion 4.0, the encoding is done by staticFile() itself. You may want to remove a encodeURIComponent() wrapping.`);
  }
  const preprocessed = encodeBySplitting(path);
  const preparsed = inner(preprocessed);
  if (!preparsed.startsWith("/")) {
    return `/${preparsed}`;
  }
  return preparsed;
};

// src/input-props-serialization.ts
var DATE_TOKEN = "remotion-date:";
var FILE_TOKEN = "remotion-file:";
var serializeJSONWithDate = ({
  data,
  indent,
  staticBase
}) => {
  let customDateUsed = false;
  let customFileUsed = false;
  let mapUsed = false;
  let setUsed = false;
  try {
    const serializedString = JSON.stringify(data, function(key, value) {
      const item = this[key];
      if (item instanceof Date) {
        customDateUsed = true;
        return `${DATE_TOKEN}${item.toISOString()}`;
      }
      if (item instanceof Map) {
        mapUsed = true;
        return value;
      }
      if (item instanceof Set) {
        setUsed = true;
        return value;
      }
      if (typeof item === "string" && staticBase !== null && item.startsWith(staticBase)) {
        customFileUsed = true;
        return `${FILE_TOKEN}${item.replace(staticBase + "/", "")}`;
      }
      return value;
    }, indent);
    return { serializedString, customDateUsed, customFileUsed, mapUsed, setUsed };
  } catch (err) {
    throw new Error("Could not serialize the passed input props to JSON: " + err.message);
  }
};
var deserializeJSONWithCustomFields = (data) => {
  return JSON.parse(data, (_, value) => {
    if (typeof value === "string" && value.startsWith(DATE_TOKEN)) {
      return new Date(value.replace(DATE_TOKEN, ""));
    }
    if (typeof value === "string" && value.startsWith(FILE_TOKEN)) {
      return staticFile(value.replace(FILE_TOKEN, ""));
    }
    return value;
  });
};
var serializeThenDeserializeInStudio = (props) => {
  if (getRemotionEnvironment().isStudio) {
    return deserializeJSONWithCustomFields(serializeJSONWithDate({
      data: props,
      indent: 2,
      staticBase: window.remotion_staticBase
    }).serializedString);
  }
  return props;
};

// src/config/input-props.ts
var didWarnSSRImport = false;
var warnOnceSSRImport = () => {
  if (didWarnSSRImport) {
    return;
  }
  didWarnSSRImport = true;
  console.warn("Called `getInputProps()` on the server. This function is not available server-side and has returned an empty object.");
  console.warn("To hide this warning, don't call this function on the server:");
  console.warn("  typeof window === 'undefined' ? {} : getInputProps()");
};
var getInputProps = () => {
  if (typeof window === "undefined") {
    warnOnceSSRImport();
    return {};
  }
  if (getRemotionEnvironment().isPlayer) {
    throw new Error("You cannot call `getInputProps()` from a <Player>. Instead, the props are available as React props from component that you passed as `component` prop.");
  }
  const param = window.remotion_inputProps;
  if (!param) {
    return {};
  }
  const parsed = deserializeJSONWithCustomFields(param);
  return parsed;
};

// src/codec.ts
var validCodecs = [
  "h264",
  "h265",
  "vp8",
  "vp9",
  "mp3",
  "aac",
  "wav",
  "prores",
  "h264-mkv",
  "h264-ts",
  "gif"
];

// src/validation/validate-default-codec.ts
function validateDefaultCodec(defaultCodec, location) {
  if (typeof defaultCodec === "undefined") {
    return;
  }
  if (typeof defaultCodec !== "string") {
    throw new TypeError(`The "defaultCodec" prop ${location} must be a string, but you passed a value of type ${typeof defaultCodec}.`);
  }
  if (!validCodecs.includes(defaultCodec)) {
    throw new Error(`The "defaultCodec" prop ${location} must be one of ${validCodecs.join(", ")}, but you passed ${defaultCodec}.`);
  }
}

// src/validation/validate-dimensions.ts
function validateDimension(amount, nameOfProp, location) {
  if (typeof amount !== "number") {
    throw new Error(`The "${nameOfProp}" prop ${location} must be a number, but you passed a value of type ${typeof amount}`);
  }
  if (isNaN(amount)) {
    throw new TypeError(`The "${nameOfProp}" prop ${location} must not be NaN, but is NaN.`);
  }
  if (!Number.isFinite(amount)) {
    throw new TypeError(`The "${nameOfProp}" prop ${location} must be finite, but is ${amount}.`);
  }
  if (amount % 1 !== 0) {
    throw new TypeError(`The "${nameOfProp}" prop ${location} must be an integer, but is ${amount}.`);
  }
  if (amount <= 0) {
    throw new TypeError(`The "${nameOfProp}" prop ${location} must be positive, but got ${amount}.`);
  }
}

// src/validation/validate-duration-in-frames.ts
function validateDurationInFrames(durationInFrames, options) {
  const { allowFloats, component } = options;
  if (typeof durationInFrames === "undefined") {
    throw new Error(`The "durationInFrames" prop ${component} is missing.`);
  }
  if (typeof durationInFrames !== "number") {
    throw new Error(`The "durationInFrames" prop ${component} must be a number, but you passed a value of type ${typeof durationInFrames}`);
  }
  if (durationInFrames <= 0) {
    throw new TypeError(`The "durationInFrames" prop ${component} must be positive, but got ${durationInFrames}.`);
  }
  if (!allowFloats && durationInFrames % 1 !== 0) {
    throw new TypeError(`The "durationInFrames" prop ${component} must be an integer, but got ${durationInFrames}.`);
  }
  if (!Number.isFinite(durationInFrames)) {
    throw new TypeError(`The "durationInFrames" prop ${component} must be finite, but got ${durationInFrames}.`);
  }
}

// src/validation/validate-fps.ts
function validateFps(fps, location, isGif) {
  if (typeof fps !== "number") {
    throw new Error(`"fps" must be a number, but you passed a value of type ${typeof fps} ${location}`);
  }
  if (!Number.isFinite(fps)) {
    throw new Error(`"fps" must be a finite, but you passed ${fps} ${location}`);
  }
  if (isNaN(fps)) {
    throw new Error(`"fps" must not be NaN, but got ${fps} ${location}`);
  }
  if (fps <= 0) {
    throw new TypeError(`"fps" must be positive, but got ${fps} ${location}`);
  }
  if (isGif && fps > 50) {
    throw new TypeError(`The FPS for a GIF cannot be higher than 50. Use the --every-nth-frame option to lower the FPS: https://remotion.dev/docs/render-as-gif`);
  }
}

// src/resolve-video-config.ts
var validateCalculated = ({
  calculated,
  compositionId,
  compositionFps,
  compositionHeight,
  compositionWidth,
  compositionDurationInFrames
}) => {
  const calculateMetadataErrorLocation = `calculated by calculateMetadata() for the composition "${compositionId}"`;
  const defaultErrorLocation = `of the "<Composition />" component with the id "${compositionId}"`;
  const width = calculated?.width ?? compositionWidth ?? undefined;
  validateDimension(width, "width", calculated?.width ? calculateMetadataErrorLocation : defaultErrorLocation);
  const height = calculated?.height ?? compositionHeight ?? undefined;
  validateDimension(height, "height", calculated?.height ? calculateMetadataErrorLocation : defaultErrorLocation);
  const fps = calculated?.fps ?? compositionFps ?? null;
  validateFps(fps, calculated?.fps ? calculateMetadataErrorLocation : defaultErrorLocation, false);
  const durationInFrames = calculated?.durationInFrames ?? compositionDurationInFrames ?? null;
  validateDurationInFrames(durationInFrames, {
    allowFloats: false,
    component: `of the "<Composition />" component with the id "${compositionId}"`
  });
  const defaultCodec = calculated?.defaultCodec;
  validateDefaultCodec(defaultCodec, calculateMetadataErrorLocation);
  return { width, height, fps, durationInFrames, defaultCodec };
};
var resolveVideoConfig = ({
  calculateMetadata,
  signal,
  defaultProps,
  originalProps,
  compositionId,
  compositionDurationInFrames,
  compositionFps,
  compositionHeight,
  compositionWidth
}) => {
  const calculatedProm = calculateMetadata ? calculateMetadata({
    defaultProps,
    props: originalProps,
    abortSignal: signal,
    compositionId
  }) : null;
  if (calculatedProm !== null && typeof calculatedProm === "object" && "then" in calculatedProm) {
    return calculatedProm.then((c) => {
      const { height, width, durationInFrames, fps, defaultCodec } = validateCalculated({
        calculated: c,
        compositionDurationInFrames,
        compositionFps,
        compositionHeight,
        compositionWidth,
        compositionId
      });
      return {
        width,
        height,
        fps,
        durationInFrames,
        id: compositionId,
        defaultProps: serializeThenDeserializeInStudio(defaultProps),
        props: serializeThenDeserializeInStudio(c.props ?? originalProps),
        defaultCodec: defaultCodec ?? null
      };
    });
  }
  const data = validateCalculated({
    calculated: calculatedProm,
    compositionDurationInFrames,
    compositionFps,
    compositionHeight,
    compositionWidth,
    compositionId
  });
  if (calculatedProm === null) {
    return {
      ...data,
      id: compositionId,
      defaultProps: serializeThenDeserializeInStudio(defaultProps ?? {}),
      props: serializeThenDeserializeInStudio(originalProps),
      defaultCodec: null
    };
  }
  return {
    ...data,
    id: compositionId,
    defaultProps: serializeThenDeserializeInStudio(defaultProps ?? {}),
    props: serializeThenDeserializeInStudio(calculatedProm.props ?? originalProps),
    defaultCodec: calculatedProm.defaultCodec ?? null
  };
};
var resolveVideoConfigOrCatch = (params) => {
  try {
    const promiseOrReturnValue = resolveVideoConfig(params);
    return {
      type: "success",
      result: promiseOrReturnValue
    };
  } catch (err) {
    return {
      type: "error",
      error: err
    };
  }
};

// src/ResolveCompositionConfig.tsx
import {
jsx as jsx6
} from "react/jsx-runtime";
var ResolveCompositionContext = createContext8(null);
var resolveCompositionsRef = createRef();
var needsResolution = (composition) => {
  return Boolean(composition.calculateMetadata);
};
var PROPS_UPDATED_EXTERNALLY = "remotion.propsUpdatedExternally";
var ResolveCompositionConfig = ({ children }) => {
  const [currentRenderModalComposition, setCurrentRenderModalComposition] = useState4(null);
  const { compositions, canvasContent, currentCompositionMetadata } = useContext5(CompositionManager);
  const { fastRefreshes } = useContext5(NonceContext);
  const selectedComposition = useMemo5(() => {
    return compositions.find((c) => canvasContent && canvasContent.type === "composition" && canvasContent.compositionId === c.id);
  }, [canvasContent, compositions]);
  const renderModalComposition = compositions.find((c) => c.id === currentRenderModalComposition);
  const { props: allEditorProps } = useContext5(EditorPropsContext);
  const inputProps = useMemo5(() => {
    return typeof window === "undefined" || getRemotionEnvironment().isPlayer ? {} : getInputProps() ?? {};
  }, []);
  const [resolvedConfigs, setResolvedConfigs] = useState4({});
  const selectedEditorProps = useMemo5(() => {
    return selectedComposition ? allEditorProps[selectedComposition.id] ?? {} : {};
  }, [allEditorProps, selectedComposition]);
  const renderModalProps = useMemo5(() => {
    return renderModalComposition ? allEditorProps[renderModalComposition.id] ?? {} : {};
  }, [allEditorProps, renderModalComposition]);
  const hasResolution = Boolean(currentCompositionMetadata);
  const doResolution = useCallback3(({
    calculateMetadata,
    combinedProps,
    compositionDurationInFrames,
    compositionFps,
    compositionHeight,
    compositionId,
    compositionWidth,
    defaultProps
  }) => {
    const controller = new AbortController;
    if (hasResolution) {
      return controller;
    }
    const { signal } = controller;
    const result = resolveVideoConfigOrCatch({
      compositionId,
      calculateMetadata,
      originalProps: combinedProps,
      signal,
      defaultProps,
      compositionDurationInFrames,
      compositionFps,
      compositionHeight,
      compositionWidth
    });
    if (result.type === "error") {
      setResolvedConfigs((r) => ({
        ...r,
        [compositionId]: {
          type: "error",
          error: result.error
        }
      }));
      return controller;
    }
    const promOrNot = result.result;
    if (typeof promOrNot === "object" && "then" in promOrNot) {
      setResolvedConfigs((r) => {
        const prev = r[compositionId];
        if (prev?.type === "success" || prev?.type === "success-and-refreshing") {
          return {
            ...r,
            [compositionId]: {
              type: "success-and-refreshing",
              result: prev.result
            }
          };
        }
        return {
          ...r,
          [compositionId]: {
            type: "loading"
          }
        };
      });
      promOrNot.then((c) => {
        if (controller.signal.aborted) {
          return;
        }
        setResolvedConfigs((r) => ({
          ...r,
          [compositionId]: {
            type: "success",
            result: c
          }
        }));
      }).catch((err) => {
        if (controller.signal.aborted) {
          return;
        }
        setResolvedConfigs((r) => ({
          ...r,
          [compositionId]: {
            type: "error",
            error: err
          }
        }));
      });
    } else {
      setResolvedConfigs((r) => ({
        ...r,
        [compositionId]: {
          type: "success",
          result: promOrNot
        }
      }));
    }
    return controller;
  }, [hasResolution]);
  const currentComposition = canvasContent?.type === "composition" ? canvasContent.compositionId : null;
  useImperativeHandle2(resolveCompositionsRef, () => {
    return {
      setCurrentRenderModalComposition: (id) => {
        setCurrentRenderModalComposition(id);
      },
      reloadCurrentlySelectedComposition: () => {
        if (!currentComposition) {
          return;
        }
        const composition = compositions.find((c) => c.id === currentComposition);
        if (!composition) {
          throw new Error(`Could not find composition with id ${currentComposition}`);
        }
        const editorProps = allEditorProps[currentComposition] ?? {};
        const defaultProps = {
          ...composition.defaultProps ?? {},
          ...editorProps ?? {}
        };
        const props = {
          ...defaultProps,
          ...inputProps ?? {}
        };
        doResolution({
          defaultProps,
          calculateMetadata: composition.calculateMetadata,
          combinedProps: props,
          compositionDurationInFrames: composition.durationInFrames ?? null,
          compositionFps: composition.fps ?? null,
          compositionHeight: composition.height ?? null,
          compositionWidth: composition.width ?? null,
          compositionId: composition.id
        });
      }
    };
  }, [
    allEditorProps,
    compositions,
    currentComposition,
    doResolution,
    inputProps
  ]);
  const isTheSame = selectedComposition?.id === renderModalComposition?.id;
  const currentDefaultProps = useMemo5(() => {
    return {
      ...selectedComposition?.defaultProps ?? {},
      ...selectedEditorProps ?? {}
    };
  }, [selectedComposition?.defaultProps, selectedEditorProps]);
  const originalProps = useMemo5(() => {
    return {
      ...currentDefaultProps,
      ...inputProps ?? {}
    };
  }, [currentDefaultProps, inputProps]);
  const canResolve = selectedComposition && needsResolution(selectedComposition);
  const shouldIgnoreUpdate = typeof window !== "undefined" && window.remotion_ignoreFastRefreshUpdate && fastRefreshes <= window.remotion_ignoreFastRefreshUpdate;
  useEffect4(() => {
    if (shouldIgnoreUpdate) {
      return;
    }
    if (canResolve) {
      const controller = doResolution({
        calculateMetadata: selectedComposition.calculateMetadata,
        combinedProps: originalProps,
        compositionDurationInFrames: selectedComposition.durationInFrames ?? null,
        compositionFps: selectedComposition.fps ?? null,
        compositionHeight: selectedComposition.height ?? null,
        compositionWidth: selectedComposition.width ?? null,
        defaultProps: currentDefaultProps,
        compositionId: selectedComposition.id
      });
      return () => {
        controller.abort();
      };
    }
  }, [
    canResolve,
    currentDefaultProps,
    doResolution,
    originalProps,
    selectedComposition?.calculateMetadata,
    selectedComposition?.durationInFrames,
    selectedComposition?.fps,
    selectedComposition?.height,
    selectedComposition?.id,
    selectedComposition?.width,
    shouldIgnoreUpdate
  ]);
  useEffect4(() => {
    if (shouldIgnoreUpdate) {
      return;
    }
    window.dispatchEvent(new CustomEvent("remotion.propsUpdatedExternally"));
  }, [fastRefreshes]);
  useEffect4(() => {
    if (renderModalComposition && !isTheSame) {
      const combinedProps = {
        ...renderModalComposition.defaultProps ?? {},
        ...renderModalProps ?? {},
        ...inputProps ?? {}
      };
      const controller = doResolution({
        calculateMetadata: renderModalComposition.calculateMetadata,
        compositionDurationInFrames: renderModalComposition.durationInFrames ?? null,
        compositionFps: renderModalComposition.fps ?? null,
        compositionHeight: renderModalComposition.height ?? null,
        compositionId: renderModalComposition.id,
        compositionWidth: renderModalComposition.width ?? null,
        defaultProps: currentDefaultProps,
        combinedProps
      });
      return () => {
        controller.abort();
      };
    }
  }, [
    currentDefaultProps,
    doResolution,
    inputProps,
    isTheSame,
    renderModalComposition,
    renderModalProps
  ]);
  const resolvedConfigsIncludingStaticOnes = useMemo5(() => {
    const staticComps = compositions.filter((c) => {
      return c.calculateMetadata === null;
    });
    return {
      ...resolvedConfigs,
      ...staticComps.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.id]: {
            type: "success",
            result: { ...curr, defaultProps: curr.defaultProps ?? {} }
          }
        };
      }, {})
    };
  }, [compositions, resolvedConfigs]);
  return jsx6(ResolveCompositionContext.Provider, {
    value: resolvedConfigsIncludingStaticOnes,
    children
  });
};
var useResolvedVideoConfig = (preferredCompositionId) => {
  const context = useContext5(ResolveCompositionContext);
  const { props: allEditorProps } = useContext5(EditorPropsContext);
  const { compositions, canvasContent, currentCompositionMetadata } = useContext5(CompositionManager);
  const currentComposition = canvasContent?.type === "composition" ? canvasContent.compositionId : null;
  const compositionId = preferredCompositionId ?? currentComposition;
  const composition = compositions.find((c) => c.id === compositionId);
  const selectedEditorProps = useMemo5(() => {
    return composition ? allEditorProps[composition.id] ?? {} : {};
  }, [allEditorProps, composition]);
  return useMemo5(() => {
    if (!composition) {
      return null;
    }
    if (currentCompositionMetadata) {
      return {
        type: "success",
        result: {
          ...currentCompositionMetadata,
          id: composition.id,
          props: currentCompositionMetadata.props,
          defaultProps: composition.defaultProps ?? {},
          defaultCodec: currentCompositionMetadata.defaultCodec
        }
      };
    }
    if (!needsResolution(composition)) {
      validateDurationInFrames(composition.durationInFrames, {
        allowFloats: false,
        component: `in <Composition id="${composition.id}">`
      });
      validateFps(composition.fps, `in <Composition id="${composition.id}">`, false);
      validateDimension(composition.width, "width", `in <Composition id="${composition.id}">`);
      validateDimension(composition.height, "height", `in <Composition id="${composition.id}">`);
      return {
        type: "success",
        result: {
          width: composition.width,
          height: composition.height,
          fps: composition.fps,
          id: composition.id,
          durationInFrames: composition.durationInFrames,
          defaultProps: composition.defaultProps ?? {},
          props: {
            ...composition.defaultProps ?? {},
            ...selectedEditorProps ?? {},
            ...typeof window === "undefined" || getRemotionEnvironment().isPlayer ? {} : getInputProps() ?? {}
          },
          defaultCodec: null
        }
      };
    }
    if (!context[composition.id]) {
      return null;
    }
    return context[composition.id];
  }, [composition, context, currentCompositionMetadata, selectedEditorProps]);
};

// src/use-video.ts
var useVideo = () => {
  const { canvasContent, compositions, currentCompositionMetadata } = useContext6(CompositionManager);
  const selected = compositions.find((c) => {
    return canvasContent?.type === "composition" && c.id === canvasContent.compositionId;
  });
  const resolved = useResolvedVideoConfig(selected?.id ?? null);
  return useMemo6(() => {
    if (!resolved) {
      return null;
    }
    if (resolved.type === "error") {
      return null;
    }
    if (resolved.type === "loading") {
      return null;
    }
    if (!selected) {
      return null;
    }
    return {
      ...resolved.result,
      defaultProps: selected.defaultProps ?? {},
      id: selected.id,
      ...currentCompositionMetadata ?? {},
      component: selected.component
    };
  }, [currentCompositionMetadata, resolved, selected]);
};

// src/timeline-position-state.ts
var TimelineContext = createContext9({
  frame: {},
  playing: false,
  playbackRate: 1,
  rootId: "",
  imperativePlaying: {
    current: false
  },
  setPlaybackRate: () => {
    throw new Error("default");
  },
  audioAndVideoTags: { current: [] }
});
var SetTimelineContext = createContext9({
  setFrame: () => {
    throw new Error("default");
  },
  setPlaying: () => {
    throw new Error("default");
  }
});
var makeKey = () => {
  return `remotion.time-all`;
};
var persistCurrentFrame = (time) => {
  localStorage.setItem(makeKey(), JSON.stringify(time));
};
var getInitialFrameState = () => {
  const item = localStorage.getItem(makeKey()) ?? "{}";
  const obj = JSON.parse(item);
  return obj;
};
var getFrameForComposition = (composition) => {
  const item = localStorage.getItem(makeKey()) ?? "{}";
  const obj = JSON.parse(item);
  if (obj[composition] !== undefined) {
    return Number(obj[composition]);
  }
  if (typeof window === "undefined") {
    return 0;
  }
  return window.remotion_initialFrame ?? 0;
};
var useTimelinePosition = () => {
  const videoConfig = useVideo();
  const state = useContext7(TimelineContext);
  if (!videoConfig) {
    return typeof window === "undefined" ? 0 : window.remotion_initialFrame ?? 0;
  }
  const unclamped = state.frame[videoConfig.id] ?? (getRemotionEnvironment().isPlayer ? 0 : getFrameForComposition(videoConfig.id));
  return Math.min(videoConfig.durationInFrames - 1, unclamped);
};
var useTimelineSetFrame = () => {
  const { setFrame } = useContext7(SetTimelineContext);
  return setFrame;
};
var usePlayingState = () => {
  const { playing, imperativePlaying } = useContext7(TimelineContext);
  const { setPlaying } = useContext7(SetTimelineContext);
  return useMemo7(() => [playing, setPlaying, imperativePlaying], [imperativePlaying, playing, setPlaying]);
};

// src/use-video-config.ts
import {useContext as useContext9} from "react";

// src/CanUseRemotionHooks.tsx
import {createContext as createContext10} from "react";
import {
jsx as jsx7
} from "react/jsx-runtime";
var CanUseRemotionHooks = createContext10(false);
var CanUseRemotionHooksProvider = ({ children }) => {
  return jsx7(CanUseRemotionHooks.Provider, {
    value: true,
    children
  });
};

// src/use-unsafe-video-config.ts
import {useContext as useContext8, useMemo as useMemo8} from "react";
var useUnsafeVideoConfig = () => {
  const context = useContext8(SequenceContext);
  const ctxWidth = context?.width ?? null;
  const ctxHeight = context?.height ?? null;
  const ctxDuration = context?.durationInFrames ?? null;
  const video = useVideo();
  return useMemo8(() => {
    if (!video) {
      return null;
    }
    const {
      id,
      durationInFrames,
      fps,
      height,
      width,
      defaultProps,
      props,
      defaultCodec
    } = video;
    return {
      id,
      width: ctxWidth ?? width,
      height: ctxHeight ?? height,
      fps,
      durationInFrames: ctxDuration ?? durationInFrames,
      defaultProps,
      props,
      defaultCodec
    };
  }, [ctxDuration, ctxHeight, ctxWidth, video]);
};

// src/use-video-config.ts
var useVideoConfig = () => {
  const videoConfig = useUnsafeVideoConfig();
  const context = useContext9(CanUseRemotionHooks);
  const isPlayer = useIsPlayer();
  if (!videoConfig) {
    if (typeof window !== "undefined" && window.remotion_isPlayer || isPlayer) {
      throw new Error([
        "No video config found. Likely reasons:",
        "- You are probably calling useVideoConfig() from outside the component passed to <Player />. See https://www.remotion.dev/docs/player/examples for how to set up the Player correctly.",
        "- You have multiple versions of Remotion installed which causes the React context to get lost."
      ].join("-"));
    }
    throw new Error("No video config found. You are probably calling useVideoConfig() from a component which has not been registered as a <Composition />. See https://www.remotion.dev/docs/the-fundamentals#defining-compositions for more information.");
  }
  if (!context) {
    throw new Error("Called useVideoConfig() outside a Remotion composition.");
  }
  return videoConfig;
};

// src/freeze.tsx
import {useContext as useContext11, useMemo as useMemo9} from "react";

// src/use-current-frame.ts
import {useContext as useContext10} from "react";
var useCurrentFrame = () => {
  const canUseRemotionHooks = useContext10(CanUseRemotionHooks);
  if (!canUseRemotionHooks) {
    if (getRemotionEnvironment().isPlayer) {
      throw new Error(`useCurrentFrame can only be called inside a component that was passed to <Player>. See: https://www.remotion.dev/docs/player/examples`);
    }
    throw new Error(`useCurrentFrame() can only be called inside a component that was registered as a composition. See https://www.remotion.dev/docs/the-fundamentals#defining-compositions`);
  }
  const frame = useTimelinePosition();
  const context = useContext10(SequenceContext);
  const contextOffset = context ? context.cumulatedFrom + context.relativeFrom : 0;
  return frame - contextOffset;
};

// src/freeze.tsx
import {
jsx as jsx8
} from "react/jsx-runtime";
var Freeze = ({
  frame: frameToFreeze,
  children,
  active = true
}) => {
  const frame = useCurrentFrame();
  const videoConfig = useVideoConfig();
  if (typeof frameToFreeze === "undefined") {
    throw new Error(`The <Freeze /> component requires a 'frame' prop, but none was passed.`);
  }
  if (typeof frameToFreeze !== "number") {
    throw new Error(`The 'frame' prop of <Freeze /> must be a number, but is of type ${typeof frameToFreeze}`);
  }
  if (Number.isNaN(frameToFreeze)) {
    throw new Error(`The 'frame' prop of <Freeze /> must be a real number, but it is NaN.`);
  }
  if (!Number.isFinite(frameToFreeze)) {
    throw new Error(`The 'frame' prop of <Freeze /> must be a finite number, but it is ${frameToFreeze}.`);
  }
  const isActive = useMemo9(() => {
    if (typeof active === "boolean") {
      return active;
    }
    if (typeof active === "function") {
      return active(frame);
    }
  }, [active, frame]);
  const timelineContext = useContext11(TimelineContext);
  const sequenceContext = useContext11(SequenceContext);
  const relativeFrom = sequenceContext?.relativeFrom ?? 0;
  const timelineValue = useMemo9(() => {
    if (!isActive) {
      return timelineContext;
    }
    return {
      ...timelineContext,
      playing: false,
      imperativePlaying: {
        current: false
      },
      frame: {
        [videoConfig.id]: frameToFreeze + relativeFrom
      }
    };
  }, [isActive, timelineContext, videoConfig.id, frameToFreeze, relativeFrom]);
  return jsx8(TimelineContext.Provider, {
    value: timelineValue,
    children
  });
};

// src/Sequence.tsx
import {
jsx as jsx9
} from "react/jsx-runtime";
var RegularSequenceRefForwardingFunction = ({
  from = 0,
  durationInFrames = Infinity,
  children,
  name,
  height,
  width,
  showInTimeline = true,
  _remotionInternalLoopDisplay: loopDisplay,
  _remotionInternalStack: stack,
  _remotionInternalPremountDisplay: premountDisplay,
  ...other
}, ref) => {
  const { layout = "absolute-fill" } = other;
  const [id] = useState5(() => String(Math.random()));
  const parentSequence = useContext12(SequenceContext);
  const { rootId } = useContext12(TimelineContext);
  const cumulatedFrom = parentSequence ? parentSequence.cumulatedFrom + parentSequence.relativeFrom : 0;
  const nonce3 = useNonce();
  if (layout !== "absolute-fill" && layout !== "none") {
    throw new TypeError(`The layout prop of <Sequence /> expects either "absolute-fill" or "none", but you passed: ${layout}`);
  }
  if (layout === "none" && typeof other.style !== "undefined") {
    throw new TypeError('If layout="none", you may not pass a style.');
  }
  if (typeof durationInFrames !== "number") {
    throw new TypeError(`You passed to durationInFrames an argument of type ${typeof durationInFrames}, but it must be a number.`);
  }
  if (durationInFrames <= 0) {
    throw new TypeError(`durationInFrames must be positive, but got ${durationInFrames}`);
  }
  if (typeof from !== "number") {
    throw new TypeError(`You passed to the "from" props of your <Sequence> an argument of type ${typeof from}, but it must be a number.`);
  }
  if (!Number.isFinite(from)) {
    throw new TypeError(`The "from" prop of a sequence must be finite, but got ${from}.`);
  }
  const absoluteFrame = useTimelinePosition();
  const videoConfig = useVideoConfig();
  const parentSequenceDuration = parentSequence ? Math.min(parentSequence.durationInFrames - from, durationInFrames) : durationInFrames;
  const actualDurationInFrames = Math.max(0, Math.min(videoConfig.durationInFrames - from, parentSequenceDuration));
  const { registerSequence, unregisterSequence } = useContext12(SequenceManager);
  const { hidden } = useContext12(SequenceVisibilityToggleContext);
  const premounting = useMemo10(() => {
    return parentSequence?.premounting ?? Boolean(other._remotionInternalIsPremounting);
  }, [other._remotionInternalIsPremounting, parentSequence?.premounting]);
  const contextValue = useMemo10(() => {
    return {
      cumulatedFrom,
      relativeFrom: from,
      durationInFrames: actualDurationInFrames,
      parentFrom: parentSequence?.relativeFrom ?? 0,
      id,
      height: height ?? parentSequence?.height ?? null,
      width: width ?? parentSequence?.width ?? null,
      premounting
    };
  }, [
    cumulatedFrom,
    from,
    actualDurationInFrames,
    parentSequence,
    id,
    height,
    width,
    premounting
  ]);
  const timelineClipName = useMemo10(() => {
    return name ?? "";
  }, [name]);
  useEffect5(() => {
    if (!getRemotionEnvironment().isStudio) {
      return;
    }
    registerSequence({
      from,
      duration: actualDurationInFrames,
      id,
      displayName: timelineClipName,
      parent: parentSequence?.id ?? null,
      type: "sequence",
      rootId,
      showInTimeline,
      nonce: nonce3,
      loopDisplay,
      stack: stack ?? null,
      premountDisplay: premountDisplay ?? null
    });
    return () => {
      unregisterSequence(id);
    };
  }, [
    durationInFrames,
    id,
    name,
    registerSequence,
    timelineClipName,
    unregisterSequence,
    parentSequence?.id,
    actualDurationInFrames,
    rootId,
    from,
    showInTimeline,
    nonce3,
    loopDisplay,
    stack,
    premountDisplay
  ]);
  const endThreshold = Math.ceil(cumulatedFrom + from + durationInFrames - 1);
  const content = absoluteFrame < cumulatedFrom + from ? null : absoluteFrame > endThreshold ? null : children;
  const styleIfThere = other.layout === "none" ? undefined : other.style;
  const defaultStyle = useMemo10(() => {
    return {
      flexDirection: undefined,
      ...width ? { width } : {},
      ...height ? { height } : {},
      ...styleIfThere ?? {}
    };
  }, [height, styleIfThere, width]);
  if (ref !== null && layout === "none") {
    throw new TypeError('It is not supported to pass both a `ref` and `layout="none"` to <Sequence />.');
  }
  const isSequenceHidden = hidden[id] ?? false;
  if (isSequenceHidden) {
    return null;
  }
  return jsx9(SequenceContext.Provider, {
    value: contextValue,
    children: content === null ? null : other.layout === "none" ? content : jsx9(AbsoluteFill, {
      ref,
      style: defaultStyle,
      className: other.className,
      children: content
    })
  });
};
var RegularSequence = forwardRef2(RegularSequenceRefForwardingFunction);
var PremountedSequenceRefForwardingFunction = (props, ref) => {
  const frame = useCurrentFrame();
  if (props.layout === "none") {
    throw new Error('`<Sequence>` with `premountFor` prop does not support layout="none"');
  }
  const { style: passedStyle, from = 0, premountFor = 0, ...otherProps } = props;
  const premountingActive = frame < from && frame >= from - premountFor;
  const style = useMemo10(() => {
    return {
      ...passedStyle,
      opacity: premountingActive ? 0 : 1,
      pointerEvents: premountingActive ? "none" : passedStyle?.pointerEvents ?? undefined
    };
  }, [premountingActive, passedStyle]);
  return jsx9(Freeze, {
    frame: from,
    active: premountingActive,
    children: jsx9(Sequence, {
      ref,
      from,
      style,
      _remotionInternalPremountDisplay: premountFor,
      _remotionInternalIsPremounting: premountingActive,
      ...otherProps
    })
  });
};
var PremountedSequence = forwardRef2(PremountedSequenceRefForwardingFunction);
var SequenceRefForwardingFunction = (props, ref) => {
  if (props.layout !== "none" && props.premountFor && !getRemotionEnvironment().isRendering) {
    return jsx9(PremountedSequence, {
      ...props,
      ref
    });
  }
  return jsx9(RegularSequence, {
    ...props,
    ref
  });
};
var Sequence = forwardRef2(SequenceRefForwardingFunction);
// src/Artifact.tsx
import {useContext as useContext13, useEffect as useEffect6, useState as useState7} from "react";

// src/RenderAssetManager.tsx
import {
createContext as createContext11,
useCallback as useCallback4,
useLayoutEffect as useLayoutEffect2,
useMemo as useMemo11,
useState as useState6
} from "react";

// src/validation/validate-artifact.ts
var validateArtifactFilename = (filename) => {
  if (typeof filename !== "string") {
    throw new TypeError(`The "filename" must be a string, but you passed a value of type ${typeof filename}`);
  }
  if (filename.trim() === "") {
    throw new Error("The `filename` must not be empty");
  }
  if (!filename.match(/^([0-9a-zA-Z-!_.*'()/:&$@=;+,?]+)/g)) {
    throw new Error('The `filename` must match "/^([0-9a-zA-Z-!_.*\'()/:&$@=;+,?]+)/g". Use forward slashes only, even on Windows.');
  }
};
var validateContent = (content) => {
  if (typeof content !== "string" && !(content instanceof Uint8Array)) {
    throw new TypeError(`The "content" must be a string or Uint8Array, but you passed a value of type ${typeof content}`);
  }
  if (typeof content === "string" && content.trim() === "") {
    throw new Error("The `content` must not be empty");
  }
};
var validateRenderAsset = (artifact) => {
  if (artifact.type !== "artifact") {
    return;
  }
  validateArtifactFilename(artifact.filename);
  validateContent(artifact.content);
};

// src/RenderAssetManager.tsx
import {
jsx as jsx10
} from "react/jsx-runtime";
var RenderAssetManager = createContext11({
  registerRenderAsset: () => {
    return;
  },
  unregisterRenderAsset: () => {
    return;
  },
  renderAssets: []
});
var RenderAssetManagerProvider = ({ children }) => {
  const [renderAssets, setRenderAssets] = useState6([]);
  const registerRenderAsset = useCallback4((renderAsset) => {
    validateRenderAsset(renderAsset);
    setRenderAssets((assets) => {
      return [...assets, renderAsset];
    });
  }, []);
  const unregisterRenderAsset = useCallback4((id) => {
    setRenderAssets((assts) => {
      return assts.filter((a) => a.id !== id);
    });
  }, []);
  useLayoutEffect2(() => {
    if (typeof window !== "undefined") {
      window.remotion_collectAssets = () => {
        setRenderAssets([]);
        return renderAssets;
      };
    }
  }, [renderAssets]);
  const contextValue = useMemo11(() => {
    return {
      registerRenderAsset,
      unregisterRenderAsset,
      renderAssets
    };
  }, [renderAssets, registerRenderAsset, unregisterRenderAsset]);
  return jsx10(RenderAssetManager.Provider, {
    value: contextValue,
    children
  });
};

// src/Artifact.tsx
var Artifact = ({ filename, content }) => {
  const { registerRenderAsset, unregisterRenderAsset } = useContext13(RenderAssetManager);
  const [env] = useState7(() => getRemotionEnvironment());
  const frame = useCurrentFrame();
  const [id] = useState7(() => {
    return String(Math.random());
  });
  useEffect6(() => {
    if (!env.isRendering) {
      return;
    }
    if (content instanceof Uint8Array) {
      registerRenderAsset({
        type: "artifact",
        id,
        content: btoa(new TextDecoder("utf8").decode(content)),
        filename,
        frame,
        binary: true
      });
    } else {
      registerRenderAsset({
        type: "artifact",
        id,
        content,
        filename,
        frame,
        binary: false
      });
    }
    return () => {
      return unregisterRenderAsset(id);
    };
  }, [
    content,
    env.isRendering,
    filename,
    frame,
    id,
    registerRenderAsset,
    unregisterRenderAsset
  ]);
  return null;
};
// src/audio/Audio.tsx
import {forwardRef as forwardRef5, useCallback as useCallback9, useContext as useContext23} from "react";

// src/absolute-src.ts
var getAbsoluteSrc = (relativeSrc) => {
  if (typeof window === "undefined") {
    return relativeSrc;
  }
  return new URL(relativeSrc, window.origin).href;
};

// src/calculate-loop.ts
var calculateLoopDuration = ({
  endAt,
  mediaDuration,
  playbackRate,
  startFrom
}) => {
  let duration = mediaDuration;
  if (typeof endAt !== "undefined") {
    duration = endAt;
  }
  if (typeof startFrom !== "undefined") {
    duration -= startFrom;
  }
  const actualDuration = duration / playbackRate;
  return Math.floor(actualDuration);
};

// src/cancel-render.ts
function cancelRender(err) {
  let error;
  if (isErrorLike(err)) {
    error = err;
    if (!error.stack) {
      error.stack = new Error(error.message).stack;
    }
  } else if (typeof err === "string") {
    error = Error(err);
  } else {
    error = Error("Rendering was cancelled");
  }
  window.remotion_cancelledError = error.stack;
  throw error;
}
var isErrorLike = (err) => {
  if (err instanceof Error) {
    return true;
  }
  if (err === null) {
    return false;
  }
  if (typeof err !== "object") {
    return false;
  }
  if (!("stack" in err)) {
    return false;
  }
  if (typeof err.stack !== "string") {
    return false;
  }
  if (!("message" in err)) {
    return false;
  }
  if (typeof err.message !== "string") {
    return false;
  }
  return true;
};

// src/loop/index.tsx
import React8, {createContext as createContext12, useMemo as useMemo12} from "react";
import {
jsx as jsx11
} from "react/jsx-runtime";
var LoopContext = createContext12(null);
var useLoop = () => {
  return React8.useContext(LoopContext);
};
var Loop = ({ durationInFrames, times = Infinity, children, name, ...props }) => {
  const currentFrame = useCurrentFrame();
  const { durationInFrames: compDuration } = useVideoConfig();
  validateDurationInFrames(durationInFrames, {
    component: "of the <Loop /> component",
    allowFloats: true
  });
  if (typeof times !== "number") {
    throw new TypeError(`You passed to "times" an argument of type ${typeof times}, but it must be a number.`);
  }
  if (times !== Infinity && times % 1 !== 0) {
    throw new TypeError(`The "times" prop of a loop must be an integer, but got ${times}.`);
  }
  if (times < 0) {
    throw new TypeError(`The "times" prop of a loop must be at least 0, but got ${times}`);
  }
  const maxTimes = Math.ceil(compDuration / durationInFrames);
  const actualTimes = Math.min(maxTimes, times);
  const style = props.layout === "none" ? undefined : props.style;
  const maxFrame = durationInFrames * (actualTimes - 1);
  const iteration = Math.floor(currentFrame / durationInFrames);
  const start = iteration * durationInFrames;
  const from = Math.min(start, maxFrame);
  const loopDisplay = useMemo12(() => {
    return {
      numberOfTimes: actualTimes,
      startOffset: -from,
      durationInFrames
    };
  }, [actualTimes, durationInFrames, from]);
  const loopContext = useMemo12(() => {
    return {
      iteration: Math.floor(currentFrame / durationInFrames),
      durationInFrames
    };
  }, [currentFrame, durationInFrames]);
  return jsx11(LoopContext.Provider, {
    value: loopContext,
    children: jsx11(Sequence, {
      durationInFrames,
      from,
      name: name ?? "<Loop>",
      _remotionInternalLoopDisplay: loopDisplay,
      layout: props.layout,
      style,
      children
    })
  });
};
Loop.useLoop = useLoop;

// src/prefetch.ts
import {useContext as useContext14} from "react";

// src/prefetch-state.tsx
import {createContext as createContext13, useEffect as useEffect7, useState as useState8} from "react";
import {
jsx as jsx12
} from "react/jsx-runtime";
var PreloadContext = createContext13({});
var preloads = {};
var updaters = [];
var setPreloads = (updater) => {
  preloads = updater(preloads);
  updaters.forEach((u) => u());
};
var PrefetchProvider = ({ children }) => {
  const [_preloads, _setPreloads] = useState8(() => preloads);
  useEffect7(() => {
    const updaterFunction = () => {
      _setPreloads(preloads);
    };
    updaters.push(updaterFunction);
    return () => {
      updaters = updaters.filter((u) => u !== updaterFunction);
    };
  }, []);
  return jsx12(PreloadContext.Provider, {
    value: _preloads,
    children
  });
};

// src/prefetch.ts
var usePreload = (src) => {
  const preloads2 = useContext14(PreloadContext);
  return preloads2[src] ?? src;
};
var blobToBase64 = function(blob) {
  const reader = new FileReader;
  return new Promise((resolve, reject) => {
    reader.onload = function() {
      const dataUrl = reader.result;
      resolve(dataUrl);
    };
    reader.onerror = (err) => {
      return reject(err);
    };
    reader.readAsDataURL(blob);
  });
};
var getBlobFromReader = async ({
  reader,
  contentType,
  contentLength,
  onProgress
}) => {
  let receivedLength = 0;
  const chunks = [];
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    chunks.push(value);
    receivedLength += value.length;
    if (onProgress) {
      onProgress({ loadedBytes: receivedLength, totalBytes: contentLength });
    }
  }
  const chunksAll = new Uint8Array(receivedLength);
  let position = 0;
  for (const chunk of chunks) {
    chunksAll.set(chunk, position);
    position += chunk.length;
  }
  return new Blob([chunksAll], {
    type: contentType ?? undefined
  });
};
var prefetch = (src, options) => {
  const method = options?.method ?? "blob-url";
  if (getRemotionEnvironment().isRendering) {
    return {
      free: () => {
        return;
      },
      waitUntilDone: () => Promise.resolve(src)
    };
  }
  let canceled = false;
  let objectUrl = null;
  let resolve = () => {
    return;
  };
  let reject = () => {
    return;
  };
  const waitUntilDone = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  const controller = new AbortController;
  let canBeAborted = true;
  fetch(src, {
    signal: controller.signal
  }).then((res) => {
    canBeAborted = false;
    if (canceled) {
      return null;
    }
    if (!res.ok) {
      throw new Error(`HTTP error, status = ${res.status}`);
    }
    const headerContentType = res.headers.get("Content-Type");
    const contentType = options?.contentType ?? headerContentType;
    const hasProperContentType = contentType && (contentType.startsWith("video/") || contentType.startsWith("audio/") || contentType.startsWith("image/"));
    if (!hasProperContentType) {
      console.warn(`Called prefetch() on ${src} which returned a "Content-Type" of ${headerContentType}. Prefetched content should have a proper content type (video/... or audio/...) or a contentType passed the options of prefetch(). Otherwise, prefetching will not work properly in all browsers.`);
    }
    if (!res.body) {
      throw new Error(`HTTP response of ${src} has no body`);
    }
    const reader = res.body.getReader();
    return getBlobFromReader({
      reader,
      contentType: options?.contentType ?? headerContentType ?? null,
      contentLength: res.headers.get("Content-Length") ? parseInt(res.headers.get("Content-Length"), 10) : null,
      onProgress: options?.onProgress
    });
  }).then((buf) => {
    if (!buf) {
      return;
    }
    const actualBlob = options?.contentType ? new Blob([buf], { type: options.contentType }) : buf;
    if (method === "base64") {
      return blobToBase64(actualBlob);
    }
    return URL.createObjectURL(actualBlob);
  }).then((url) => {
    if (canceled) {
      return;
    }
    objectUrl = url;
    setPreloads((p) => ({
      ...p,
      [src]: objectUrl
    }));
    resolve(objectUrl);
  }).catch((err) => {
    reject(err);
  });
  return {
    free: () => {
      if (objectUrl) {
        if (method === "blob-url") {
          URL.revokeObjectURL(objectUrl);
        }
        setPreloads((p) => {
          const copy = { ...p };
          delete copy[src];
          return copy;
        });
      } else {
        canceled = true;
        if (canBeAborted) {
          try {
            controller.abort();
          } catch (e) {
          }
        }
      }
    },
    waitUntilDone: () => {
      return waitUntilDone;
    }
  };
};

// src/validate-media-props.ts
var validateMediaProps = (props, component) => {
  if (typeof props.volume !== "number" && typeof props.volume !== "function" && typeof props.volume !== "undefined") {
    throw new TypeError(`You have passed a volume of type ${typeof props.volume} to your <${component} /> component. Volume must be a number or a function with the signature '(frame: number) => number' undefined.`);
  }
  if (typeof props.volume === "number" && props.volume < 0) {
    throw new TypeError(`You have passed a volume below 0 to your <${component} /> component. Volume must be between 0 and 1`);
  }
  if (typeof props.playbackRate !== "number" && typeof props.playbackRate !== "undefined") {
    throw new TypeError(`You have passed a playbackRate of type ${typeof props.playbackRate} to your <${component} /> component. Playback rate must a real number or undefined.`);
  }
  if (typeof props.playbackRate === "number" && (isNaN(props.playbackRate) || !Number.isFinite(props.playbackRate) || props.playbackRate <= 0)) {
    throw new TypeError(`You have passed a playbackRate of ${props.playbackRate} to your <${component} /> component. Playback rate must be a real number above 0.`);
  }
};

// src/validate-start-from-props.ts
var validateStartFromProps = (startFrom, endAt) => {
  if (typeof startFrom !== "undefined") {
    if (typeof startFrom !== "number") {
      throw new TypeError(`type of startFrom prop must be a number, instead got type ${typeof startFrom}.`);
    }
    if (isNaN(startFrom) || startFrom === Infinity) {
      throw new TypeError("startFrom prop can not be NaN or Infinity.");
    }
    if (startFrom < 0) {
      throw new TypeError(`startFrom must be greater than equal to 0 instead got ${startFrom}.`);
    }
  }
  if (typeof endAt !== "undefined") {
    if (typeof endAt !== "number") {
      throw new TypeError(`type of endAt prop must be a number, instead got type ${typeof endAt}.`);
    }
    if (isNaN(endAt)) {
      throw new TypeError("endAt prop can not be NaN.");
    }
    if (endAt <= 0) {
      throw new TypeError(`endAt must be a positive number, instead got ${endAt}.`);
    }
  }
  if (endAt < startFrom) {
    throw new TypeError("endAt prop must be greater than startFrom prop.");
  }
};

// src/video/duration-state.tsx
import {createContext as createContext14, useMemo as useMemo13, useReducer} from "react";
import {
jsx as jsx13
} from "react/jsx-runtime";
var durationReducer = (state, action) => {
  switch (action.type) {
    case "got-duration": {
      const absoluteSrc = getAbsoluteSrc(action.src);
      if (state[absoluteSrc] === action.durationInSeconds) {
        return state;
      }
      return {
        ...state,
        [absoluteSrc]: action.durationInSeconds
      };
    }
    default:
      return state;
  }
};
var DurationsContext = createContext14({
  durations: {},
  setDurations: () => {
    throw new Error("context missing");
  }
});
var DurationsContextProvider = ({ children }) => {
  const [durations, setDurations] = useReducer(durationReducer, {});
  const value = useMemo13(() => {
    return {
      durations,
      setDurations
    };
  }, [durations]);
  return jsx13(DurationsContext.Provider, {
    value,
    children
  });
};

// src/audio/AudioForPreview.tsx
import {
forwardRef as forwardRef3,
useContext as useContext21,
useEffect as useEffect16,
useImperativeHandle as useImperativeHandle3,
useMemo as useMemo20,
useRef as useRef8,
useState as useState14
} from "react";

// src/random.ts
var mulberry32 = function(a) {
  let t = a + 1831565813;
  t = Math.imul(t ^ t >>> 15, t | 1);
  t ^= t + Math.imul(t ^ t >>> 7, t | 61);
  return ((t ^ t >>> 14) >>> 0) / 4294967296;
};
var hashCode = function(str) {
  let i = 0;
  let chr = 0;
  let hash = 0;
  for (i = 0;i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};
var random = (seed, dummy) => {
  if (dummy !== undefined) {
    throw new TypeError("random() takes only one argument");
  }
  if (seed === null) {
    return Math.random();
  }
  if (typeof seed === "string") {
    return mulberry32(hashCode(seed));
  }
  if (typeof seed === "number") {
    return mulberry32(seed * 10000000000);
  }
  throw new Error("random() argument must be a number or a string");
};

// src/use-media-in-timeline.ts
import {useContext as useContext16, useEffect as useEffect8, useMemo as useMemo14, useState as useState9} from "react";

// src/audio/use-audio-frame.ts
import {useContext as useContext15} from "react";
var useMediaStartsAt = () => {
  const parentSequence = useContext15(SequenceContext);
  const startsAt = Math.min(0, parentSequence?.relativeFrom ?? 0);
  return startsAt;
};
var useFrameForVolumeProp = (behavior) => {
  const loop2 = Loop.useLoop();
  const frame = useCurrentFrame();
  const startsAt = useMediaStartsAt();
  if (behavior === "repeat" || loop2 === null) {
    return frame + startsAt;
  }
  return frame + startsAt + loop2.durationInFrames * loop2.iteration;
};

// src/get-asset-file-name.ts
var getAssetDisplayName = (filename) => {
  if (/data:|blob:/.test(filename.substring(0, 5))) {
    return "Data URL";
  }
  const splitted = filename.split("/").map((s) => s.split("\\")).flat(1);
  return splitted[splitted.length - 1];
};

// src/play-and-handle-not-allowed-error.ts
var playAndHandleNotAllowedError = (mediaRef, mediaType, onAutoPlayError) => {
  const { current } = mediaRef;
  if (!current) {
    return;
  }
  const prom = current.play();
  if (prom.catch) {
    prom.catch((err) => {
      if (!current) {
        return;
      }
      if (err.message.includes("request was interrupted by a call to pause")) {
        return;
      }
      if (err.message.includes("The operation was aborted.")) {
        return;
      }
      if (err.message.includes("The fetching process for the media resource was aborted by the user agent")) {
        return;
      }
      if (err.message.includes("request was interrupted by a new load request")) {
        return;
      }
      if (err.message.includes("because the media was removed from the document")) {
        return;
      }
      console.log(`Could not play ${mediaType} due to following error: `, err);
      if (!current.muted) {
        if (onAutoPlayError) {
          onAutoPlayError();
          return;
        }
        console.log(`The video will be muted and we'll retry playing it.`);
        if (mediaType === "video" && getRemotionEnvironment().isPlayer) {
          console.log("Use onAutoPlayError() to handle this error yourself.");
        }
        current.muted = true;
        current.play();
      }
    });
  }
};

// src/volume-prop.ts
var evaluateVolume = ({
  frame,
  volume,
  mediaVolume = 1,
  allowAmplificationDuringRender
}) => {
  const maxVolume = allowAmplificationDuringRender ? Infinity : 1;
  if (typeof volume === "number") {
    return Math.min(maxVolume, volume * mediaVolume);
  }
  if (typeof volume === "undefined") {
    return Number(mediaVolume);
  }
  const evaluated = volume(frame) * mediaVolume;
  if (typeof evaluated !== "number") {
    throw new TypeError(`You passed in a a function to the volume prop but it did not return a number but a value of type ${typeof evaluated} for frame ${frame}`);
  }
  if (Number.isNaN(evaluated)) {
    throw new TypeError(`You passed in a function to the volume prop but it returned NaN for frame ${frame}.`);
  }
  if (!Number.isFinite(evaluated)) {
    throw new TypeError(`You passed in a function to the volume prop but it returned a non-finite number for frame ${frame}.`);
  }
  return Math.max(0, Math.min(maxVolume, evaluated));
};

// src/use-media-in-timeline.ts
var didWarn2 = {};
var warnOnce2 = (message) => {
  if (didWarn2[message]) {
    return;
  }
  console.warn(message);
  didWarn2[message] = true;
};
var useMediaInTimeline = ({
  volume,
  mediaVolume,
  mediaRef,
  src,
  mediaType,
  playbackRate,
  displayName,
  id,
  stack,
  showInTimeline,
  premountDisplay,
  onAutoPlayError
}) => {
  const videoConfig = useVideoConfig();
  const { rootId, audioAndVideoTags } = useContext16(TimelineContext);
  const parentSequence = useContext16(SequenceContext);
  const actualFrom = parentSequence ? parentSequence.relativeFrom + parentSequence.cumulatedFrom : 0;
  const [playing] = usePlayingState();
  const startsAt = useMediaStartsAt();
  const { registerSequence, unregisterSequence } = useContext16(SequenceManager);
  const [initialVolume] = useState9(() => volume);
  const nonce4 = useNonce();
  const duration = parentSequence ? Math.min(parentSequence.durationInFrames, videoConfig.durationInFrames) : videoConfig.durationInFrames;
  const doesVolumeChange = typeof volume === "function";
  const volumes = useMemo14(() => {
    if (typeof volume === "number") {
      return volume;
    }
    return new Array(Math.floor(Math.max(0, duration + startsAt))).fill(true).map((_, i) => {
      return evaluateVolume({
        frame: i + startsAt,
        volume,
        mediaVolume,
        allowAmplificationDuringRender: false
      });
    }).join(",");
  }, [duration, startsAt, volume, mediaVolume]);
  useEffect8(() => {
    if (typeof volume === "number" && volume !== initialVolume) {
      warnOnce2(`Remotion: The ${mediaType} with src ${src} has changed it's volume. Prefer the callback syntax for setting volume to get better timeline display: https://www.remotion.dev/docs/using-audio/#controlling-volume`);
    }
  }, [initialVolume, mediaType, src, volume]);
  useEffect8(() => {
    if (!mediaRef.current) {
      return;
    }
    if (!src) {
      throw new Error("No src passed");
    }
    if (!getRemotionEnvironment().isStudio && window.process?.env?.NODE_ENV !== "test") {
      return;
    }
    if (!showInTimeline) {
      return;
    }
    registerSequence({
      type: mediaType,
      src,
      id,
      duration,
      from: 0,
      parent: parentSequence?.id ?? null,
      displayName: displayName ?? getAssetDisplayName(src),
      rootId,
      volume: volumes,
      showInTimeline: true,
      nonce: nonce4,
      startMediaFrom: 0 - startsAt,
      doesVolumeChange,
      loopDisplay: undefined,
      playbackRate,
      stack,
      premountDisplay
    });
    return () => {
      unregisterSequence(id);
    };
  }, [
    actualFrom,
    duration,
    id,
    parentSequence,
    src,
    registerSequence,
    rootId,
    unregisterSequence,
    videoConfig,
    volumes,
    doesVolumeChange,
    nonce4,
    mediaRef,
    mediaType,
    startsAt,
    playbackRate,
    displayName,
    stack,
    showInTimeline,
    premountDisplay
  ]);
  useEffect8(() => {
    const tag = {
      id,
      play: () => {
        if (!playing) {
          return;
        }
        return playAndHandleNotAllowedError(mediaRef, mediaType, onAutoPlayError);
      }
    };
    audioAndVideoTags.current.push(tag);
    return () => {
      audioAndVideoTags.current = audioAndVideoTags.current.filter((a) => a.id !== id);
    };
  }, [audioAndVideoTags, id, mediaRef, mediaType, onAutoPlayError, playing]);
};

// src/use-media-playback.ts
import {useCallback as useCallback7, useContext as useContext18, useEffect as useEffect12, useRef as useRef6} from "react";

// src/buffer-until-first-frame.ts
import {useCallback as useCallback6, useMemo as useMemo17, useRef as useRef3} from "react";

// src/use-buffer-state.ts
import {useContext as useContext17, useMemo as useMemo16} from "react";

// src/buffering.tsx
import React11, {useCallback as useCallback5, useEffect as useEffect9, useMemo as useMemo15, useRef as useRef2, useState as useState10} from "react";
import {
jsx as jsx14
} from "react/jsx-runtime";
var useBufferManager = () => {
  const [blocks, setBlocks] = useState10([]);
  const [onBufferingCallbacks, setOnBufferingCallbacks] = useState10([]);
  const [onResumeCallbacks, setOnResumeCallbacks] = useState10([]);
  const buffering = useRef2(false);
  const addBlock = useCallback5((block) => {
    setBlocks((b) => [...b, block]);
    return {
      unblock: () => {
        setBlocks((b) => {
          const newArr = b.filter((bx) => bx !== block);
          if (newArr.length === b.length) {
            return b;
          }
          return newArr;
        });
      }
    };
  }, []);
  const listenForBuffering = useCallback5((callback) => {
    setOnBufferingCallbacks((c) => [...c, callback]);
    return {
      remove: () => {
        setOnBufferingCallbacks((c) => c.filter((cb) => cb !== callback));
      }
    };
  }, []);
  const listenForResume = useCallback5((callback) => {
    setOnResumeCallbacks((c) => [...c, callback]);
    return {
      remove: () => {
        setOnResumeCallbacks((c) => c.filter((cb) => cb !== callback));
      }
    };
  }, []);
  useEffect9(() => {
    if (blocks.length > 0) {
      onBufferingCallbacks.forEach((c) => c());
    }
  }, [blocks]);
  useEffect9(() => {
    if (blocks.length === 0) {
      onResumeCallbacks.forEach((c) => c());
    }
  }, [blocks]);
  return useMemo15(() => {
    return { addBlock, listenForBuffering, listenForResume, buffering };
  }, [addBlock, buffering, listenForBuffering, listenForResume]);
};
var BufferingContextReact = React11.createContext(null);
var BufferingProvider = ({ children }) => {
  const bufferManager = useBufferManager();
  return jsx14(BufferingContextReact.Provider, {
    value: bufferManager,
    children
  });
};

// src/use-buffer-state.ts
var useBufferState = () => {
  const buffer = useContext17(BufferingContextReact);
  const addBlock = buffer ? buffer.addBlock : null;
  return useMemo16(() => ({
    delayPlayback: () => {
      if (!addBlock) {
        throw new Error("Tried to enable the buffering state, but a Remotion context was not found. This API can only be called in a component that was passed to the Remotion Player or a <Composition>. Or you might have experienced a version mismatch - run `npx remotion versions` and ensure all packages have the same version. This error is thrown by the buffer state https://remotion.dev/docs/player/buffer-state");
      }
      const { unblock } = addBlock({
        id: String(Math.random())
      });
      return { unblock };
    }
  }), [addBlock]);
};

// src/buffer-until-first-frame.ts
var useBufferUntilFirstFrame = ({
  mediaRef,
  mediaType,
  onVariableFpsVideoDetected,
  pauseWhenBuffering
}) => {
  const bufferingRef = useRef3(false);
  const { delayPlayback } = useBufferState();
  const bufferUntilFirstFrame = useCallback6((requestedTime) => {
    if (mediaType !== "video") {
      return;
    }
    if (!pauseWhenBuffering) {
      return;
    }
    const current = mediaRef.current;
    if (!current) {
      return;
    }
    if (!current.requestVideoFrameCallback) {
      return;
    }
    bufferingRef.current = true;
    const playback = delayPlayback();
    const unblock = () => {
      playback.unblock();
      current.removeEventListener("ended", unblock, {
        once: true
      });
      current.removeEventListener("pause", unblock, {
        once: true
      });
      bufferingRef.current = false;
    };
    const onEndedOrPause = () => {
      unblock();
    };
    current.requestVideoFrameCallback((_, info) => {
      const differenceFromRequested = Math.abs(info.mediaTime - requestedTime);
      if (differenceFromRequested > 0.5) {
        onVariableFpsVideoDetected();
      }
      current.requestVideoFrameCallback(() => {
        unblock();
      });
    });
    current.addEventListener("ended", onEndedOrPause, { once: true });
    current.addEventListener("pause", onEndedOrPause, { once: true });
  }, [
    delayPlayback,
    mediaRef,
    mediaType,
    onVariableFpsVideoDetected,
    pauseWhenBuffering
  ]);
  return useMemo17(() => {
    return {
      isBuffering: () => bufferingRef.current,
      bufferUntilFirstFrame
    };
  }, [bufferUntilFirstFrame]);
};

// src/use-media-buffering.ts
import {useEffect as useEffect10, useState as useState11} from "react";
var useMediaBuffering = ({
  element,
  shouldBuffer,
  isPremounting
}) => {
  const buffer = useBufferState();
  const [isBuffering, setIsBuffering] = useState11(false);
  useEffect10(() => {
    let cleanupFns = [];
    const { current } = element;
    if (!current) {
      return;
    }
    if (!shouldBuffer) {
      return;
    }
    if (isPremounting) {
      return;
    }
    const cleanup = () => {
      cleanupFns.forEach((fn) => fn());
      cleanupFns = [];
      setIsBuffering(false);
    };
    const onWaiting = () => {
      setIsBuffering(true);
      const { unblock } = buffer.delayPlayback();
      const onCanPlay = () => {
        cleanup();
        init();
      };
      const onError = () => {
        cleanup();
        init();
      };
      current.addEventListener("canplay", onCanPlay, {
        once: true
      });
      cleanupFns.push(() => {
        current.removeEventListener("canplay", onCanPlay);
      });
      current.addEventListener("error", onError, {
        once: true
      });
      cleanupFns.push(() => {
        current.removeEventListener("error", onError);
      });
      cleanupFns.push(() => {
        unblock();
      });
    };
    const init = () => {
      if (current.readyState < current.HAVE_FUTURE_DATA) {
        onWaiting();
        if (!navigator.userAgent.includes("Firefox/")) {
          current.load();
        }
      } else {
        current.addEventListener("waiting", onWaiting);
        cleanupFns.push(() => {
          current.removeEventListener("waiting", onWaiting);
        });
      }
    };
    init();
    return () => {
      cleanup();
    };
  }, [buffer, element, isPremounting, shouldBuffer]);
  return isBuffering;
};

// src/use-request-video-callback-time.ts
import {useEffect as useEffect11, useRef as useRef4} from "react";
var useRequestVideoCallbackTime = ({
  mediaRef,
  mediaType,
  lastSeek,
  onVariableFpsVideoDetected
}) => {
  const currentTime = useRef4(null);
  useEffect11(() => {
    const { current } = mediaRef;
    if (current) {
      currentTime.current = current.currentTime;
    } else {
      currentTime.current = null;
      return;
    }
    if (mediaType !== "video") {
      currentTime.current = null;
      return;
    }
    const videoTag = current;
    if (!videoTag.requestVideoFrameCallback) {
      return;
    }
    let cancel = () => {
      return;
    };
    const request = () => {
      if (!videoTag) {
        return;
      }
      const cb = videoTag.requestVideoFrameCallback((_, info) => {
        if (currentTime.current !== null) {
          const difference = Math.abs(currentTime.current - info.mediaTime);
          const differenceToLastSeek = Math.abs(lastSeek.current === null ? Infinity : info.mediaTime - lastSeek.current);
          if (difference > 0.5 && differenceToLastSeek > 0.5 && info.mediaTime > currentTime.current) {
            onVariableFpsVideoDetected();
          }
        }
        currentTime.current = info.mediaTime;
        request();
      });
      cancel = () => {
        videoTag.cancelVideoFrameCallback(cb);
        cancel = () => {
          return;
        };
      };
    };
    request();
    return () => {
      cancel();
    };
  }, [lastSeek, mediaRef, mediaType, onVariableFpsVideoDetected]);
  return currentTime;
};

// src/interpolate.ts
var interpolateFunction = function(input, inputRange, outputRange, options) {
  const { extrapolateLeft, extrapolateRight, easing } = options;
  let result = input;
  const [inputMin, inputMax] = inputRange;
  const [outputMin, outputMax] = outputRange;
  if (result < inputMin) {
    if (extrapolateLeft === "identity") {
      return result;
    }
    if (extrapolateLeft === "clamp") {
      result = inputMin;
    } else if (extrapolateLeft === "wrap") {
      const range = inputMax - inputMin;
      result = ((result - inputMin) % range + range) % range + inputMin;
    } else if (extrapolateLeft === "extend") {
    }
  }
  if (result > inputMax) {
    if (extrapolateRight === "identity") {
      return result;
    }
    if (extrapolateRight === "clamp") {
      result = inputMax;
    } else if (extrapolateRight === "wrap") {
      const range = inputMax - inputMin;
      result = ((result - inputMin) % range + range) % range + inputMin;
    } else if (extrapolateRight === "extend") {
    }
  }
  if (outputMin === outputMax) {
    return outputMin;
  }
  result = (result - inputMin) / (inputMax - inputMin);
  result = easing(result);
  result = result * (outputMax - outputMin) + outputMin;
  return result;
};
var findRange = function(input, inputRange) {
  let i;
  for (i = 1;i < inputRange.length - 1; ++i) {
    if (inputRange[i] >= input) {
      break;
    }
  }
  return i - 1;
};
var checkValidInputRange = function(arr) {
  for (let i = 1;i < arr.length; ++i) {
    if (!(arr[i] > arr[i - 1])) {
      throw new Error(`inputRange must be strictly monotonically increasing but got [${arr.join(",")}]`);
    }
  }
};
var checkInfiniteRange = function(name, arr) {
  if (arr.length < 2) {
    throw new Error(name + " must have at least 2 elements");
  }
  for (const element of arr) {
    if (typeof element !== "number") {
      throw new Error(`${name} must contain only numbers`);
    }
    if (!Number.isFinite(element)) {
      throw new Error(`${name} must contain only finite numbers, but got [${arr.join(",")}]`);
    }
  }
};
function interpolate(input, inputRange, outputRange, options) {
  if (typeof input === "undefined") {
    throw new Error("input can not be undefined");
  }
  if (typeof inputRange === "undefined") {
    throw new Error("inputRange can not be undefined");
  }
  if (typeof outputRange === "undefined") {
    throw new Error("outputRange can not be undefined");
  }
  if (inputRange.length !== outputRange.length) {
    throw new Error("inputRange (" + inputRange.length + ") and outputRange (" + outputRange.length + ") must have the same length");
  }
  checkInfiniteRange("inputRange", inputRange);
  checkInfiniteRange("outputRange", outputRange);
  checkValidInputRange(inputRange);
  const easing = options?.easing ?? ((num) => num);
  let extrapolateLeft = "extend";
  if (options?.extrapolateLeft !== undefined) {
    extrapolateLeft = options.extrapolateLeft;
  }
  let extrapolateRight = "extend";
  if (options?.extrapolateRight !== undefined) {
    extrapolateRight = options.extrapolateRight;
  }
  if (typeof input !== "number") {
    throw new TypeError("Cannot interpolate an input which is not a number");
  }
  const range = findRange(input, inputRange);
  return interpolateFunction(input, [inputRange[range], inputRange[range + 1]], [outputRange[range], outputRange[range + 1]], {
    easing,
    extrapolateLeft,
    extrapolateRight
  });
}

// src/video/get-current-time.ts
var getExpectedMediaFrameUncorrected = ({
  frame,
  playbackRate,
  startFrom
}) => {
  return interpolate(frame, [-1, startFrom, startFrom + 1], [-1, startFrom, startFrom + playbackRate]);
};
var getMediaTime = ({
  fps,
  frame,
  playbackRate,
  startFrom
}) => {
  const expectedFrame = getExpectedMediaFrameUncorrected({
    frame,
    playbackRate,
    startFrom
  });
  const msPerFrame = 1000 / fps;
  return expectedFrame * msPerFrame / 1000;
};

// src/video/video-fragment.ts
import {useRef as useRef5} from "react";
var toSeconds = (time, fps) => {
  return Math.round(time / fps * 100) / 100;
};
var isIosSafari = () => {
  if (typeof window === "undefined") {
    return false;
  }
  const isIpadIPodIPhone = /iP(ad|od|hone)/i.test(window.navigator.userAgent);
  const isAppleWebKit = /AppleWebKit/.test(window.navigator.userAgent);
  return isIpadIPodIPhone && isAppleWebKit;
};
var isIOSSafariAndBlob = (actualSrc) => {
  return isIosSafari() && actualSrc.startsWith("blob:");
};
var getVideoFragmentStart = ({
  actualFrom,
  fps
}) => {
  return toSeconds(Math.max(0, -actualFrom), fps);
};
var getVideoFragmentEnd = ({
  duration,
  fps
}) => {
  return toSeconds(duration, fps);
};
var appendVideoFragment = ({
  actualSrc,
  actualFrom,
  duration,
  fps
}) => {
  if (isIOSSafariAndBlob(actualSrc)) {
    return actualSrc;
  }
  if (actualSrc.startsWith("data:")) {
    return actualSrc;
  }
  const existingHash = Boolean(new URL(actualSrc, (typeof window === "undefined" ? null : window.location.href) ?? "http://localhost:3000").hash);
  if (existingHash) {
    return actualSrc;
  }
  if (!Number.isFinite(actualFrom)) {
    return actualSrc;
  }
  const withStartHash = `${actualSrc}#t=${getVideoFragmentStart({ actualFrom, fps })}`;
  if (!Number.isFinite(duration)) {
    return withStartHash;
  }
  return `${withStartHash},${getVideoFragmentEnd({ duration, fps })}`;
};
var isSubsetOfDuration = ({
  prevStartFrom,
  newStartFrom,
  prevDuration,
  newDuration,
  fps
}) => {
  const previousFrom = getVideoFragmentStart({ actualFrom: prevStartFrom, fps });
  const newFrom = getVideoFragmentStart({ actualFrom: newStartFrom, fps });
  const previousEnd = getVideoFragmentEnd({ duration: prevDuration, fps });
  const newEnd = getVideoFragmentEnd({ duration: newDuration, fps });
  if (newFrom < previousFrom) {
    return false;
  }
  if (newEnd > previousEnd) {
    return false;
  }
  return true;
};
var useAppendVideoFragment = ({
  actualSrc: initialActualSrc,
  actualFrom: initialActualFrom,
  duration: initialDuration,
  fps
}) => {
  const actualFromRef = useRef5(initialActualFrom);
  const actualDuration = useRef5(initialDuration);
  const actualSrc = useRef5(initialActualSrc);
  if (!isSubsetOfDuration({
    prevStartFrom: actualFromRef.current,
    newStartFrom: initialActualFrom,
    prevDuration: actualDuration.current,
    newDuration: initialDuration,
    fps
  }) || initialActualSrc !== actualSrc.current) {
    actualFromRef.current = initialActualFrom;
    actualDuration.current = initialDuration;
    actualSrc.current = initialActualSrc;
  }
  const appended = appendVideoFragment({
    actualSrc: actualSrc.current,
    actualFrom: actualFromRef.current,
    duration: actualDuration.current,
    fps
  });
  return appended;
};

// src/warn-about-non-seekable-media.ts
var alreadyWarned = {};
var warnAboutNonSeekableMedia = (ref, type) => {
  if (ref === null) {
    return;
  }
  if (ref.seekable.length === 0) {
    return;
  }
  if (ref.seekable.length > 1) {
    return;
  }
  if (alreadyWarned[ref.src]) {
    return;
  }
  const range = { start: ref.seekable.start(0), end: ref.seekable.end(0) };
  if (range.start === 0 && range.end === 0) {
    const msg = [
      `The media ${ref.src} cannot be seeked. This could be one of few reasons:`,
      "1) The media resource was replaced while the video is playing but it was not loaded yet.",
      "2) The media does not support seeking.",
      "3) The media was loaded with security headers prventing it from being included.",
      "Please see https://remotion.dev/docs/non-seekable-media for assistance."
    ].join("\n");
    if (type === "console-error") {
      console.error(msg);
    } else if (type === "console-warning") {
      console.warn(`The media ${ref.src} does not support seeking. The video will render fine, but may not play correctly in the Remotion Studio and in the <Player>. See https://remotion.dev/docs/non-seekable-media for an explanation.`);
    } else {
      throw new Error(msg);
    }
    alreadyWarned[ref.src] = true;
  }
};

// src/use-media-playback.ts
var DEFAULT_ACCEPTABLE_TIMESHIFT = 0.45;
var seek = (mediaRef, time) => {
  if (!mediaRef.current) {
    return;
  }
  const timeToSet = isIosSafari() ? Number(time.toFixed(1)) : time;
  mediaRef.current.currentTime = timeToSet;
};
var useMediaPlayback = ({
  mediaRef,
  src,
  mediaType,
  playbackRate: localPlaybackRate,
  onlyWarnForMediaSeekingError,
  acceptableTimeshift,
  pauseWhenBuffering,
  isPremounting,
  debugSeeking,
  onAutoPlayError
}) => {
  const { playbackRate: globalPlaybackRate } = useContext18(TimelineContext);
  const frame = useCurrentFrame();
  const absoluteFrame = useTimelinePosition();
  const [playing] = usePlayingState();
  const buffering3 = useContext18(BufferingContextReact);
  const { fps } = useVideoConfig();
  const mediaStartsAt = useMediaStartsAt();
  const lastSeekDueToShift = useRef6(null);
  const lastSeek = useRef6(null);
  if (!buffering3) {
    throw new Error("useMediaPlayback must be used inside a <BufferingContext>");
  }
  const isVariableFpsVideoMap = useRef6({});
  const onVariableFpsVideoDetected = useCallback7(() => {
    if (!src) {
      return;
    }
    if (debugSeeking) {
      console.log(`Detected ${src} as a variable FPS video. Disabling buffering while seeking.`);
    }
    isVariableFpsVideoMap.current[src] = true;
  }, [debugSeeking, src]);
  const currentTime = useRequestVideoCallbackTime({
    mediaRef,
    mediaType,
    lastSeek,
    onVariableFpsVideoDetected
  });
  const desiredUnclampedTime = getMediaTime({
    frame,
    playbackRate: localPlaybackRate,
    startFrom: -mediaStartsAt,
    fps
  });
  const isMediaTagBuffering = useMediaBuffering({
    element: mediaRef,
    shouldBuffer: pauseWhenBuffering,
    isPremounting
  });
  const { bufferUntilFirstFrame, isBuffering } = useBufferUntilFirstFrame({
    mediaRef,
    mediaType,
    onVariableFpsVideoDetected,
    pauseWhenBuffering
  });
  const playbackRate = localPlaybackRate * globalPlaybackRate;
  const acceptableTimeShiftButLessThanDuration = (() => {
    if (mediaRef.current?.duration) {
      return Math.min(mediaRef.current.duration, acceptableTimeshift ?? DEFAULT_ACCEPTABLE_TIMESHIFT);
    }
    return acceptableTimeshift;
  })();
  useEffect12(() => {
    if (!playing) {
      mediaRef.current?.pause();
      return;
    }
    const isPlayerBuffering = buffering3.buffering.current;
    const isMediaTagBufferingOrStalled = isMediaTagBuffering || isBuffering();
    if (isPlayerBuffering && !isMediaTagBufferingOrStalled) {
      mediaRef.current?.pause();
    }
  }, [
    buffering3.buffering,
    isBuffering,
    isMediaTagBuffering,
    mediaRef,
    playing
  ]);
  useEffect12(() => {
    const tagName = mediaType === "audio" ? "<Audio>" : "<Video>";
    if (!mediaRef.current) {
      throw new Error(`No ${mediaType} ref found`);
    }
    if (!src) {
      throw new Error(`No 'src' attribute was passed to the ${tagName} element.`);
    }
    const playbackRateToSet = Math.max(0, playbackRate);
    if (mediaRef.current.playbackRate !== playbackRateToSet) {
      mediaRef.current.playbackRate = playbackRateToSet;
    }
    const { duration } = mediaRef.current;
    const shouldBeTime = !Number.isNaN(duration) && Number.isFinite(duration) ? Math.min(duration, desiredUnclampedTime) : desiredUnclampedTime;
    const mediaTagTime = mediaRef.current.currentTime;
    const rvcTime = currentTime.current ?? null;
    const isVariableFpsVideo = isVariableFpsVideoMap.current[src];
    const timeShiftMediaTag = Math.abs(shouldBeTime - mediaTagTime);
    const timeShiftRvcTag = rvcTime ? Math.abs(shouldBeTime - rvcTime) : null;
    const timeShift = timeShiftRvcTag && !isVariableFpsVideo ? timeShiftRvcTag : timeShiftMediaTag;
    if (debugSeeking) {
      console.log({
        mediaTagTime,
        rvcTime,
        shouldBeTime,
        state: mediaRef.current.readyState,
        playing: !mediaRef.current.paused,
        isVariableFpsVideo
      });
    }
    if (timeShift > acceptableTimeShiftButLessThanDuration && lastSeekDueToShift.current !== shouldBeTime) {
      if (debugSeeking) {
        console.log("Seeking", {
          shouldBeTime,
          isTime: mediaTagTime,
          rvcTime,
          timeShift,
          isVariableFpsVideo
        });
      }
      seek(mediaRef, shouldBeTime);
      lastSeek.current = shouldBeTime;
      lastSeekDueToShift.current = shouldBeTime;
      if (playing && !isVariableFpsVideo) {
        bufferUntilFirstFrame(shouldBeTime);
        if (mediaRef.current.paused) {
          playAndHandleNotAllowedError(mediaRef, mediaType, onAutoPlayError);
        }
      }
      if (!onlyWarnForMediaSeekingError) {
        warnAboutNonSeekableMedia(mediaRef.current, onlyWarnForMediaSeekingError ? "console-warning" : "console-error");
      }
      return;
    }
    const seekThreshold = playing ? 0.15 : 0.00001;
    const makesSenseToSeek = Math.abs(mediaRef.current.currentTime - shouldBeTime) > seekThreshold;
    const isMediaTagBufferingOrStalled = isMediaTagBuffering || isBuffering();
    const isSomethingElseBuffering = buffering3.buffering.current && !isMediaTagBufferingOrStalled;
    if (!playing || isSomethingElseBuffering) {
      if (makesSenseToSeek) {
        seek(mediaRef, shouldBeTime);
        lastSeek.current = shouldBeTime;
      }
      return;
    }
    if (mediaRef.current.paused && !mediaRef.current.ended || absoluteFrame === 0) {
      if (makesSenseToSeek) {
        seek(mediaRef, shouldBeTime);
        lastSeek.current = shouldBeTime;
      }
      playAndHandleNotAllowedError(mediaRef, mediaType, onAutoPlayError);
      if (!isVariableFpsVideo) {
        bufferUntilFirstFrame(shouldBeTime);
      }
    }
  }, [
    absoluteFrame,
    acceptableTimeShiftButLessThanDuration,
    bufferUntilFirstFrame,
    buffering3.buffering,
    currentTime,
    debugSeeking,
    desiredUnclampedTime,
    isBuffering,
    isMediaTagBuffering,
    mediaRef,
    mediaType,
    onlyWarnForMediaSeekingError,
    playbackRate,
    playing,
    src,
    onAutoPlayError
  ]);
};

// src/use-media-tag-volume.ts
import {useEffect as useEffect13, useState as useState12} from "react";
var useMediaTagVolume = (mediaRef) => {
  const [actualVolume, setActualVolume] = useState12(1);
  useEffect13(() => {
    const ref = mediaRef.current;
    if (!ref) {
      return;
    }
    const onChange = () => {
      setActualVolume(ref.volume);
    };
    ref.addEventListener("volumechange", onChange);
    return () => ref.removeEventListener("volumechange", onChange);
  }, [mediaRef]);
  useEffect13(() => {
    const ref = mediaRef.current;
    if (!ref) {
      return;
    }
    if (ref.volume !== actualVolume) {
      setActualVolume(ref.volume);
    }
  }, [actualVolume, mediaRef]);
  return actualVolume;
};

// src/use-sync-volume-with-media-tag.ts
import {useEffect as useEffect14} from "react";

// src/is-approximately-the-same.ts
var FLOATING_POINT_ERROR_THRESHOLD = 0.00001;
var isApproximatelyTheSame = (num1, num2) => {
  return Math.abs(num1 - num2) < FLOATING_POINT_ERROR_THRESHOLD;
};

// src/use-sync-volume-with-media-tag.ts
var useSyncVolumeWithMediaTag = ({
  volumePropFrame,
  actualVolume,
  volume,
  mediaVolume,
  mediaRef
}) => {
  useEffect14(() => {
    const userPreferredVolume = evaluateVolume({
      frame: volumePropFrame,
      volume,
      mediaVolume,
      allowAmplificationDuringRender: false
    });
    if (!isApproximatelyTheSame(userPreferredVolume, actualVolume) && mediaRef.current) {
      mediaRef.current.volume = userPreferredVolume;
    }
  }, [actualVolume, volumePropFrame, mediaRef, volume, mediaVolume]);
};

// src/volume-position-state.ts
import {createContext as createContext15, useContext as useContext19, useMemo as useMemo18} from "react";
var MediaVolumeContext = createContext15({
  mediaMuted: false,
  mediaVolume: 1
});
var SetMediaVolumeContext = createContext15({
  setMediaMuted: () => {
    throw new Error("default");
  },
  setMediaVolume: () => {
    throw new Error("default");
  }
});
var useMediaVolumeState = () => {
  const { mediaVolume } = useContext19(MediaVolumeContext);
  const { setMediaVolume } = useContext19(SetMediaVolumeContext);
  return useMemo18(() => {
    return [mediaVolume, setMediaVolume];
  }, [mediaVolume, setMediaVolume]);
};
var useMediaMutedState = () => {
  const { mediaMuted } = useContext19(MediaVolumeContext);
  const { setMediaMuted } = useContext19(SetMediaVolumeContext);
  return useMemo18(() => {
    return [mediaMuted, setMediaMuted];
  }, [mediaMuted, setMediaMuted]);
};

// src/audio/shared-audio-tags.tsx
import React12, {
createContext as createContext16,
createRef as createRef2,
useCallback as useCallback8,
useContext as useContext20,
useEffect as useEffect15,
useMemo as useMemo19,
useRef as useRef7,
useState as useState13
} from "react";
import {
jsx as jsx15,
jsxs
} from "react/jsx-runtime";
var EMPTY_AUDIO = "data:audio/mp3;base64,/+MYxAAJcAV8AAgAABn//////+/gQ5BAMA+D4Pg+BAQBAEAwD4Pg+D4EBAEAQDAPg++hYBH///hUFQVBUFREDQNHmf///////+MYxBUGkAGIMAAAAP/29Xt6lUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV/+MYxDUAAANIAAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
var compareProps = (obj1, obj2) => {
  const keysA = Object.keys(obj1).sort();
  const keysB = Object.keys(obj2).sort();
  if (keysA.length !== keysB.length) {
    return false;
  }
  for (let i = 0;i < keysA.length; i++) {
    if (keysA[i] !== keysB[i]) {
      return false;
    }
    if (obj1[keysA[i]] !== obj2[keysB[i]]) {
      return false;
    }
  }
  return true;
};
var didPropChange = (key, newProp, prevProp) => {
  if (key === "src" && !prevProp.startsWith("data:") && !newProp.startsWith("data:")) {
    return new URL(prevProp, window.origin).toString() !== new URL(newProp, window.origin).toString();
  }
  if (prevProp === newProp) {
    return false;
  }
  return true;
};
var SharedAudioContext = createContext16(null);
var SharedAudioContextProvider = ({ children, numberOfAudioTags, component }) => {
  const audios = useRef7([]);
  const [initialNumberOfAudioTags] = useState13(numberOfAudioTags);
  if (numberOfAudioTags !== initialNumberOfAudioTags) {
    throw new Error("The number of shared audio tags has changed dynamically. Once you have set this property, you cannot change it afterwards.");
  }
  const refs = useMemo19(() => {
    return new Array(numberOfAudioTags).fill(true).map(() => {
      return { id: Math.random(), ref: createRef2() };
    });
  }, [numberOfAudioTags]);
  const takenAudios = useRef7(new Array(numberOfAudioTags).fill(false));
  const rerenderAudios = useCallback8(() => {
    refs.forEach(({ ref, id }) => {
      const data = audios.current?.find((a) => a.id === id);
      const { current } = ref;
      if (!current) {
        return;
      }
      if (data === undefined) {
        current.src = EMPTY_AUDIO;
        return;
      }
      if (!data) {
        throw new TypeError("Expected audio data to be there");
      }
      Object.keys(data.props).forEach((key) => {
        if (didPropChange(key, data.props[key], current[key])) {
          current[key] = data.props[key];
        }
      });
    });
  }, [refs]);
  const registerAudio = useCallback8((aud, audioId) => {
    const found = audios.current?.find((a) => a.audioId === audioId);
    if (found) {
      return found;
    }
    const firstFreeAudio = takenAudios.current.findIndex((a) => a === false);
    if (firstFreeAudio === -1) {
      throw new Error(`Tried to simultaneously mount ${numberOfAudioTags + 1} <Audio /> tags at the same time. With the current settings, the maximum amount of <Audio /> tags is limited to ${numberOfAudioTags} at the same time. Remotion pre-mounts silent audio tags to help avoid browser autoplay restrictions. See https://remotion.dev/docs/player/autoplay#use-the-numberofsharedaudiotags-property for more information on how to increase this limit.`);
    }
    const { id, ref } = refs[firstFreeAudio];
    const cloned = [...takenAudios.current];
    cloned[firstFreeAudio] = id;
    takenAudios.current = cloned;
    const newElem = {
      props: aud,
      id,
      el: ref,
      audioId
    };
    audios.current?.push(newElem);
    rerenderAudios();
    return newElem;
  }, [numberOfAudioTags, refs, rerenderAudios]);
  const unregisterAudio = useCallback8((id) => {
    const cloned = [...takenAudios.current];
    const index = refs.findIndex((r) => r.id === id);
    if (index === -1) {
      throw new TypeError("Error occured in ");
    }
    cloned[index] = false;
    takenAudios.current = cloned;
    audios.current = audios.current?.filter((a) => a.id !== id);
    rerenderAudios();
  }, [refs, rerenderAudios]);
  const updateAudio = useCallback8(({
    aud,
    audioId,
    id
  }) => {
    let changed = false;
    audios.current = audios.current?.map((prevA) => {
      if (prevA.id === id) {
        const isTheSame = compareProps(aud, prevA.props);
        if (isTheSame) {
          return prevA;
        }
        changed = true;
        return {
          ...prevA,
          props: aud,
          audioId
        };
      }
      return prevA;
    });
    if (changed) {
      rerenderAudios();
    }
  }, [rerenderAudios]);
  const playAllAudios = useCallback8(() => {
    refs.forEach((ref) => {
      playAndHandleNotAllowedError(ref.ref, "audio", null);
    });
  }, [refs]);
  const value = useMemo19(() => {
    return {
      registerAudio,
      unregisterAudio,
      updateAudio,
      playAllAudios,
      numberOfAudioTags
    };
  }, [
    numberOfAudioTags,
    playAllAudios,
    registerAudio,
    unregisterAudio,
    updateAudio
  ]);
  const resetAudio = useCallback8(() => {
    takenAudios.current = new Array(numberOfAudioTags).fill(false);
    audios.current = [];
    rerenderAudios();
  }, [numberOfAudioTags, rerenderAudios]);
  useEffect15(() => {
    return () => {
      resetAudio();
    };
  }, [component, resetAudio]);
  return jsxs(SharedAudioContext.Provider, {
    value,
    children: [
      refs.map(({ id, ref }) => {
        return jsx15("audio", {
          ref,
          preload: "metadata",
          src: EMPTY_AUDIO
        }, id);
      }),
      children
    ]
  });
};
var useSharedAudio = (aud, audioId) => {
  const ctx = useContext20(SharedAudioContext);
  const [elem] = useState13(() => {
    if (ctx && ctx.numberOfAudioTags > 0) {
      return ctx.registerAudio(aud, audioId);
    }
    return {
      el: React12.createRef(),
      id: Math.random(),
      props: aud,
      audioId
    };
  });
  const effectToUse = React12.useInsertionEffect ?? React12.useLayoutEffect;
  if (typeof document !== "undefined") {
    effectToUse(() => {
      if (ctx && ctx.numberOfAudioTags > 0) {
        ctx.updateAudio({ id: elem.id, aud, audioId });
      }
    }, [aud, ctx, elem.id, audioId]);
    effectToUse(() => {
      return () => {
        if (ctx && ctx.numberOfAudioTags > 0) {
          ctx.unregisterAudio(elem.id);
        }
      };
    }, [ctx, elem.id]);
  }
  return elem;
};

// src/audio/AudioForPreview.tsx
import {
jsx as jsx16
} from "react/jsx-runtime";
var AudioForDevelopmentForwardRefFunction = (props, ref) => {
  const [initialShouldPreMountAudioElements] = useState14(props.shouldPreMountAudioTags);
  if (props.shouldPreMountAudioTags !== initialShouldPreMountAudioElements) {
    throw new Error("Cannot change the behavior for pre-mounting audio tags dynamically.");
  }
  const {
    volume,
    muted,
    playbackRate,
    shouldPreMountAudioTags,
    src,
    onDuration,
    acceptableTimeShiftInSeconds,
    _remotionInternalNeedsDurationCalculation,
    _remotionInternalNativeLoopPassed,
    _remotionInternalStack,
    _remotionDebugSeeking,
    allowAmplificationDuringRender,
    name,
    pauseWhenBuffering,
    showInTimeline,
    loopVolumeCurveBehavior,
    stack,
    ...nativeProps
  } = props;
  const [mediaVolume] = useMediaVolumeState();
  const [mediaMuted] = useMediaMutedState();
  const volumePropFrame = useFrameForVolumeProp(loopVolumeCurveBehavior ?? "repeat");
  const { hidden } = useContext21(SequenceVisibilityToggleContext);
  if (!src) {
    throw new TypeError("No 'src' was passed to <Audio>.");
  }
  const preloadedSrc = usePreload(src);
  const sequenceContext = useContext21(SequenceContext);
  const [timelineId] = useState14(() => String(Math.random()));
  const isSequenceHidden = hidden[timelineId] ?? false;
  const userPreferredVolume = evaluateVolume({
    frame: volumePropFrame,
    volume,
    mediaVolume,
    allowAmplificationDuringRender: false
  });
  const propsToPass = useMemo20(() => {
    return {
      muted: muted || mediaMuted || isSequenceHidden || userPreferredVolume <= 0,
      src: preloadedSrc,
      loop: _remotionInternalNativeLoopPassed,
      ...nativeProps
    };
  }, [
    _remotionInternalNativeLoopPassed,
    isSequenceHidden,
    mediaMuted,
    muted,
    nativeProps,
    preloadedSrc,
    userPreferredVolume
  ]);
  const id = useMemo20(() => `audio-${random(src ?? "")}-${sequenceContext?.relativeFrom}-${sequenceContext?.cumulatedFrom}-${sequenceContext?.durationInFrames}-muted:${props.muted}-loop:${props.loop}`, [
    src,
    sequenceContext?.relativeFrom,
    sequenceContext?.cumulatedFrom,
    sequenceContext?.durationInFrames,
    props.muted,
    props.loop
  ]);
  const audioRef = useSharedAudio(propsToPass, id).el;
  const actualVolume = useMediaTagVolume(audioRef);
  useSyncVolumeWithMediaTag({
    volumePropFrame,
    actualVolume,
    volume,
    mediaVolume,
    mediaRef: audioRef
  });
  useMediaInTimeline({
    volume,
    mediaVolume,
    mediaRef: audioRef,
    src,
    mediaType: "audio",
    playbackRate: playbackRate ?? 1,
    displayName: name ?? null,
    id: timelineId,
    stack: _remotionInternalStack,
    showInTimeline,
    premountDisplay: null,
    onAutoPlayError: null
  });
  useMediaPlayback({
    mediaRef: audioRef,
    src,
    mediaType: "audio",
    playbackRate: playbackRate ?? 1,
    onlyWarnForMediaSeekingError: false,
    acceptableTimeshift: acceptableTimeShiftInSeconds ?? DEFAULT_ACCEPTABLE_TIMESHIFT,
    isPremounting: Boolean(sequenceContext?.premounting),
    pauseWhenBuffering,
    debugSeeking: _remotionDebugSeeking,
    onAutoPlayError: null
  });
  useImperativeHandle3(ref, () => {
    return audioRef.current;
  }, [audioRef]);
  const currentOnDurationCallback = useRef8();
  currentOnDurationCallback.current = onDuration;
  useEffect16(() => {
    const { current } = audioRef;
    if (!current) {
      return;
    }
    if (current.duration) {
      currentOnDurationCallback.current?.(current.src, current.duration);
      return;
    }
    const onLoadedMetadata = () => {
      currentOnDurationCallback.current?.(current.src, current.duration);
    };
    current.addEventListener("loadedmetadata", onLoadedMetadata);
    return () => {
      current.removeEventListener("loadedmetadata", onLoadedMetadata);
    };
  }, [audioRef, src]);
  if (initialShouldPreMountAudioElements) {
    return null;
  }
  return jsx16("audio", {
    ref: audioRef,
    preload: "metadata",
    ...propsToPass
  });
};
var AudioForPreview = forwardRef3(AudioForDevelopmentForwardRefFunction);

// src/audio/AudioForRendering.tsx
import {
forwardRef as forwardRef4,
useContext as useContext22,
useEffect as useEffect17,
useImperativeHandle as useImperativeHandle4,
useLayoutEffect as useLayoutEffect3,
useMemo as useMemo21,
useRef as useRef9
} from "react";

// src/delay-render.ts
if (typeof window !== "undefined") {
  window.remotion_renderReady = false;
}
var handles = [];
if (typeof window !== "undefined") {
  window.remotion_delayRenderTimeouts = {};
}
var DELAY_RENDER_CALLSTACK_TOKEN = "The delayRender was called:";
var DELAY_RENDER_RETRIES_LEFT = "Retries left: ";
var DELAY_RENDER_RETRY_TOKEN = "- Rendering the frame will be retried.";
var defaultTimeout = 30000;
var delayRender = (label, options) => {
  if (typeof label !== "string" && typeof label !== "undefined") {
    throw new Error("The label parameter of delayRender() must be a string or undefined, got: " + JSON.stringify(label));
  }
  const handle = Math.random();
  handles.push(handle);
  const called = Error().stack?.replace(/^Error/g, "") ?? "";
  if (getRemotionEnvironment().isRendering) {
    const timeoutToUse = (options?.timeoutInMilliseconds ?? (typeof window === "undefined" ? defaultTimeout : window.remotion_puppeteerTimeout ?? defaultTimeout)) - 2000;
    if (typeof window !== "undefined") {
      const retriesLeft = (options?.retries ?? 0) - (window.remotion_attempt - 1);
      window.remotion_delayRenderTimeouts[handle] = {
        label: label ?? null,
        timeout: setTimeout(() => {
          const message = [
            `A delayRender()`,
            label ? `"${label}"` : null,
            `was called but not cleared after ${timeoutToUse}ms. See https://remotion.dev/docs/timeout for help.`,
            retriesLeft > 0 ? DELAY_RENDER_RETRIES_LEFT + retriesLeft : null,
            retriesLeft > 0 ? DELAY_RENDER_RETRY_TOKEN : null,
            DELAY_RENDER_CALLSTACK_TOKEN,
            called
          ].filter(truthy).join(" ");
          cancelRender(Error(message));
        }, timeoutToUse)
      };
    }
  }
  if (typeof window !== "undefined") {
    window.remotion_renderReady = false;
  }
  return handle;
};
var continueRender = (handle) => {
  if (typeof handle === "undefined") {
    throw new TypeError("The continueRender() method must be called with a parameter that is the return value of delayRender(). No value was passed.");
  }
  if (typeof handle !== "number") {
    throw new TypeError("The parameter passed into continueRender() must be the return value of delayRender() which is a number. Got: " + JSON.stringify(handle));
  }
  handles = handles.filter((h) => {
    if (h === handle) {
      if (getRemotionEnvironment().isRendering) {
        clearTimeout(window.remotion_delayRenderTimeouts[handle].timeout);
        delete window.remotion_delayRenderTimeouts[handle];
      }
      return false;
    }
    return true;
  });
  if (handles.length === 0 && typeof window !== "undefined") {
    window.remotion_renderReady = true;
  }
};

// src/audio/AudioForRendering.tsx
import {
jsx as jsx17
} from "react/jsx-runtime";
var AudioForRenderingRefForwardingFunction = (props, ref) => {
  const audioRef = useRef9(null);
  const {
    volume: volumeProp,
    playbackRate,
    allowAmplificationDuringRender,
    onDuration,
    toneFrequency,
    _remotionInternalNeedsDurationCalculation,
    _remotionInternalNativeLoopPassed,
    acceptableTimeShiftInSeconds,
    name,
    onError,
    delayRenderRetries,
    delayRenderTimeoutInMilliseconds,
    loopVolumeCurveBehavior,
    pauseWhenBuffering,
    ...nativeProps
  } = props;
  const absoluteFrame = useTimelinePosition();
  const volumePropFrame = useFrameForVolumeProp(loopVolumeCurveBehavior ?? "repeat");
  const frame = useCurrentFrame();
  const sequenceContext = useContext22(SequenceContext);
  const { registerRenderAsset, unregisterRenderAsset } = useContext22(RenderAssetManager);
  const id = useMemo21(() => `audio-${random(props.src ?? "")}-${sequenceContext?.relativeFrom}-${sequenceContext?.cumulatedFrom}-${sequenceContext?.durationInFrames}`, [
    props.src,
    sequenceContext?.relativeFrom,
    sequenceContext?.cumulatedFrom,
    sequenceContext?.durationInFrames
  ]);
  const volume = evaluateVolume({
    volume: volumeProp,
    frame: volumePropFrame,
    mediaVolume: 1,
    allowAmplificationDuringRender: allowAmplificationDuringRender ?? false
  });
  useImperativeHandle4(ref, () => {
    return audioRef.current;
  }, []);
  useEffect17(() => {
    if (!props.src) {
      throw new Error("No src passed");
    }
    if (!window.remotion_audioEnabled) {
      return;
    }
    if (props.muted) {
      return;
    }
    if (volume <= 0) {
      return;
    }
    registerRenderAsset({
      type: "audio",
      src: getAbsoluteSrc(props.src),
      id,
      frame: absoluteFrame,
      volume,
      mediaFrame: frame,
      playbackRate: props.playbackRate ?? 1,
      allowAmplificationDuringRender: allowAmplificationDuringRender ?? false,
      toneFrequency: toneFrequency ?? null,
      audioStartFrame: Math.max(0, -(sequenceContext?.relativeFrom ?? 0))
    });
    return () => unregisterRenderAsset(id);
  }, [
    props.muted,
    props.src,
    registerRenderAsset,
    absoluteFrame,
    id,
    unregisterRenderAsset,
    volume,
    volumePropFrame,
    frame,
    playbackRate,
    props.playbackRate,
    allowAmplificationDuringRender,
    toneFrequency,
    sequenceContext?.relativeFrom
  ]);
  const { src } = props;
  const needsToRenderAudioTag = ref || _remotionInternalNeedsDurationCalculation;
  useLayoutEffect3(() => {
    if (window.process?.env?.NODE_ENV === "test") {
      return;
    }
    if (!needsToRenderAudioTag) {
      return;
    }
    const newHandle = delayRender("Loading <Audio> duration with src=" + src, {
      retries: delayRenderRetries ?? undefined,
      timeoutInMilliseconds: delayRenderTimeoutInMilliseconds ?? undefined
    });
    const { current } = audioRef;
    const didLoad = () => {
      if (current?.duration) {
        onDuration(current.src, current.duration);
      }
      continueRender(newHandle);
    };
    if (current?.duration) {
      onDuration(current.src, current.duration);
      continueRender(newHandle);
    } else {
      current?.addEventListener("loadedmetadata", didLoad, { once: true });
    }
    return () => {
      current?.removeEventListener("loadedmetadata", didLoad);
      continueRender(newHandle);
    };
  }, [
    src,
    onDuration,
    needsToRenderAudioTag,
    delayRenderRetries,
    delayRenderTimeoutInMilliseconds
  ]);
  if (!needsToRenderAudioTag) {
    return null;
  }
  return jsx17("audio", {
    ref: audioRef,
    ...nativeProps
  });
};
var AudioForRendering = forwardRef4(AudioForRenderingRefForwardingFunction);

// src/audio/Audio.tsx
import {
jsx as jsx18
} from "react/jsx-runtime";
var AudioRefForwardingFunction = (props, ref) => {
  const audioContext = useContext23(SharedAudioContext);
  const {
    startFrom,
    endAt,
    name,
    stack,
    pauseWhenBuffering,
    showInTimeline,
    _remotionDebugSeeking,
    ...otherProps
  } = props;
  const { loop: loop3, ...propsOtherThanLoop } = props;
  const { fps } = useVideoConfig();
  const environment = getRemotionEnvironment();
  const { durations, setDurations } = useContext23(DurationsContext);
  if (typeof props.src !== "string") {
    throw new TypeError(`The \`<Audio>\` tag requires a string for \`src\`, but got ${JSON.stringify(props.src)} instead.`);
  }
  const preloadedSrc = usePreload(props.src);
  const onError = useCallback9((e) => {
    console.log(e.currentTarget.error);
    const errMessage = `Could not play audio with src ${preloadedSrc}: ${e.currentTarget.error}. See https://remotion.dev/docs/media-playback-error for help.`;
    if (loop3) {
      cancelRender(new Error(errMessage));
    } else {
      console.warn(errMessage);
    }
  }, [loop3, preloadedSrc]);
  const onDuration = useCallback9((src, durationInSeconds) => {
    setDurations({ type: "got-duration", durationInSeconds, src });
  }, [setDurations]);
  const durationFetched = durations[getAbsoluteSrc(preloadedSrc)] ?? durations[getAbsoluteSrc(props.src)];
  if (loop3 && durationFetched !== undefined) {
    const duration = durationFetched * fps;
    return jsx18(Loop, {
      layout: "none",
      durationInFrames: calculateLoopDuration({
        endAt,
        mediaDuration: duration,
        playbackRate: props.playbackRate ?? 1,
        startFrom
      }),
      children: jsx18(Audio, {
        ...propsOtherThanLoop,
        ref,
        _remotionInternalNativeLoopPassed: true
      })
    });
  }
  if (typeof startFrom !== "undefined" || typeof endAt !== "undefined") {
    validateStartFromProps(startFrom, endAt);
    const startFromFrameNo = startFrom ?? 0;
    const endAtFrameNo = endAt ?? Infinity;
    return jsx18(Sequence, {
      layout: "none",
      from: 0 - startFromFrameNo,
      showInTimeline: false,
      durationInFrames: endAtFrameNo,
      name,
      children: jsx18(Audio, {
        _remotionInternalNeedsDurationCalculation: Boolean(loop3),
        pauseWhenBuffering: pauseWhenBuffering ?? false,
        ...otherProps,
        ref
      })
    });
  }
  validateMediaProps(props, "Audio");
  if (environment.isRendering) {
    return jsx18(AudioForRendering, {
      onDuration,
      ...props,
      ref,
      onError,
      _remotionInternalNeedsDurationCalculation: Boolean(loop3)
    });
  }
  return jsx18(AudioForPreview, {
    _remotionInternalNativeLoopPassed: props._remotionInternalNativeLoopPassed ?? false,
    _remotionDebugSeeking: _remotionDebugSeeking ?? false,
    _remotionInternalStack: stack ?? null,
    shouldPreMountAudioTags: audioContext !== null && audioContext.numberOfAudioTags > 0,
    ...props,
    ref,
    onError,
    onDuration,
    pauseWhenBuffering: pauseWhenBuffering ?? false,
    _remotionInternalNeedsDurationCalculation: Boolean(loop3),
    showInTimeline: showInTimeline ?? true
  });
};
var Audio = forwardRef5(AudioRefForwardingFunction);
addSequenceStackTraces(Audio);
// src/Composition.tsx
import {Suspense, useContext as useContext25, useEffect as useEffect19, useMemo as useMemo24} from "react";
import {createPortal} from "react-dom";

// src/Folder.tsx
import {createContext as createContext17, useContext as useContext24, useEffect as useEffect18, useMemo as useMemo22} from "react";

// src/validation/validate-folder-name.ts
var getRegex = () => /^([a-zA-Z0-9-\u4E00-\u9FFF])+$/g;
var isFolderNameValid = (name) => name.match(getRegex());
var validateFolderName = (name) => {
  if (name === undefined || name === null) {
    throw new TypeError("You must pass a name to a <Folder />.");
  }
  if (typeof name !== "string") {
    throw new TypeError(`The "name" you pass into <Folder /> must be a string. Got: ${typeof name}`);
  }
  if (!isFolderNameValid(name)) {
    throw new Error(`Folder name can only contain a-z, A-Z, 0-9 and -. You passed ${name}`);
  }
};
var invalidFolderNameErrorMessage = `Folder name must match ${String(getRegex())}`;

// src/Folder.tsx
import {
jsx as jsx19
} from "react/jsx-runtime";
var FolderContext = createContext17({
  folderName: null,
  parentName: null
});
var Folder = ({
  name,
  children
}) => {
  const parent = useContext24(FolderContext);
  const { registerFolder, unregisterFolder } = useContext24(CompositionManager);
  validateFolderName(name);
  const parentNameArr = [parent.parentName, parent.folderName].filter(truthy);
  const parentName = parentNameArr.length === 0 ? null : parentNameArr.join("/");
  const value = useMemo22(() => {
    return {
      folderName: name,
      parentName
    };
  }, [name, parentName]);
  useEffect18(() => {
    registerFolder(name, parentName);
    return () => {
      unregisterFolder(name, parentName);
    };
  }, [name, parent.folderName, parentName, registerFolder, unregisterFolder]);
  return jsx19(FolderContext.Provider, {
    value,
    children
  });
};

// src/loading-indicator.tsx
import {
jsx as jsx20,
jsxs as jsxs2
} from "react/jsx-runtime";
var rotate = {
  transform: `rotate(90deg)`
};
var ICON_SIZE = 40;
var label = {
  color: "white",
  fontSize: 14,
  fontFamily: "sans-serif"
};
var container = {
  justifyContent: "center",
  alignItems: "center"
};
var Loading = () => {
  return jsxs2(AbsoluteFill, {
    style: container,
    id: "remotion-comp-loading",
    children: [
      jsx20("style", {
        type: "text/css",
        children: `
				@keyframes anim {
					from {
						opacity: 0
					}
					to {
						opacity: 1
					}
				}
				#remotion-comp-loading {
					animation: anim 2s;
					animation-fill-mode: forwards;
				}
			`
      }),
      jsx20("svg", {
        width: ICON_SIZE,
        height: ICON_SIZE,
        viewBox: "-100 -100 400 400",
        style: rotate,
        children: jsx20("path", {
          fill: "#555",
          stroke: "#555",
          strokeWidth: "100",
          strokeLinejoin: "round",
          d: "M 2 172 a 196 100 0 0 0 195 5 A 196 240 0 0 0 100 2.259 A 196 240 0 0 0 2 172 z"
        })
      }),
      jsxs2("p", {
        style: label,
        children: [
          "Resolving ",
          "<Suspense>",
          "..."
        ]
      })
    ]
  });
};

// src/portal-node.ts
var _portalNode = null;
var portalNode = () => {
  if (!_portalNode) {
    if (typeof document === "undefined") {
      throw new Error("Tried to call an API that only works in the browser from outside the browser");
    }
    _portalNode = document.createElement("div");
    _portalNode.style.position = "absolute";
    _portalNode.style.top = "0px";
    _portalNode.style.left = "0px";
    _portalNode.style.right = "0px";
    _portalNode.style.bottom = "0px";
    _portalNode.style.width = "100%";
    _portalNode.style.height = "100%";
    _portalNode.style.display = "flex";
    _portalNode.style.flexDirection = "column";
    const containerNode = document.createElement("div");
    containerNode.style.position = "fixed";
    containerNode.style.top = -999999 + "px";
    containerNode.appendChild(_portalNode);
    document.body.appendChild(containerNode);
  }
  return _portalNode;
};

// src/use-lazy-component.ts
import React16, {useMemo as useMemo23} from "react";
var useLazyComponent = (compProps) => {
  const lazy = useMemo23(() => {
    if ("lazyComponent" in compProps && typeof compProps.lazyComponent !== "undefined") {
      return React16.lazy(compProps.lazyComponent);
    }
    if ("component" in compProps) {
      if (typeof document === "undefined") {
        return compProps.component;
      }
      return React16.lazy(() => Promise.resolve({ default: compProps.component }));
    }
    throw new Error("You must pass either 'component' or 'lazyComponent'");
  }, [compProps.component, compProps.lazyComponent]);
  return lazy;
};

// src/validation/validate-composition-id.ts
var getRegex2 = () => /^([a-zA-Z0-9-\u4E00-\u9FFF])+$/g;
var isCompositionIdValid = (id) => id.match(getRegex2());
var validateCompositionId = (id) => {
  if (!isCompositionIdValid(id)) {
    throw new Error(`Composition id can only contain a-z, A-Z, 0-9, CJK characters and -. You passed ${id}`);
  }
};
var invalidCompositionErrorMessage = `Composition ID must match ${String(getRegex2())}`;

// src/validation/validate-default-props.ts
var validateDefaultAndInputProps = (defaultProps, name, compositionId) => {
  if (!defaultProps) {
    return;
  }
  if (typeof defaultProps !== "object") {
    throw new Error(`"${name}" must be an object, but you passed a value of type ${typeof defaultProps}`);
  }
  if (Array.isArray(defaultProps)) {
    throw new Error(`"${name}" must be an object, an array was passed ${compositionId ? `for composition "${compositionId}"` : ""}`);
  }
};

// src/Composition.tsx
import {
jsx as jsx21
} from "react/jsx-runtime";
var ClipComposition = ({ children }) => {
  const { clipRegion } = useContext25(NativeLayersContext);
  const style = useMemo24(() => {
    return {
      display: "flex",
      flexDirection: "row",
      opacity: clipRegion === "hide" ? 0 : 1,
      clipPath: clipRegion && clipRegion !== "hide" ? `polygon(${clipRegion.x}px ${clipRegion.y}px, ${clipRegion.x}px ${clipRegion.height + clipRegion.y}px, ${clipRegion.width + clipRegion.x}px ${clipRegion.height + clipRegion.y}px, ${clipRegion.width + clipRegion.x}px ${clipRegion.y}px)` : undefined
    };
  }, [clipRegion]);
  return jsx21(AbsoluteFill, {
    style,
    children
  });
};
var Fallback = () => {
  useEffect19(() => {
    const fallback = delayRender("Waiting for Root component to unsuspend");
    return () => continueRender(fallback);
  }, []);
  return null;
};
var Composition = ({
  width,
  height,
  fps,
  durationInFrames,
  id,
  defaultProps,
  schema,
  ...compProps
}) => {
  const { registerComposition, unregisterComposition } = useContext25(CompositionManager);
  const video = useVideo();
  const lazy = useLazyComponent(compProps);
  const nonce5 = useNonce();
  const isPlayer = useIsPlayer();
  const environment = getRemotionEnvironment();
  const canUseComposition = useContext25(CanUseRemotionHooks);
  if (canUseComposition) {
    if (isPlayer) {
      throw new Error("<Composition> was mounted inside the `component` that was passed to the <Player>. See https://remotion.dev/docs/wrong-composition-mount for help.");
    }
    throw new Error("<Composition> mounted inside another composition. See https://remotion.dev/docs/wrong-composition-mount for help.");
  }
  const { folderName, parentName } = useContext25(FolderContext);
  useEffect19(() => {
    if (!id) {
      throw new Error("No id for composition passed.");
    }
    validateCompositionId(id);
    validateDefaultAndInputProps(defaultProps, "defaultProps", id);
    registerComposition({
      durationInFrames: durationInFrames ?? undefined,
      fps: fps ?? undefined,
      height: height ?? undefined,
      width: width ?? undefined,
      id,
      folderName,
      component: lazy,
      defaultProps: serializeThenDeserializeInStudio(defaultProps ?? {}),
      nonce: nonce5,
      parentFolderName: parentName,
      schema: schema ?? null,
      calculateMetadata: compProps.calculateMetadata ?? null
    });
    return () => {
      unregisterComposition(id);
    };
  }, [
    durationInFrames,
    fps,
    height,
    lazy,
    id,
    folderName,
    defaultProps,
    registerComposition,
    unregisterComposition,
    width,
    nonce5,
    parentName,
    schema,
    compProps.calculateMetadata
  ]);
  const resolved = useResolvedVideoConfig(id);
  if (environment.isStudio && video && video.component === lazy) {
    const Comp = lazy;
    if (resolved === null || resolved.type !== "success") {
      return null;
    }
    return createPortal(jsx21(ClipComposition, {
      children: jsx21(CanUseRemotionHooksProvider, {
        children: jsx21(Suspense, {
          fallback: jsx21(Loading, {}),
          children: jsx21(Comp, {
            ...resolved.result.props ?? {}
          })
        })
      })
    }), portalNode());
  }
  if (environment.isRendering && video && video.component === lazy) {
    const Comp = lazy;
    if (resolved === null || resolved.type !== "success") {
      return null;
    }
    return createPortal(jsx21(CanUseRemotionHooksProvider, {
      children: jsx21(Suspense, {
        fallback: jsx21(Fallback, {}),
        children: jsx21(Comp, {
          ...resolved.result.props ?? {}
        })
      })
    }), portalNode());
  }
  return null;
};
// src/CompositionManager.tsx
import React18, {
useCallback as useCallback10,
useImperativeHandle as useImperativeHandle5,
useMemo as useMemo25,
useRef as useRef10,
useState as useState15
} from "react";
import {
jsx as jsx22
} from "react/jsx-runtime";
var compositionsRef = React18.createRef();
var CompositionManagerProvider = ({ children, numberOfAudioTags }) => {
  const [compositions, setCompositions] = useState15([]);
  const currentcompositionsRef = useRef10(compositions);
  const [folders, setFolders] = useState15([]);
  const [canvasContent, setCanvasContent] = useState15(null);
  const [currentCompositionMetadata, setCurrentCompositionMetadata] = useState15(null);
  const updateCompositions = useCallback10((updateComps) => {
    setCompositions((comps) => {
      const updated = updateComps(comps);
      currentcompositionsRef.current = updated;
      return updated;
    });
  }, []);
  const registerComposition = useCallback10((comp) => {
    updateCompositions((comps) => {
      if (comps.find((c) => c.id === comp.id)) {
        throw new Error(`Multiple composition with id ${comp.id} are registered.`);
      }
      const value = [...comps, comp].slice().sort((a, b) => a.nonce - b.nonce);
      return value;
    });
  }, [updateCompositions]);
  const unregisterComposition = useCallback10((id) => {
    setCompositions((comps) => {
      return comps.filter((c) => c.id !== id);
    });
  }, []);
  const registerFolder = useCallback10((name, parent) => {
    setFolders((prevFolders) => {
      return [
        ...prevFolders,
        {
          name,
          parent
        }
      ];
    });
  }, []);
  const unregisterFolder = useCallback10((name, parent) => {
    setFolders((prevFolders) => {
      return prevFolders.filter((p) => !(p.name === name && p.parent === parent));
    });
  }, []);
  useImperativeHandle5(compositionsRef, () => {
    return {
      getCompositions: () => currentcompositionsRef.current
    };
  }, []);
  const composition = compositions.find((c) => canvasContent?.type === "composition" ? c.id === canvasContent.compositionId : null);
  const updateCompositionDefaultProps = useCallback10((id, newDefaultProps) => {
    setCompositions((comps) => {
      const updated = comps.map((c) => {
        if (c.id === id) {
          return {
            ...c,
            defaultProps: newDefaultProps
          };
        }
        return c;
      });
      return updated;
    });
  }, []);
  const contextValue = useMemo25(() => {
    return {
      compositions,
      registerComposition,
      unregisterComposition,
      folders,
      registerFolder,
      unregisterFolder,
      currentCompositionMetadata,
      setCurrentCompositionMetadata,
      canvasContent,
      setCanvasContent,
      updateCompositionDefaultProps
    };
  }, [
    compositions,
    registerComposition,
    unregisterComposition,
    folders,
    registerFolder,
    unregisterFolder,
    currentCompositionMetadata,
    canvasContent,
    updateCompositionDefaultProps
  ]);
  return jsx22(CompositionManager.Provider, {
    value: contextValue,
    children: jsx22(SequenceManagerProvider, {
      children: jsx22(RenderAssetManagerProvider, {
        children: jsx22(ResolveCompositionConfig, {
          children: jsx22(SharedAudioContextProvider, {
            numberOfAudioTags,
            component: composition?.component ?? null,
            children
          })
        })
      })
    })
  });
};
// src/bezier.ts
var a = function(aA1, aA2) {
  return 1 - 3 * aA2 + 3 * aA1;
};
var b = function(aA1, aA2) {
  return 3 * aA2 - 6 * aA1;
};
var c = function(aA1) {
  return 3 * aA1;
};
var calcBezier = function(aT, aA1, aA2) {
  return ((a(aA1, aA2) * aT + b(aA1, aA2)) * aT + c(aA1)) * aT;
};
var getSlope = function(aT, aA1, aA2) {
  return 3 * a(aA1, aA2) * aT * aT + 2 * b(aA1, aA2) * aT + c(aA1);
};
var binarySubdivide = function({
  aX,
  _aA,
  _aB,
  mX1,
  mX2
}) {
  let currentX;
  let currentT;
  let i = 0;
  let aA = _aA;
  let aB = _aB;
  do {
    currentT = aA + (aB - aA) / 2;
    currentX = calcBezier(currentT, mX1, mX2) - aX;
    if (currentX > 0) {
      aB = currentT;
    } else {
      aA = currentT;
    }
  } while (Math.abs(currentX) > SUBDIVISION_PRECISION && ++i < SUBDIVISION_MAX_ITERATIONS);
  return currentT;
};
var newtonRaphsonIterate = function(aX, _aGuessT, mX1, mX2) {
  let aGuessT = _aGuessT;
  for (let i = 0;i < NEWTON_ITERATIONS; ++i) {
    const currentSlope = getSlope(aGuessT, mX1, mX2);
    if (currentSlope === 0) {
      return aGuessT;
    }
    const currentX = calcBezier(aGuessT, mX1, mX2) - aX;
    aGuessT -= currentX / currentSlope;
  }
  return aGuessT;
};
function bezier(mX1, mY1, mX2, mY2) {
  if (!(mX1 >= 0 && mX1 <= 1 && mX2 >= 0 && mX2 <= 1)) {
    throw new Error("bezier x values must be in [0, 1] range");
  }
  const sampleValues = float32ArraySupported ? new Float32Array(kSplineTableSize) : new Array(kSplineTableSize);
  if (mX1 !== mY1 || mX2 !== mY2) {
    for (let i = 0;i < kSplineTableSize; ++i) {
      sampleValues[i] = calcBezier(i * kSampleStepSize, mX1, mX2);
    }
  }
  function getTForX(aX) {
    let intervalStart = 0;
    let currentSample = 1;
    const lastSample = kSplineTableSize - 1;
    for (;currentSample !== lastSample && sampleValues[currentSample] <= aX; ++currentSample) {
      intervalStart += kSampleStepSize;
    }
    --currentSample;
    const dist = (aX - sampleValues[currentSample]) / (sampleValues[currentSample + 1] - sampleValues[currentSample]);
    const guessForT = intervalStart + dist * kSampleStepSize;
    const initialSlope = getSlope(guessForT, mX1, mX2);
    if (initialSlope >= NEWTON_MIN_SLOPE) {
      return newtonRaphsonIterate(aX, guessForT, mX1, mX2);
    }
    if (initialSlope === 0) {
      return guessForT;
    }
    return binarySubdivide({
      aX,
      _aA: intervalStart,
      _aB: intervalStart + kSampleStepSize,
      mX1,
      mX2
    });
  }
  return function(x) {
    if (mX1 === mY1 && mX2 === mY2) {
      return x;
    }
    if (x === 0) {
      return 0;
    }
    if (x === 1) {
      return 1;
    }
    return calcBezier(getTForX(x), mY1, mY2);
  };
}
var NEWTON_ITERATIONS = 4;
var NEWTON_MIN_SLOPE = 0.001;
var SUBDIVISION_PRECISION = 0.0000001;
var SUBDIVISION_MAX_ITERATIONS = 10;
var kSplineTableSize = 11;
var kSampleStepSize = 1 / (kSplineTableSize - 1);
var float32ArraySupported = typeof Float32Array === "function";

// src/easing.ts
class Easing {
  static step0(n) {
    return n > 0 ? 1 : 0;
  }
  static step1(n) {
    return n >= 1 ? 1 : 0;
  }
  static linear(t) {
    return t;
  }
  static ease(t) {
    return Easing.bezier(0.42, 0, 1, 1)(t);
  }
  static quad(t) {
    return t * t;
  }
  static cubic(t) {
    return t * t * t;
  }
  static poly(n) {
    return (t) => t ** n;
  }
  static sin(t) {
    return 1 - Math.cos(t * Math.PI / 2);
  }
  static circle(t) {
    return 1 - Math.sqrt(1 - t * t);
  }
  static exp(t) {
    return 2 ** (10 * (t - 1));
  }
  static elastic(bounciness = 1) {
    const p = bounciness * Math.PI;
    return (t) => 1 - Math.cos(t * Math.PI / 2) ** 3 * Math.cos(t * p);
  }
  static back(s = 1.70158) {
    return (t) => t * t * ((s + 1) * t - s);
  }
  static bounce(t) {
    if (t < 1 / 2.75) {
      return 7.5625 * t * t;
    }
    if (t < 2 / 2.75) {
      const t2_ = t - 1.5 / 2.75;
      return 7.5625 * t2_ * t2_ + 0.75;
    }
    if (t < 2.5 / 2.75) {
      const t2_ = t - 2.25 / 2.75;
      return 7.5625 * t2_ * t2_ + 0.9375;
    }
    const t2 = t - 2.625 / 2.75;
    return 7.5625 * t2 * t2 + 0.984375;
  }
  static bezier(x1, y1, x2, y2) {
    return bezier(x1, y1, x2, y2);
  }
  static in(easing) {
    return easing;
  }
  static out(easing) {
    return (t) => 1 - easing(1 - t);
  }
  static inOut(easing) {
    return (t) => {
      if (t < 0.5) {
        return easing(t * 2) / 2;
      }
      return 1 - easing((1 - t) * 2) / 2;
    };
  }
}
// src/v5-flag.ts
var ENABLE_V5_BREAKING_CHANGES = false;

// src/get-static-files.ts
var warnedServer = false;
var warnedPlayer = false;
var warnServerOnce = () => {
  if (warnedServer) {
    return;
  }
  warnedServer = true;
  console.warn("Called getStaticFiles() on the server. The API is only available in the browser. An empty array was returned.");
};
var warnPlayerOnce = () => {
  if (warnedPlayer) {
    return;
  }
  warnedPlayer = true;
  console.warn("Called getStaticFiles() while using the Remotion Player. The API is only available while using the Remotion Studio. An empty array was returned.");
};
var getStaticFiles = () => {
  if (ENABLE_V5_BREAKING_CHANGES) {
    throw new Error("getStaticFiles() has moved into the `@remotion/studio` package. Update your imports.");
  }
  if (typeof document === "undefined") {
    warnServerOnce();
    return [];
  }
  if (window.remotion_isPlayer) {
    warnPlayerOnce();
    return [];
  }
  return window.remotion_staticFiles;
};
// src/IFrame.tsx
import {forwardRef as forwardRef6, useCallback as useCallback11, useState as useState16} from "react";
import {
jsx as jsx23
} from "react/jsx-runtime";
var IFrameRefForwarding = ({
  onLoad,
  onError,
  delayRenderRetries,
  delayRenderTimeoutInMilliseconds,
  ...props2
}, ref) => {
  const [handle] = useState16(() => delayRender(`Loading <IFrame> with source ${props2.src}`, {
    retries: delayRenderRetries ?? undefined,
    timeoutInMilliseconds: delayRenderTimeoutInMilliseconds ?? undefined
  }));
  const didLoad = useCallback11((e) => {
    continueRender(handle);
    onLoad?.(e);
  }, [handle, onLoad]);
  const didGetError = useCallback11((e) => {
    continueRender(handle);
    if (onError) {
      onError(e);
    } else {
      console.error("Error loading iframe:", e, "Handle the event using the onError() prop to make this message disappear.");
    }
  }, [handle, onError]);
  return jsx23("iframe", {
    ...props2,
    ref,
    onError: didGetError,
    onLoad: didLoad
  });
};
var IFrame = forwardRef6(IFrameRefForwarding);
// src/Img.tsx
import {
forwardRef as forwardRef7,
useCallback as useCallback12,
useContext as useContext26,
useImperativeHandle as useImperativeHandle6,
useLayoutEffect as useLayoutEffect4,
useRef as useRef11
} from "react";
var exponentialBackoff = function(errorCount) {
  return 1000 * 2 ** (errorCount - 1);
};
import {
jsx as jsx24
} from "react/jsx-runtime";
var ImgRefForwarding = ({
  onError,
  maxRetries = 2,
  src,
  pauseWhenLoading,
  delayRenderRetries,
  delayRenderTimeoutInMilliseconds,
  onImageFrame,
  ...props2
}, ref) => {
  const imageRef = useRef11(null);
  const errors = useRef11({});
  const { delayPlayback } = useBufferState();
  const sequenceContext = useContext26(SequenceContext);
  if (!src) {
    throw new Error('No "src" prop was passed to <Img>.');
  }
  useImperativeHandle6(ref, () => {
    return imageRef.current;
  }, []);
  const actualSrc = usePreload(src);
  const retryIn = useCallback12((timeout) => {
    if (!imageRef.current) {
      return;
    }
    const currentSrc = imageRef.current.src;
    setTimeout(() => {
      if (!imageRef.current) {
        return;
      }
      const newSrc = imageRef.current?.src;
      if (newSrc !== currentSrc) {
        return;
      }
      imageRef.current.removeAttribute("src");
      imageRef.current.setAttribute("src", newSrc);
    }, timeout);
  }, []);
  const didGetError = useCallback12((e) => {
    if (!errors.current) {
      return;
    }
    errors.current[imageRef.current?.src] = (errors.current[imageRef.current?.src] ?? 0) + 1;
    if (onError && (errors.current[imageRef.current?.src] ?? 0) > maxRetries) {
      onError(e);
      return;
    }
    if ((errors.current[imageRef.current?.src] ?? 0) <= maxRetries) {
      const backoff = exponentialBackoff(errors.current[imageRef.current?.src] ?? 0);
      console.warn(`Could not load image with source ${imageRef.current?.src}, retrying again in ${backoff}ms`);
      retryIn(backoff);
      return;
    }
    cancelRender("Error loading image with src: " + imageRef.current?.src);
  }, [maxRetries, onError, retryIn]);
  if (typeof window !== "undefined") {
    const isPremounting = Boolean(sequenceContext?.premounting);
    useLayoutEffect4(() => {
      if (window.process?.env?.NODE_ENV === "test") {
        return;
      }
      const newHandle = delayRender("Loading <Img> with src=" + actualSrc, {
        retries: delayRenderRetries ?? undefined,
        timeoutInMilliseconds: delayRenderTimeoutInMilliseconds ?? undefined
      });
      const unblock = pauseWhenLoading && !isPremounting ? delayPlayback().unblock : () => {
        return;
      };
      const { current } = imageRef;
      const onComplete = () => {
        if ((errors.current[imageRef.current?.src] ?? 0) > 0) {
          delete errors.current[imageRef.current?.src];
          console.info(`Retry successful - ${imageRef.current?.src} is now loaded`);
        }
        if (current) {
          onImageFrame?.(current);
        }
        unblock();
        continueRender(newHandle);
      };
      const didLoad = () => {
        onComplete();
      };
      if (current?.complete) {
        onComplete();
      } else {
        current?.addEventListener("load", didLoad, { once: true });
      }
      return () => {
        current?.removeEventListener("load", didLoad);
        unblock();
        continueRender(newHandle);
      };
    }, [
      actualSrc,
      delayPlayback,
      delayRenderRetries,
      delayRenderTimeoutInMilliseconds,
      pauseWhenLoading,
      isPremounting,
      onImageFrame
    ]);
  }
  return jsx24("img", {
    ...props2,
    ref: imageRef,
    src: actualSrc,
    onError: didGetError
  });
};
var Img = forwardRef7(ImgRefForwarding);
// src/default-css.ts
var exports_default_css = {};
__export(exports_default_css, {
  makeDefaultPreviewCSS: () => makeDefaultPreviewCSS,
  injectCSS: () => injectCSS,
  OFFTHREAD_VIDEO_CLASS_NAME: () => OFFTHREAD_VIDEO_CLASS_NAME
});
var injected = {};
var injectCSS = (css) => {
  if (typeof document === "undefined") {
    return;
  }
  if (injected[css]) {
    return;
  }
  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");
  style.appendChild(document.createTextNode(css));
  head.prepend(style);
  injected[css] = true;
};
var OFFTHREAD_VIDEO_CLASS_NAME = "__remotion_offthreadvideo";
var makeDefaultPreviewCSS = (scope, backgroundColor) => {
  if (!scope) {
    return `
    * {
      box-sizing: border-box;
    }
    body {
      margin: 0;
	    background-color: ${backgroundColor};
    }
    .${OFFTHREAD_VIDEO_CLASS_NAME} {
      object-fit: contain;
    }
    `;
  }
  return `
    ${scope} * {
      box-sizing: border-box;
    }
    ${scope} *:-webkit-full-screen {
      width: 100%;
      height: 100%;
    }
    ${scope} .${OFFTHREAD_VIDEO_CLASS_NAME} {
      object-fit: contain;
    }
  `;
};

// src/get-preview-dom-element.ts
var REMOTION_STUDIO_CONTAINER_ELEMENT = "__remotion-studio-container";
var getPreviewDomElement = () => {
  return document.getElementById(REMOTION_STUDIO_CONTAINER_ELEMENT);
};

// src/register-root.ts
var Root = null;
var listeners = [];
var registerRoot = (comp) => {
  if (!comp) {
    throw new Error(`You must pass a React component to registerRoot(), but ${JSON.stringify(comp)} was passed.`);
  }
  if (Root) {
    throw new Error("registerRoot() was called more than once.");
  }
  Root = comp;
  listeners.forEach((l) => {
    l(comp);
  });
};
var getRoot = () => {
  return Root;
};
var waitForRoot = (fn) => {
  if (Root) {
    fn(Root);
    return () => {
      return;
    };
  }
  listeners.push(fn);
  return () => {
    listeners = listeners.filter((l) => l !== fn);
  };
};

// src/RemotionRoot.tsx
import {
useEffect as useEffect20,
useLayoutEffect as useLayoutEffect5,
useMemo as useMemo26,
useRef as useRef12,
useState as useState17
} from "react";
import {
jsx as jsx25
} from "react/jsx-runtime";
var RemotionRoot = ({ children, numberOfAudioTags }) => {
  const [remotionRootId] = useState17(() => String(random(null)));
  const [frame, setFrame] = useState17(() => getInitialFrameState());
  const [playing, setPlaying] = useState17(false);
  const imperativePlaying = useRef12(false);
  const [fastRefreshes, setFastRefreshes] = useState17(0);
  const [playbackRate, setPlaybackRate] = useState17(1);
  const audioAndVideoTags = useRef12([]);
  if (typeof window !== "undefined") {
    useLayoutEffect5(() => {
      window.remotion_setFrame = (f, composition, attempt) => {
        window.remotion_attempt = attempt;
        const id = delayRender(`Setting the current frame to ${f}`);
        let asyncUpdate = true;
        setFrame((s) => {
          const currentFrame = s[composition] ?? window.remotion_initialFrame;
          if (currentFrame === f) {
            asyncUpdate = false;
            return s;
          }
          return {
            ...s,
            [composition]: f
          };
        });
        if (asyncUpdate) {
          requestAnimationFrame(() => continueRender(id));
        } else {
          continueRender(id);
        }
      };
      window.remotion_isPlayer = false;
    }, []);
  }
  const timelineContextValue = useMemo26(() => {
    return {
      frame,
      playing,
      imperativePlaying,
      rootId: remotionRootId,
      playbackRate,
      setPlaybackRate,
      audioAndVideoTags
    };
  }, [frame, playbackRate, playing, remotionRootId]);
  const setTimelineContextValue = useMemo26(() => {
    return {
      setFrame,
      setPlaying
    };
  }, []);
  const nonceContext = useMemo26(() => {
    let counter = 0;
    return {
      getNonce: () => counter++,
      fastRefreshes
    };
  }, [fastRefreshes]);
  useEffect20(() => {
    if (typeof __webpack_module__ !== "undefined") {
      if (__webpack_module__.hot) {
        __webpack_module__.hot.addStatusHandler((status) => {
          if (status === "idle") {
            setFastRefreshes((i) => i + 1);
          }
        });
      }
    }
  }, []);
  return jsx25(NonceContext.Provider, {
    value: nonceContext,
    children: jsx25(TimelineContext.Provider, {
      value: timelineContextValue,
      children: jsx25(SetTimelineContext.Provider, {
        value: setTimelineContextValue,
        children: jsx25(EditorPropsProvider, {
          children: jsx25(PrefetchProvider, {
            children: jsx25(NativeLayersProvider, {
              children: jsx25(CompositionManagerProvider, {
                numberOfAudioTags,
                children: jsx25(DurationsContextProvider, {
                  children: jsx25(BufferingProvider, {
                    children
                  })
                })
              })
            })
          })
        })
      })
    })
  });
};

// src/setup-env-variables.ts
var getEnvVariables = () => {
  if (getRemotionEnvironment().isRendering) {
    const param = window.remotion_envVariables;
    if (!param) {
      return {};
    }
    return { ...JSON.parse(param), NODE_ENV: "production" };
  }
  if (false) {
  }
  return {
    NODE_ENV: "production"
  };
};
var setupEnvVariables = () => {
  const env = getEnvVariables();
  if (!window.process) {
    window.process = {};
  }
  if (!window.process.env) {
    window.process.env = {};
  }
  Object.keys(env).forEach((key) => {
    window.process.env[key] = env[key];
  });
};

// src/use-current-scale.ts
import React22, {createContext as createContext18} from "react";
var CurrentScaleContext = React22.createContext(null);
var PreviewSizeContext = createContext18({
  setSize: () => {
    return;
  },
  size: { size: "auto", translation: { x: 0, y: 0 } }
});
var calculateScale = ({
  canvasSize,
  compositionHeight,
  compositionWidth,
  previewSize
}) => {
  const heightRatio = canvasSize.height / compositionHeight;
  const widthRatio = canvasSize.width / compositionWidth;
  const ratio = Math.min(heightRatio, widthRatio);
  return previewSize === "auto" ? ratio : Number(previewSize);
};
var useCurrentScale = (options) => {
  const hasContext = React22.useContext(CurrentScaleContext);
  const zoomContext = React22.useContext(PreviewSizeContext);
  const config = useUnsafeVideoConfig();
  if (hasContext === null || config === null || zoomContext === null) {
    if (options?.dontThrowIfOutsideOfRemotion) {
      return 1;
    }
    if (getRemotionEnvironment().isRendering) {
      return 1;
    }
    throw new Error([
      "useCurrentScale() was called outside of a Remotion context.",
      "This hook can only be called in a component that is being rendered by Remotion.",
      "If you want to this hook to return 1 outside of Remotion, pass {dontThrowIfOutsideOfRemotion: true} as an option.",
      "If you think you called this hook in a Remotion component, make sure all versions of Remotion are aligned."
    ].join("\n"));
  }
  if (hasContext.type === "scale") {
    return hasContext.scale;
  }
  return calculateScale({
    canvasSize: hasContext.canvasSize,
    compositionHeight: config.height,
    compositionWidth: config.width,
    previewSize: zoomContext.size.size
  });
};

// src/watch-static-file.ts
var WATCH_REMOTION_STATIC_FILES = "remotion_staticFilesChanged";
var watchStaticFile = (fileName, callback) => {
  if (ENABLE_V5_BREAKING_CHANGES) {
    throw new Error("watchStaticFile() has moved into the `@remotion/studio` package. Update your imports.");
  }
  if (!getRemotionEnvironment().isStudio) {
    console.warn("The API is only available while using the Remotion Studio.");
    return { cancel: () => {
      return;
    } };
  }
  const withoutStaticBase = fileName.startsWith(window.remotion_staticBase) ? fileName.replace(window.remotion_staticBase, "") : fileName;
  const withoutLeadingSlash = withoutStaticBase.startsWith("/") ? withoutStaticBase.slice(1) : withoutStaticBase;
  let prevFileData = window.remotion_staticFiles.find((file) => file.name === withoutLeadingSlash);
  const checkFile = (event) => {
    const staticFiles = event.detail.files;
    const newFileData = staticFiles.find((file) => file.name === withoutLeadingSlash);
    if (!newFileData) {
      if (prevFileData !== undefined) {
        callback(null);
      }
      prevFileData = undefined;
      return;
    }
    if (prevFileData === undefined || prevFileData.lastModified !== newFileData.lastModified) {
      callback(newFileData);
      prevFileData = newFileData;
    }
  };
  window.addEventListener(WATCH_REMOTION_STATIC_FILES, checkFile);
  const cancel = () => {
    return window.removeEventListener(WATCH_REMOTION_STATIC_FILES, checkFile);
  };
  return { cancel };
};

// src/wrap-remotion-context.tsx
import React23, {useMemo as useMemo27} from "react";
function useRemotionContexts() {
  const compositionManagerCtx = React23.useContext(CompositionManager);
  const timelineContext = React23.useContext(TimelineContext);
  const setTimelineContext = React23.useContext(SetTimelineContext);
  const sequenceContext = React23.useContext(SequenceContext);
  const nonceContext = React23.useContext(NonceContext);
  const canUseRemotionHooksContext = React23.useContext(CanUseRemotionHooks);
  const nativeLayersContext = React23.useContext(NativeLayersContext);
  const preloadContext = React23.useContext(PreloadContext);
  const resolveCompositionContext = React23.useContext(ResolveCompositionContext);
  const renderAssetManagerContext = React23.useContext(RenderAssetManager);
  const sequenceManagerContext = React23.useContext(SequenceManager);
  const bufferManagerContext = React23.useContext(BufferingContextReact);
  return useMemo27(() => ({
    compositionManagerCtx,
    timelineContext,
    setTimelineContext,
    sequenceContext,
    nonceContext,
    canUseRemotionHooksContext,
    nativeLayersContext,
    preloadContext,
    resolveCompositionContext,
    renderAssetManagerContext,
    sequenceManagerContext,
    bufferManagerContext
  }), [
    compositionManagerCtx,
    nonceContext,
    sequenceContext,
    setTimelineContext,
    timelineContext,
    canUseRemotionHooksContext,
    nativeLayersContext,
    preloadContext,
    resolveCompositionContext,
    renderAssetManagerContext,
    sequenceManagerContext,
    bufferManagerContext
  ]);
}
import {
jsx as jsx26
} from "react/jsx-runtime";
var RemotionContextProvider = (props2) => {
  const { children, contexts } = props2;
  return jsx26(CanUseRemotionHooks.Provider, {
    value: contexts.canUseRemotionHooksContext,
    children: jsx26(NonceContext.Provider, {
      value: contexts.nonceContext,
      children: jsx26(NativeLayersContext.Provider, {
        value: contexts.nativeLayersContext,
        children: jsx26(PreloadContext.Provider, {
          value: contexts.preloadContext,
          children: jsx26(CompositionManager.Provider, {
            value: contexts.compositionManagerCtx,
            children: jsx26(SequenceManager.Provider, {
              value: contexts.sequenceManagerContext,
              children: jsx26(RenderAssetManager.Provider, {
                value: contexts.renderAssetManagerContext,
                children: jsx26(ResolveCompositionContext.Provider, {
                  value: contexts.resolveCompositionContext,
                  children: jsx26(TimelineContext.Provider, {
                    value: contexts.timelineContext,
                    children: jsx26(SetTimelineContext.Provider, {
                      value: contexts.setTimelineContext,
                      children: jsx26(SequenceContext.Provider, {
                        value: contexts.sequenceContext,
                        children: jsx26(BufferingContextReact.Provider, {
                          value: contexts.bufferManagerContext,
                          children
                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  });
};

// src/internals.ts
var Internals = {
  useUnsafeVideoConfig,
  Timeline: exports_timeline_position_state,
  CompositionManager,
  SequenceManager,
  SequenceVisibilityToggleContext,
  RemotionRoot,
  useVideo,
  getRoot,
  useMediaVolumeState,
  useMediaMutedState,
  useLazyComponent,
  truthy,
  SequenceContext,
  useRemotionContexts,
  RemotionContextProvider,
  CSSUtils: exports_default_css,
  setupEnvVariables,
  MediaVolumeContext,
  SetMediaVolumeContext,
  getRemotionEnvironment,
  SharedAudioContext,
  SharedAudioContextProvider,
  invalidCompositionErrorMessage,
  isCompositionIdValid,
  getPreviewDomElement,
  compositionsRef,
  portalNode,
  waitForRoot,
  CanUseRemotionHooksProvider,
  CanUseRemotionHooks,
  PrefetchProvider,
  DurationsContextProvider,
  IsPlayerContextProvider,
  useIsPlayer,
  EditorPropsProvider,
  EditorPropsContext,
  usePreload,
  NonceContext,
  resolveVideoConfig,
  useResolvedVideoConfig,
  resolveCompositionsRef,
  ResolveCompositionConfig,
  REMOTION_STUDIO_CONTAINER_ELEMENT,
  RenderAssetManager,
  persistCurrentFrame,
  useTimelineSetFrame,
  NativeLayersProvider,
  ClipComposition,
  isIosSafari,
  WATCH_REMOTION_STATIC_FILES,
  addSequenceStackTraces,
  useMediaStartsAt,
  BufferingProvider,
  BufferingContextReact,
  enableSequenceStackTraces,
  CurrentScaleContext,
  PreviewSizeContext,
  calculateScale,
  editorPropsProviderRef,
  PROPS_UPDATED_EXTERNALLY,
  validateRenderAsset
};
// src/interpolate-colors.ts
var call = function(...args) {
  return "\\(\\s*(" + args.join(")\\s*,\\s*(") + ")\\s*\\)";
};
var getMatchers = function() {
  const cachedMatchers = {
    rgb: undefined,
    rgba: undefined,
    hsl: undefined,
    hsla: undefined,
    hex3: undefined,
    hex4: undefined,
    hex5: undefined,
    hex6: undefined,
    hex8: undefined
  };
  if (cachedMatchers.rgb === undefined) {
    cachedMatchers.rgb = new RegExp("rgb" + call(NUMBER, NUMBER, NUMBER));
    cachedMatchers.rgba = new RegExp("rgba" + call(NUMBER, NUMBER, NUMBER, NUMBER));
    cachedMatchers.hsl = new RegExp("hsl" + call(NUMBER, PERCENTAGE, PERCENTAGE));
    cachedMatchers.hsla = new RegExp("hsla" + call(NUMBER, PERCENTAGE, PERCENTAGE, NUMBER));
    cachedMatchers.hex3 = /^#([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/;
    cachedMatchers.hex4 = /^#([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/;
    cachedMatchers.hex6 = /^#([0-9a-fA-F]{6})$/;
    cachedMatchers.hex8 = /^#([0-9a-fA-F]{8})$/;
  }
  return cachedMatchers;
};
var hue2rgb = function(p, q, t) {
  if (t < 0) {
    t += 1;
  }
  if (t > 1) {
    t -= 1;
  }
  if (t < 1 / 6) {
    return p + (q - p) * 6 * t;
  }
  if (t < 1 / 2) {
    return q;
  }
  if (t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6;
  }
  return p;
};
var hslToRgb = function(h, s, l) {
  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;
  const r = hue2rgb(p, q, h + 1 / 3);
  const g = hue2rgb(p, q, h);
  const b2 = hue2rgb(p, q, h - 1 / 3);
  return Math.round(r * 255) << 24 | Math.round(g * 255) << 16 | Math.round(b2 * 255) << 8;
};
var parse255 = function(str) {
  const int = Number.parseInt(str, 10);
  if (int < 0) {
    return 0;
  }
  if (int > 255) {
    return 255;
  }
  return int;
};
var parse360 = function(str) {
  const int = Number.parseFloat(str);
  return (int % 360 + 360) % 360 / 360;
};
var parse1 = function(str) {
  const num = Number.parseFloat(str);
  if (num < 0) {
    return 0;
  }
  if (num > 1) {
    return 255;
  }
  return Math.round(num * 255);
};
var parsePercentage = function(str) {
  const int = Number.parseFloat(str);
  if (int < 0) {
    return 0;
  }
  if (int > 100) {
    return 1;
  }
  return int / 100;
};
var normalizeColor = function(color) {
  const matchers = getMatchers();
  let match;
  if (matchers.hex6) {
    if (match = matchers.hex6.exec(color)) {
      return Number.parseInt(match[1] + "ff", 16) >>> 0;
    }
  }
  if (colorNames[color] !== undefined) {
    return colorNames[color];
  }
  if (matchers.rgb) {
    if (match = matchers.rgb.exec(color)) {
      return (parse255(match[1]) << 24 | parse255(match[2]) << 16 | parse255(match[3]) << 8 | 255) >>> 0;
    }
  }
  if (matchers.rgba) {
    if (match = matchers.rgba.exec(color)) {
      return (parse255(match[1]) << 24 | parse255(match[2]) << 16 | parse255(match[3]) << 8 | parse1(match[4])) >>> 0;
    }
  }
  if (matchers.hex3) {
    if (match = matchers.hex3.exec(color)) {
      return Number.parseInt(match[1] + match[1] + match[2] + match[2] + match[3] + match[3] + "ff", 16) >>> 0;
    }
  }
  if (matchers.hex8) {
    if (match = matchers.hex8.exec(color)) {
      return Number.parseInt(match[1], 16) >>> 0;
    }
  }
  if (matchers.hex4) {
    if (match = matchers.hex4.exec(color)) {
      return Number.parseInt(match[1] + match[1] + match[2] + match[2] + match[3] + match[3] + match[4] + match[4], 16) >>> 0;
    }
  }
  if (matchers.hsl) {
    if (match = matchers.hsl.exec(color)) {
      return (hslToRgb(parse360(match[1]), parsePercentage(match[2]), parsePercentage(match[3])) | 255) >>> 0;
    }
  }
  if (matchers.hsla) {
    if (match = matchers.hsla.exec(color)) {
      return (hslToRgb(parse360(match[1]), parsePercentage(match[2]), parsePercentage(match[3])) | parse1(match[4])) >>> 0;
    }
  }
  throw new Error(`invalid color string ${color} provided`);
};
function processColor(color) {
  const normalizedColor = normalizeColor(color);
  return (normalizedColor << 24 | normalizedColor >>> 8) >>> 0;
}
var NUMBER = "[-+]?\\d*\\.?\\d+";
var PERCENTAGE = NUMBER + "%";
var colorNames = {
  transparent: 0,
  aliceblue: 4042850303,
  antiquewhite: 4209760255,
  aqua: 16777215,
  aquamarine: 2147472639,
  azure: 4043309055,
  beige: 4126530815,
  bisque: 4293182719,
  black: 255,
  blanchedalmond: 4293643775,
  blue: 65535,
  blueviolet: 2318131967,
  brown: 2771004159,
  burlywood: 3736635391,
  burntsienna: 3934150143,
  cadetblue: 1604231423,
  chartreuse: 2147418367,
  chocolate: 3530104575,
  coral: 4286533887,
  cornflowerblue: 1687547391,
  cornsilk: 4294499583,
  crimson: 3692313855,
  cyan: 16777215,
  darkblue: 35839,
  darkcyan: 9145343,
  darkgoldenrod: 3095792639,
  darkgray: 2846468607,
  darkgreen: 6553855,
  darkgrey: 2846468607,
  darkkhaki: 3182914559,
  darkmagenta: 2332068863,
  darkolivegreen: 1433087999,
  darkorange: 4287365375,
  darkorchid: 2570243327,
  darkred: 2332033279,
  darksalmon: 3918953215,
  darkseagreen: 2411499519,
  darkslateblue: 1211993087,
  darkslategray: 793726975,
  darkslategrey: 793726975,
  darkturquoise: 13554175,
  darkviolet: 2483082239,
  deeppink: 4279538687,
  deepskyblue: 12582911,
  dimgray: 1768516095,
  dimgrey: 1768516095,
  dodgerblue: 512819199,
  firebrick: 2988581631,
  floralwhite: 4294635775,
  forestgreen: 579543807,
  fuchsia: 4278255615,
  gainsboro: 3705462015,
  ghostwhite: 4177068031,
  gold: 4292280575,
  goldenrod: 3668254975,
  gray: 2155905279,
  green: 8388863,
  greenyellow: 2919182335,
  grey: 2155905279,
  honeydew: 4043305215,
  hotpink: 4285117695,
  indianred: 3445382399,
  indigo: 1258324735,
  ivory: 4294963455,
  khaki: 4041641215,
  lavender: 3873897215,
  lavenderblush: 4293981695,
  lawngreen: 2096890111,
  lemonchiffon: 4294626815,
  lightblue: 2916673279,
  lightcoral: 4034953471,
  lightcyan: 3774873599,
  lightgoldenrodyellow: 4210742015,
  lightgray: 3553874943,
  lightgreen: 2431553791,
  lightgrey: 3553874943,
  lightpink: 4290167295,
  lightsalmon: 4288707327,
  lightseagreen: 548580095,
  lightskyblue: 2278488831,
  lightslategray: 2005441023,
  lightslategrey: 2005441023,
  lightsteelblue: 2965692159,
  lightyellow: 4294959359,
  lime: 16711935,
  limegreen: 852308735,
  linen: 4210091775,
  magenta: 4278255615,
  maroon: 2147483903,
  mediumaquamarine: 1724754687,
  mediumblue: 52735,
  mediumorchid: 3126187007,
  mediumpurple: 2473647103,
  mediumseagreen: 1018393087,
  mediumslateblue: 2070474495,
  mediumspringgreen: 16423679,
  mediumturquoise: 1221709055,
  mediumvioletred: 3340076543,
  midnightblue: 421097727,
  mintcream: 4127193855,
  mistyrose: 4293190143,
  moccasin: 4293178879,
  navajowhite: 4292783615,
  navy: 33023,
  oldlace: 4260751103,
  olive: 2155872511,
  olivedrab: 1804477439,
  orange: 4289003775,
  orangered: 4282712319,
  orchid: 3664828159,
  palegoldenrod: 4008225535,
  palegreen: 2566625535,
  paleturquoise: 2951671551,
  palevioletred: 3681588223,
  papayawhip: 4293907967,
  peachpuff: 4292524543,
  peru: 3448061951,
  pink: 4290825215,
  plum: 3718307327,
  powderblue: 2967529215,
  purple: 2147516671,
  rebeccapurple: 1714657791,
  red: 4278190335,
  rosybrown: 3163525119,
  royalblue: 1097458175,
  saddlebrown: 2336560127,
  salmon: 4202722047,
  sandybrown: 4104413439,
  seagreen: 780883967,
  seashell: 4294307583,
  sienna: 2689740287,
  silver: 3233857791,
  skyblue: 2278484991,
  slateblue: 1784335871,
  slategray: 1887473919,
  slategrey: 1887473919,
  snow: 4294638335,
  springgreen: 16744447,
  steelblue: 1182971135,
  tan: 3535047935,
  teal: 8421631,
  thistle: 3636451583,
  tomato: 4284696575,
  turquoise: 1088475391,
  violet: 4001558271,
  wheat: 4125012991,
  white: 4294967295,
  whitesmoke: 4126537215,
  yellow: 4294902015,
  yellowgreen: 2597139199
};
var opacity = (c2) => {
  return (c2 >> 24 & 255) / 255;
};
var red = (c2) => {
  return c2 >> 16 & 255;
};
var green = (c2) => {
  return c2 >> 8 & 255;
};
var blue = (c2) => {
  return c2 & 255;
};
var rgbaColor = (r, g, b2, alpha) => {
  return `rgba(${r}, ${g}, ${b2}, ${alpha})`;
};
var interpolateColorsRGB = (value, inputRange, colors) => {
  const [r, g, b2, a2] = [red, green, blue, opacity].map((f) => {
    const unrounded = interpolate(value, inputRange, colors.map((c2) => f(c2)), {
      extrapolateLeft: "clamp",
      extrapolateRight: "clamp"
    });
    if (f === opacity) {
      return Number(unrounded.toFixed(3));
    }
    return Math.round(unrounded);
  });
  return rgbaColor(r, g, b2, a2);
};
var interpolateColors = (input, inputRange, outputRange) => {
  if (typeof input === "undefined") {
    throw new TypeError("input can not be undefined");
  }
  if (typeof inputRange === "undefined") {
    throw new TypeError("inputRange can not be undefined");
  }
  if (typeof outputRange === "undefined") {
    throw new TypeError("outputRange can not be undefined");
  }
  if (inputRange.length !== outputRange.length) {
    throw new TypeError("inputRange (" + inputRange.length + " values provided) and outputRange (" + outputRange.length + " values provided) must have the same length");
  }
  const processedOutputRange = outputRange.map((c2) => processColor(c2));
  return interpolateColorsRGB(input, inputRange, processedOutputRange);
};
// src/validate-frame.ts
var validateFrame = ({
  allowFloats,
  durationInFrames,
  frame
}) => {
  if (typeof frame === "undefined") {
    throw new TypeError(`Argument missing for parameter "frame"`);
  }
  if (typeof frame !== "number") {
    throw new TypeError(`Argument passed for "frame" is not a number: ${frame}`);
  }
  if (!Number.isFinite(frame)) {
    throw new RangeError(`Frame ${frame} is not finite`);
  }
  if (frame % 1 !== 0 && !allowFloats) {
    throw new RangeError(`Argument for frame must be an integer, but got ${frame}`);
  }
  if (frame < 0 && frame < -durationInFrames) {
    throw new RangeError(`Cannot use frame ${frame}: Duration of composition is ${durationInFrames}, therefore the lowest frame that can be rendered is ${-durationInFrames}`);
  }
  if (frame > durationInFrames - 1) {
    throw new RangeError(`Cannot use frame ${frame}: Duration of composition is ${durationInFrames}, therefore the highest frame that can be rendered is ${durationInFrames - 1}`);
  }
};

// src/video/offthread-video-source.ts
var getOffthreadVideoSource = ({
  src,
  transparent,
  currentTime,
  toneMapped
}) => {
  return `http://localhost:${window.remotion_proxyPort}/proxy?src=${encodeURIComponent(getAbsoluteSrc(src))}&time=${encodeURIComponent(currentTime)}&transparent=${String(transparent)}&toneMapped=${String(toneMapped)}`;
};
// src/series/index.tsx
import {Children, forwardRef as forwardRef8, useMemo as useMemo28} from "react";

// src/series/flatten-children.tsx
import React24 from "react";
var flattenChildren = (children) => {
  const childrenArray = React24.Children.toArray(children);
  return childrenArray.reduce((flatChildren, child) => {
    if (child.type === React24.Fragment) {
      return flatChildren.concat(flattenChildren(child.props.children));
    }
    flatChildren.push(child);
    return flatChildren;
  }, []);
};

// src/series/is-inside-series.tsx
import React25, {createContext as createContext19} from "react";
import {
jsx as jsx27
} from "react/jsx-runtime";
var IsInsideSeriesContext = createContext19(false);
var IsInsideSeriesContainer = ({ children }) => {
  return jsx27(IsInsideSeriesContext.Provider, {
    value: true,
    children
  });
};
var IsNotInsideSeriesProvider = ({ children }) => {
  return jsx27(IsInsideSeriesContext.Provider, {
    value: false,
    children
  });
};
var useRequireToBeInsideSeries = () => {
  const isInsideSeries = React25.useContext(IsInsideSeriesContext);
  if (!isInsideSeries) {
    throw new Error("This component must be inside a <Series /> component.");
  }
};

// src/series/index.tsx
import {
jsx as jsx28
} from "react/jsx-runtime";
var SeriesSequenceRefForwardingFunction = ({ children }, _ref) => {
  useRequireToBeInsideSeries();
  return jsx28(IsNotInsideSeriesProvider, {
    children
  });
};
var SeriesSequence = forwardRef8(SeriesSequenceRefForwardingFunction);
var Series = (props2) => {
  const childrenValue = useMemo28(() => {
    let startFrame = 0;
    const flattenedChildren = flattenChildren(props2.children);
    return Children.map(flattenedChildren, (child, i) => {
      const castedChild = child;
      if (typeof castedChild === "string") {
        if (castedChild.trim() === "") {
          return null;
        }
        throw new TypeError(`The <Series /> component only accepts a list of <Series.Sequence /> components as its children, but you passed a string "${castedChild}"`);
      }
      if (castedChild.type !== SeriesSequence) {
        throw new TypeError(`The <Series /> component only accepts a list of <Series.Sequence /> components as its children, but got ${castedChild} instead`);
      }
      const debugInfo = `index = ${i}, duration = ${castedChild.props.durationInFrames}`;
      if (!castedChild?.props.children) {
        throw new TypeError(`A <Series.Sequence /> component (${debugInfo}) was detected to not have any children. Delete it to fix this error.`);
      }
      const durationInFramesProp = castedChild.props.durationInFrames;
      const {
        durationInFrames,
        children: _children,
        from,
        name,
        ...passedProps
      } = castedChild.props;
      if (i !== flattenedChildren.length - 1 || durationInFramesProp !== Infinity) {
        validateDurationInFrames(durationInFramesProp, {
          component: `of a <Series.Sequence /> component`,
          allowFloats: true
        });
      }
      const offset = castedChild.props.offset ?? 0;
      if (Number.isNaN(offset)) {
        throw new TypeError(`The "offset" property of a <Series.Sequence /> must not be NaN, but got NaN (${debugInfo}).`);
      }
      if (!Number.isFinite(offset)) {
        throw new TypeError(`The "offset" property of a <Series.Sequence /> must be finite, but got ${offset} (${debugInfo}).`);
      }
      if (offset % 1 !== 0) {
        throw new TypeError(`The "offset" property of a <Series.Sequence /> must be finite, but got ${offset} (${debugInfo}).`);
      }
      const currentStartFrame = startFrame + offset;
      startFrame += durationInFramesProp + offset;
      return jsx28(Sequence, {
        name: name || "<Series.Sequence>",
        from: currentStartFrame,
        durationInFrames: durationInFramesProp,
        ...passedProps,
        ref: castedChild.ref,
        children: child
      });
    });
  }, [props2.children]);
  if (ENABLE_V5_BREAKING_CHANGES) {
    return jsx28(IsInsideSeriesContainer, {
      children: jsx28(Sequence, {
        ...props2,
        children: childrenValue
      })
    });
  }
  return jsx28(IsInsideSeriesContainer, {
    children: childrenValue
  });
};
Series.Sequence = SeriesSequence;
addSequenceStackTraces(SeriesSequence);
// src/validation/validation-spring-duration.ts
var validateSpringDuration = (dur) => {
  if (typeof dur === "undefined") {
    return;
  }
  if (typeof dur !== "number") {
    throw new TypeError(`A "duration" of a spring must be a "number" but is "${typeof dur}"`);
  }
  if (Number.isNaN(dur)) {
    throw new TypeError('A "duration" of a spring is NaN, which it must not be');
  }
  if (!Number.isFinite(dur)) {
    throw new TypeError('A "duration" of a spring must be finite, but is ' + dur);
  }
  if (dur <= 0) {
    throw new TypeError('A "duration" of a spring must be positive, but is ' + dur);
  }
};

// src/spring/spring-utils.ts
var advance = function({
  animation,
  now,
  config
}) {
  const { toValue, lastTimestamp, current, velocity } = animation;
  const deltaTime = Math.min(now - lastTimestamp, 64);
  if (config.damping <= 0) {
    throw new Error("Spring damping must be greater than 0, otherwise the spring() animation will never end, causing an infinite loop.");
  }
  const c2 = config.damping;
  const m = config.mass;
  const k = config.stiffness;
  const cacheKey = [
    toValue,
    lastTimestamp,
    current,
    velocity,
    c2,
    m,
    k,
    now
  ].join("-");
  if (advanceCache[cacheKey]) {
    return advanceCache[cacheKey];
  }
  const v0 = -velocity;
  const x0 = toValue - current;
  const zeta = c2 / (2 * Math.sqrt(k * m));
  const omega0 = Math.sqrt(k / m);
  const omega1 = omega0 * Math.sqrt(1 - zeta ** 2);
  const t = deltaTime / 1000;
  const sin1 = Math.sin(omega1 * t);
  const cos1 = Math.cos(omega1 * t);
  const underDampedEnvelope = Math.exp(-zeta * omega0 * t);
  const underDampedFrag1 = underDampedEnvelope * (sin1 * ((v0 + zeta * omega0 * x0) / omega1) + x0 * cos1);
  const underDampedPosition = toValue - underDampedFrag1;
  const underDampedVelocity = zeta * omega0 * underDampedFrag1 - underDampedEnvelope * (cos1 * (v0 + zeta * omega0 * x0) - omega1 * x0 * sin1);
  const criticallyDampedEnvelope = Math.exp(-omega0 * t);
  const criticallyDampedPosition = toValue - criticallyDampedEnvelope * (x0 + (v0 + omega0 * x0) * t);
  const criticallyDampedVelocity = criticallyDampedEnvelope * (v0 * (t * omega0 - 1) + t * x0 * omega0 * omega0);
  const animationNode = {
    toValue,
    prevPosition: current,
    lastTimestamp: now,
    current: zeta < 1 ? underDampedPosition : criticallyDampedPosition,
    velocity: zeta < 1 ? underDampedVelocity : criticallyDampedVelocity
  };
  advanceCache[cacheKey] = animationNode;
  return animationNode;
};
function springCalculation({
  frame,
  fps,
  config = {}
}) {
  const from = 0;
  const to = 1;
  const cacheKey = [
    frame,
    fps,
    config.damping,
    config.mass,
    config.overshootClamping,
    config.stiffness
  ].join("-");
  if (calculationCache[cacheKey]) {
    return calculationCache[cacheKey];
  }
  let animation = {
    lastTimestamp: 0,
    current: from,
    toValue: to,
    velocity: 0,
    prevPosition: 0
  };
  const frameClamped = Math.max(0, frame);
  const unevenRest = frameClamped % 1;
  for (let f = 0;f <= Math.floor(frameClamped); f++) {
    if (f === Math.floor(frameClamped)) {
      f += unevenRest;
    }
    const time = f / fps * 1000;
    animation = advance({
      animation,
      now: time,
      config: {
        ...defaultSpringConfig,
        ...config
      }
    });
  }
  calculationCache[cacheKey] = animation;
  return animation;
}
var defaultSpringConfig = {
  damping: 10,
  mass: 1,
  stiffness: 100,
  overshootClamping: false
};
var advanceCache = {};
var calculationCache = {};

// src/spring/measure-spring.ts
function measureSpring({
  fps,
  config = {},
  threshold = 0.005
}) {
  if (typeof threshold !== "number") {
    throw new TypeError(`threshold must be a number, got ${threshold} of type ${typeof threshold}`);
  }
  if (threshold === 0) {
    return Infinity;
  }
  if (threshold === 1) {
    return 0;
  }
  if (isNaN(threshold)) {
    throw new TypeError("Threshold is NaN");
  }
  if (!Number.isFinite(threshold)) {
    throw new TypeError("Threshold is not finite");
  }
  if (threshold < 0) {
    throw new TypeError("Threshold is below 0");
  }
  const cacheKey = [
    fps,
    config.damping,
    config.mass,
    config.overshootClamping,
    config.stiffness,
    threshold
  ].join("-");
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }
  validateFps(fps, "to the measureSpring() function", false);
  let frame = 0;
  let finishedFrame = 0;
  const calc = () => {
    return springCalculation({
      fps,
      frame,
      config
    });
  };
  let animation = calc();
  const calcDifference = () => {
    return Math.abs(animation.current - animation.toValue);
  };
  let difference = calcDifference();
  while (difference >= threshold) {
    frame++;
    animation = calc();
    difference = calcDifference();
  }
  finishedFrame = frame;
  for (let i = 0;i < 20; i++) {
    frame++;
    animation = calc();
    difference = calcDifference();
    if (difference >= threshold) {
      i = 0;
      finishedFrame = frame + 1;
    }
  }
  cache.set(cacheKey, finishedFrame);
  return finishedFrame;
}
var cache = new Map;

// src/spring/index.ts
function spring({
  frame: passedFrame,
  fps,
  config = {},
  from = 0,
  to = 1,
  durationInFrames: passedDurationInFrames,
  durationRestThreshold,
  delay = 0,
  reverse = false
}) {
  validateSpringDuration(passedDurationInFrames);
  validateFrame({
    frame: passedFrame,
    durationInFrames: Infinity,
    allowFloats: true
  });
  validateFps(fps, "to spring()", false);
  const needsToCalculateNaturalDuration = reverse || typeof passedDurationInFrames !== "undefined";
  const naturalDuration = needsToCalculateNaturalDuration ? measureSpring({
    fps,
    config,
    threshold: durationRestThreshold
  }) : undefined;
  const naturalDurationGetter = needsToCalculateNaturalDuration ? {
    get: () => naturalDuration
  } : {
    get: () => {
      throw new Error("did not calculate natural duration, this is an error with Remotion. Please report");
    }
  };
  const reverseProcessed = reverse ? (passedDurationInFrames ?? naturalDurationGetter.get()) - passedFrame : passedFrame;
  const delayProcessed = reverseProcessed + (reverse ? delay : -delay);
  const durationProcessed = passedDurationInFrames === undefined ? delayProcessed : delayProcessed / (passedDurationInFrames / naturalDurationGetter.get());
  if (passedDurationInFrames && delayProcessed > passedDurationInFrames) {
    return to;
  }
  const spr = springCalculation({
    fps,
    frame: durationProcessed,
    config
  });
  const inner2 = config.overshootClamping ? to >= from ? Math.min(spr.current, to) : Math.max(spr.current, to) : spr.current;
  const interpolated = from === 0 && to === 1 ? inner2 : interpolate(inner2, [0, 1], [from, to]);
  return interpolated;
}
// src/Still.tsx
import React27 from "react";
var Still = (props2) => {
  const newProps = {
    ...props2,
    durationInFrames: 1,
    fps: 1
  };
  return React27.createElement(Composition, newProps);
};
// src/video/OffthreadVideo.tsx
import {useCallback as useCallback14} from "react";

// src/video/OffthreadVideoForRendering.tsx
import {
useCallback as useCallback13,
useContext as useContext27,
useEffect as useEffect21,
useLayoutEffect as useLayoutEffect6,
useMemo as useMemo29,
useState as useState18
} from "react";
import {
jsx as jsx29
} from "react/jsx-runtime";
var OffthreadVideoForRendering = ({
  onError,
  volume: volumeProp,
  playbackRate,
  src,
  muted,
  allowAmplificationDuringRender,
  transparent = false,
  toneMapped = true,
  toneFrequency,
  name,
  loopVolumeCurveBehavior,
  delayRenderRetries,
  delayRenderTimeoutInMilliseconds,
  onVideoFrame,
  ...props2
}) => {
  const absoluteFrame = useTimelinePosition();
  const frame = useCurrentFrame();
  const volumePropsFrame = useFrameForVolumeProp(loopVolumeCurveBehavior ?? "repeat");
  const videoConfig = useUnsafeVideoConfig();
  const sequenceContext = useContext27(SequenceContext);
  const mediaStartsAt = useMediaStartsAt();
  const { registerRenderAsset, unregisterRenderAsset } = useContext27(RenderAssetManager);
  if (!src) {
    throw new TypeError("No `src` was passed to <OffthreadVideo>.");
  }
  const id = useMemo29(() => `offthreadvideo-${random(src ?? "")}-${sequenceContext?.cumulatedFrom}-${sequenceContext?.relativeFrom}-${sequenceContext?.durationInFrames}`, [
    src,
    sequenceContext?.cumulatedFrom,
    sequenceContext?.relativeFrom,
    sequenceContext?.durationInFrames
  ]);
  if (!videoConfig) {
    throw new Error("No video config found");
  }
  const volume = evaluateVolume({
    volume: volumeProp,
    frame: volumePropsFrame,
    mediaVolume: 1,
    allowAmplificationDuringRender: allowAmplificationDuringRender ?? false
  });
  useEffect21(() => {
    if (!src) {
      throw new Error("No src passed");
    }
    if (!window.remotion_audioEnabled) {
      return;
    }
    if (muted) {
      return;
    }
    if (volume <= 0) {
      return;
    }
    registerRenderAsset({
      type: "video",
      src: getAbsoluteSrc(src),
      id,
      frame: absoluteFrame,
      volume,
      mediaFrame: frame,
      playbackRate: playbackRate ?? 1,
      allowAmplificationDuringRender: allowAmplificationDuringRender ?? false,
      toneFrequency: toneFrequency ?? null,
      audioStartFrame: Math.max(0, -(sequenceContext?.relativeFrom ?? 0))
    });
    return () => unregisterRenderAsset(id);
  }, [
    muted,
    src,
    registerRenderAsset,
    id,
    unregisterRenderAsset,
    volume,
    frame,
    absoluteFrame,
    playbackRate,
    allowAmplificationDuringRender,
    toneFrequency,
    sequenceContext?.relativeFrom
  ]);
  const currentTime = useMemo29(() => {
    return getExpectedMediaFrameUncorrected({
      frame,
      playbackRate: playbackRate || 1,
      startFrom: -mediaStartsAt
    }) / videoConfig.fps;
  }, [frame, mediaStartsAt, playbackRate, videoConfig.fps]);
  const actualSrc = useMemo29(() => {
    return getOffthreadVideoSource({
      src,
      currentTime,
      transparent,
      toneMapped
    });
  }, [toneMapped, currentTime, src, transparent]);
  const [imageSrc, setImageSrc] = useState18(null);
  useLayoutEffect6(() => {
    const cleanup = [];
    setImageSrc(null);
    const controller = new AbortController;
    const newHandle = delayRender("Fetching " + actualSrc + "from server", {
      retries: delayRenderRetries ?? undefined,
      timeoutInMilliseconds: delayRenderTimeoutInMilliseconds ?? undefined
    });
    const execute = async () => {
      try {
        const res = await fetch(actualSrc, {
          signal: controller.signal
        });
        if (res.status !== 200) {
          if (res.status === 500) {
            const json = await res.json();
            if (json.error) {
              const cleanedUpErrorMessage = json.error.replace(/^Error: /, "");
              throw new Error(cleanedUpErrorMessage);
            }
          }
          throw new Error(`Server returned status ${res.status} while fetching ${actualSrc}`);
        }
        const blob = await res.blob();
        const url = URL.createObjectURL(blob);
        cleanup.push(() => URL.revokeObjectURL(url));
        setImageSrc({
          src: url,
          handle: newHandle
        });
      } catch (err) {
        if (err.message.includes("aborted")) {
          return;
        }
        if (controller.signal.aborted) {
          return;
        }
        if (err.message.includes("Failed to fetch")) {
          err = new Error(`Failed to fetch ${actualSrc}. This could be caused by Chrome rejecting the request because the disk space is low. Consider increasing the disk size of your environment.`, { cause: err });
        }
        if (onError) {
          onError(err);
        } else {
          cancelRender(err);
        }
      }
    };
    execute();
    cleanup.push(() => {
      if (controller.signal.aborted) {
        return;
      }
      controller.abort();
    });
    return () => {
      cleanup.forEach((c2) => c2());
    };
  }, [
    actualSrc,
    delayRenderRetries,
    delayRenderTimeoutInMilliseconds,
    onError
  ]);
  const onErr = useCallback13(() => {
    if (onError) {
      onError?.(new Error("Failed to load image with src " + imageSrc));
    } else {
      cancelRender("Failed to load image with src " + imageSrc);
    }
  }, [imageSrc, onError]);
  const className = useMemo29(() => {
    return [OFFTHREAD_VIDEO_CLASS_NAME, props2.className].filter(truthy).join(" ");
  }, [props2.className]);
  const onImageFrame = useCallback13((img) => {
    if (onVideoFrame) {
      onVideoFrame(img);
    }
  }, [onVideoFrame]);
  if (!imageSrc) {
    return null;
  }
  continueRender(imageSrc.handle);
  return jsx29(Img, {
    src: imageSrc.src,
    className,
    delayRenderRetries,
    delayRenderTimeoutInMilliseconds,
    onImageFrame,
    ...props2,
    onError: onErr
  });
};

// src/video/VideoForPreview.tsx
import {
forwardRef as forwardRef9,
useContext as useContext28,
useEffect as useEffect23,
useImperativeHandle as useImperativeHandle7,
useMemo as useMemo30,
useRef as useRef13,
useState as useState19
} from "react";

// src/video/emit-video-frame.ts
import {useEffect as useEffect22} from "react";
var useEmitVideoFrame = ({
  ref,
  onVideoFrame
}) => {
  useEffect22(() => {
    const { current } = ref;
    if (!current) {
      return;
    }
    if (!onVideoFrame) {
      return;
    }
    let handle = 0;
    const callback = () => {
      if (!ref.current) {
        return;
      }
      onVideoFrame(ref.current);
      handle = ref.current.requestVideoFrameCallback(callback);
    };
    callback();
    return () => {
      current.cancelVideoFrameCallback(handle);
    };
  }, [onVideoFrame, ref]);
};

// src/video/VideoForPreview.tsx
import {
jsx as jsx30
} from "react/jsx-runtime";
var VideoForDevelopmentRefForwardingFunction = (props2, ref) => {
  const videoRef = useRef13(null);
  const {
    volume,
    muted,
    playbackRate,
    onlyWarnForMediaSeekingError,
    src,
    onDuration,
    acceptableTimeShift,
    acceptableTimeShiftInSeconds,
    toneFrequency,
    name,
    _remotionInternalNativeLoopPassed,
    _remotionInternalStack,
    _remotionDebugSeeking,
    style,
    pauseWhenBuffering,
    showInTimeline,
    loopVolumeCurveBehavior,
    onError,
    onAutoPlayError,
    onVideoFrame,
    crossOrigin,
    ...nativeProps
  } = props2;
  const volumePropFrame = useFrameForVolumeProp(loopVolumeCurveBehavior ?? "repeat");
  const { fps, durationInFrames } = useVideoConfig();
  const parentSequence = useContext28(SequenceContext);
  const { hidden } = useContext28(SequenceVisibilityToggleContext);
  const [timelineId] = useState19(() => String(Math.random()));
  const isSequenceHidden = hidden[timelineId] ?? false;
  if (typeof acceptableTimeShift !== "undefined") {
    throw new Error("acceptableTimeShift has been removed. Use acceptableTimeShiftInSeconds instead.");
  }
  const actualVolume = useMediaTagVolume(videoRef);
  const [mediaVolume] = useMediaVolumeState();
  const [mediaMuted] = useMediaMutedState();
  useMediaInTimeline({
    mediaRef: videoRef,
    volume,
    mediaVolume,
    mediaType: "video",
    src,
    playbackRate: props2.playbackRate ?? 1,
    displayName: name ?? null,
    id: timelineId,
    stack: _remotionInternalStack,
    showInTimeline,
    premountDisplay: null,
    onAutoPlayError: onAutoPlayError ?? null
  });
  useSyncVolumeWithMediaTag({
    volumePropFrame,
    actualVolume,
    volume,
    mediaVolume,
    mediaRef: videoRef
  });
  useMediaPlayback({
    mediaRef: videoRef,
    src,
    mediaType: "video",
    playbackRate: props2.playbackRate ?? 1,
    onlyWarnForMediaSeekingError,
    acceptableTimeshift: acceptableTimeShiftInSeconds ?? DEFAULT_ACCEPTABLE_TIMESHIFT,
    isPremounting: Boolean(parentSequence?.premounting),
    pauseWhenBuffering,
    debugSeeking: _remotionDebugSeeking,
    onAutoPlayError: onAutoPlayError ?? null
  });
  const actualFrom = parentSequence ? parentSequence.relativeFrom : 0;
  const duration = parentSequence ? Math.min(parentSequence.durationInFrames, durationInFrames) : durationInFrames;
  const actualSrc = useAppendVideoFragment({
    actualSrc: usePreload(src),
    actualFrom,
    duration,
    fps
  });
  useImperativeHandle7(ref, () => {
    return videoRef.current;
  }, []);
  useEffect23(() => {
    const { current } = videoRef;
    if (!current) {
      return;
    }
    const errorHandler = () => {
      if (current.error) {
        console.error("Error occurred in video", current?.error);
        if (onError) {
          const err = new Error(`Code ${current.error.code}: ${current.error.message}`);
          onError(err);
          return;
        }
        throw new Error(`The browser threw an error while playing the video ${src}: Code ${current.error.code} - ${current?.error?.message}. See https://remotion.dev/docs/media-playback-error for help. Pass an onError() prop to handle the error.`);
      } else {
        if (onError) {
          const err = new Error(`The browser threw an error while playing the video ${src}`);
          onError(err);
          return;
        }
        throw new Error("The browser threw an error while playing the video");
      }
    };
    current.addEventListener("error", errorHandler, { once: true });
    return () => {
      current.removeEventListener("error", errorHandler);
    };
  }, [onError, src]);
  const currentOnDurationCallback = useRef13();
  currentOnDurationCallback.current = onDuration;
  useEmitVideoFrame({ ref: videoRef, onVideoFrame });
  useEffect23(() => {
    const { current } = videoRef;
    if (!current) {
      return;
    }
    if (current.duration) {
      currentOnDurationCallback.current?.(src, current.duration);
      return;
    }
    const onLoadedMetadata = () => {
      currentOnDurationCallback.current?.(src, current.duration);
    };
    current.addEventListener("loadedmetadata", onLoadedMetadata);
    return () => {
      current.removeEventListener("loadedmetadata", onLoadedMetadata);
    };
  }, [src]);
  useEffect23(() => {
    const { current } = videoRef;
    if (!current) {
      return;
    }
    if (isIosSafari()) {
      current.preload = "metadata";
    } else {
      current.preload = "auto";
    }
  }, []);
  const actualStyle = useMemo30(() => {
    return {
      ...style,
      opacity: isSequenceHidden ? 0 : style?.opacity ?? 1
    };
  }, [isSequenceHidden, style]);
  const crossOriginValue = crossOrigin ?? (onVideoFrame ? "anonymous" : "");
  return jsx30("video", {
    ref: videoRef,
    muted: muted || mediaMuted,
    playsInline: true,
    src: actualSrc,
    loop: _remotionInternalNativeLoopPassed,
    style: actualStyle,
    disableRemotePlayback: true,
    crossOrigin: crossOriginValue,
    ...nativeProps
  });
};
var VideoForPreview = forwardRef9(VideoForDevelopmentRefForwardingFunction);

// src/video/OffthreadVideo.tsx
import {
jsx as jsx31
} from "react/jsx-runtime";
var OffthreadVideo = (props2) => {
  const {
    startFrom,
    endAt,
    name,
    pauseWhenBuffering,
    stack,
    showInTimeline,
    ...otherProps
  } = props2;
  const environment = getRemotionEnvironment();
  const onDuration = useCallback14(() => {
    return;
  }, []);
  if (typeof props2.src !== "string") {
    throw new TypeError(`The \`<OffthreadVideo>\` tag requires a string for \`src\`, but got ${JSON.stringify(props2.src)} instead.`);
  }
  if (props2.imageFormat) {
    throw new TypeError(`The \`<OffthreadVideo>\` tag does no longer accept \`imageFormat\`. Use the \`transparent\` prop if you want to render a transparent video.`);
  }
  if (typeof startFrom !== "undefined" || typeof endAt !== "undefined") {
    validateStartFromProps(startFrom, endAt);
    const startFromFrameNo = startFrom ?? 0;
    const endAtFrameNo = endAt ?? Infinity;
    return jsx31(Sequence, {
      layout: "none",
      from: 0 - startFromFrameNo,
      showInTimeline: false,
      durationInFrames: endAtFrameNo,
      name,
      children: jsx31(OffthreadVideo, {
        pauseWhenBuffering: pauseWhenBuffering ?? false,
        ...otherProps
      })
    });
  }
  validateMediaProps(props2, "Video");
  if (environment.isRendering) {
    return jsx31(OffthreadVideoForRendering, {
      ...otherProps
    });
  }
  const {
    transparent,
    toneMapped,
    _remotionDebugSeeking,
    onAutoPlayError,
    onVideoFrame,
    crossOrigin,
    ...withoutTransparent
  } = otherProps;
  return jsx31(VideoForPreview, {
    _remotionInternalStack: stack ?? null,
    _remotionInternalNativeLoopPassed: false,
    _remotionDebugSeeking: _remotionDebugSeeking ?? false,
    onDuration,
    onlyWarnForMediaSeekingError: true,
    pauseWhenBuffering: pauseWhenBuffering ?? false,
    showInTimeline: showInTimeline ?? true,
    onAutoPlayError: onAutoPlayError ?? undefined,
    onVideoFrame: onVideoFrame ?? null,
    crossOrigin,
    ...withoutTransparent
  });
};
// src/video/Video.tsx
import {forwardRef as forwardRef11, useCallback as useCallback15, useContext as useContext30} from "react";

// src/video/VideoForRendering.tsx
import {
forwardRef as forwardRef10,
useContext as useContext29,
useEffect as useEffect24,
useImperativeHandle as useImperativeHandle8,
useLayoutEffect as useLayoutEffect7,
useMemo as useMemo31,
useRef as useRef14
} from "react";

// src/video/seek-until-right.ts
var roundTo6Commas = (num) => {
  return Math.round(num * 1e5) / 1e5;
};
var seekToTime = (element, desiredTime) => {
  element.currentTime = desiredTime;
  let cancel;
  let cancelSeeked = null;
  const prom = new Promise((resolve) => {
    cancel = element.requestVideoFrameCallback((now, metadata) => {
      const displayIn = metadata.expectedDisplayTime - now;
      if (displayIn <= 0) {
        resolve(metadata.mediaTime);
        return;
      }
      setTimeout(() => {
        resolve(metadata.mediaTime);
      }, displayIn + 150);
    });
  });
  const waitForSeekedEvent = new Promise((resolve) => {
    const onDone = () => {
      resolve();
    };
    element.addEventListener("seeked", onDone, {
      once: true
    });
    cancelSeeked = () => {
      element.removeEventListener("seeked", onDone);
    };
  });
  return {
    wait: Promise.all([prom, waitForSeekedEvent]).then(([time]) => time),
    cancel: () => {
      cancelSeeked?.();
      element.cancelVideoFrameCallback(cancel);
    }
  };
};
var seekToTimeMultipleUntilRight = (element, desiredTime, fps) => {
  const threshold = 1 / fps / 2;
  let currentCancel = () => {
    return;
  };
  if (Number.isFinite(element.duration) && element.currentTime >= element.duration && desiredTime >= element.duration) {
    return {
      prom: Promise.resolve(),
      cancel: () => {
      }
    };
  }
  const prom = new Promise((resolve, reject) => {
    const firstSeek = seekToTime(element, desiredTime + threshold);
    firstSeek.wait.then((seekedTo) => {
      const difference = Math.abs(desiredTime - seekedTo);
      if (difference <= threshold) {
        return resolve();
      }
      const sign = desiredTime > seekedTo ? 1 : -1;
      const newSeek = seekToTime(element, seekedTo + threshold * sign);
      currentCancel = newSeek.cancel;
      newSeek.wait.then((newTime) => {
        const newDifference = Math.abs(desiredTime - newTime);
        if (roundTo6Commas(newDifference) <= roundTo6Commas(threshold)) {
          return resolve();
        }
        const thirdSeek = seekToTime(element, desiredTime + threshold);
        currentCancel = thirdSeek.cancel;
        return thirdSeek.wait.then(() => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
      }).catch((err) => {
        reject(err);
      });
    });
    currentCancel = firstSeek.cancel;
  });
  return {
    prom,
    cancel: () => {
      currentCancel();
    }
  };
};

// src/video/VideoForRendering.tsx
import {
jsx as jsx32
} from "react/jsx-runtime";
var VideoForRenderingForwardFunction = ({
  onError,
  volume: volumeProp,
  allowAmplificationDuringRender,
  playbackRate,
  onDuration,
  toneFrequency,
  name,
  acceptableTimeShiftInSeconds,
  delayRenderRetries,
  delayRenderTimeoutInMilliseconds,
  loopVolumeCurveBehavior,
  ...props2
}, ref) => {
  const absoluteFrame = useTimelinePosition();
  const frame = useCurrentFrame();
  const volumePropsFrame = useFrameForVolumeProp(loopVolumeCurveBehavior ?? "repeat");
  const videoConfig = useUnsafeVideoConfig();
  const videoRef = useRef14(null);
  const sequenceContext = useContext29(SequenceContext);
  const mediaStartsAt = useMediaStartsAt();
  const environment = getRemotionEnvironment();
  const { registerRenderAsset, unregisterRenderAsset } = useContext29(RenderAssetManager);
  const id = useMemo31(() => `video-${random(props2.src ?? "")}-${sequenceContext?.cumulatedFrom}-${sequenceContext?.relativeFrom}-${sequenceContext?.durationInFrames}`, [
    props2.src,
    sequenceContext?.cumulatedFrom,
    sequenceContext?.relativeFrom,
    sequenceContext?.durationInFrames
  ]);
  if (!videoConfig) {
    throw new Error("No video config found");
  }
  const volume = evaluateVolume({
    volume: volumeProp,
    frame: volumePropsFrame,
    mediaVolume: 1,
    allowAmplificationDuringRender: allowAmplificationDuringRender ?? false
  });
  useEffect24(() => {
    if (!props2.src) {
      throw new Error("No src passed");
    }
    if (props2.muted) {
      return;
    }
    if (volume <= 0) {
      return;
    }
    if (!window.remotion_audioEnabled) {
      return;
    }
    registerRenderAsset({
      type: "video",
      src: getAbsoluteSrc(props2.src),
      id,
      frame: absoluteFrame,
      volume,
      mediaFrame: frame,
      playbackRate: playbackRate ?? 1,
      allowAmplificationDuringRender: allowAmplificationDuringRender ?? false,
      toneFrequency: toneFrequency ?? null,
      audioStartFrame: Math.max(0, -(sequenceContext?.relativeFrom ?? 0))
    });
    return () => unregisterRenderAsset(id);
  }, [
    props2.muted,
    props2.src,
    registerRenderAsset,
    id,
    unregisterRenderAsset,
    volume,
    frame,
    absoluteFrame,
    playbackRate,
    allowAmplificationDuringRender,
    toneFrequency,
    sequenceContext?.relativeFrom
  ]);
  useImperativeHandle8(ref, () => {
    return videoRef.current;
  }, []);
  useEffect24(() => {
    if (!window.remotion_videoEnabled) {
      return;
    }
    const { current } = videoRef;
    if (!current) {
      return;
    }
    const currentTime = getMediaTime({
      frame,
      playbackRate: playbackRate || 1,
      startFrom: -mediaStartsAt,
      fps: videoConfig.fps
    });
    const handle = delayRender(`Rendering <Video /> with src="${props2.src}"`, {
      retries: delayRenderRetries ?? undefined,
      timeoutInMilliseconds: delayRenderTimeoutInMilliseconds ?? undefined
    });
    if (window.process?.env?.NODE_ENV === "test") {
      continueRender(handle);
      return;
    }
    if (isApproximatelyTheSame(current.currentTime, currentTime)) {
      if (current.readyState >= 2) {
        continueRender(handle);
        return;
      }
      const loadedDataHandler = () => {
        continueRender(handle);
      };
      current.addEventListener("loadeddata", loadedDataHandler, { once: true });
      return () => {
        current.removeEventListener("loadeddata", loadedDataHandler);
      };
    }
    const endedHandler = () => {
      continueRender(handle);
    };
    const seek2 = seekToTimeMultipleUntilRight(current, currentTime, videoConfig.fps);
    seek2.prom.then(() => {
      continueRender(handle);
    });
    current.addEventListener("ended", endedHandler, { once: true });
    const errorHandler = () => {
      if (current?.error) {
        console.error("Error occurred in video", current?.error);
        if (onError) {
          return;
        }
        throw new Error(`The browser threw an error while playing the video ${props2.src}: Code ${current.error.code} - ${current?.error?.message}. See https://remotion.dev/docs/media-playback-error for help. Pass an onError() prop to handle the error.`);
      } else {
        throw new Error("The browser threw an error");
      }
    };
    current.addEventListener("error", errorHandler, { once: true });
    return () => {
      seek2.cancel();
      current.removeEventListener("ended", endedHandler);
      current.removeEventListener("error", errorHandler);
      continueRender(handle);
    };
  }, [
    volumePropsFrame,
    props2.src,
    playbackRate,
    videoConfig.fps,
    frame,
    mediaStartsAt,
    onError,
    delayRenderRetries,
    delayRenderTimeoutInMilliseconds
  ]);
  const { src } = props2;
  if (environment.isRendering) {
    useLayoutEffect7(() => {
      if (window.process?.env?.NODE_ENV === "test") {
        return;
      }
      const newHandle = delayRender("Loading <Video> duration with src=" + src, {
        retries: delayRenderRetries ?? undefined,
        timeoutInMilliseconds: delayRenderTimeoutInMilliseconds ?? undefined
      });
      const { current } = videoRef;
      const didLoad = () => {
        if (current?.duration) {
          onDuration(src, current.duration);
        }
        continueRender(newHandle);
      };
      if (current?.duration) {
        onDuration(src, current.duration);
        continueRender(newHandle);
      } else {
        current?.addEventListener("loadedmetadata", didLoad, { once: true });
      }
      return () => {
        current?.removeEventListener("loadedmetadata", didLoad);
        continueRender(newHandle);
      };
    }, [src, onDuration, delayRenderRetries, delayRenderTimeoutInMilliseconds]);
  }
  return jsx32("video", {
    ref: videoRef,
    ...props2
  });
};
var VideoForRendering = forwardRef10(VideoForRenderingForwardFunction);

// src/video/Video.tsx
import {
jsx as jsx33
} from "react/jsx-runtime";
var VideoForwardingFunction = (props2, ref) => {
  const {
    startFrom,
    endAt,
    name,
    pauseWhenBuffering,
    stack,
    _remotionInternalNativeLoopPassed,
    showInTimeline,
    onAutoPlayError,
    onVideoFrame,
    crossOrigin,
    ...otherProps
  } = props2;
  const { loop: loop4, _remotionDebugSeeking, ...propsOtherThanLoop } = props2;
  const { fps } = useVideoConfig();
  const environment = getRemotionEnvironment();
  const { durations, setDurations } = useContext30(DurationsContext);
  if (typeof ref === "string") {
    throw new Error("string refs are not supported");
  }
  if (typeof props2.src !== "string") {
    throw new TypeError(`The \`<Video>\` tag requires a string for \`src\`, but got ${JSON.stringify(props2.src)} instead.`);
  }
  const preloadedSrc = usePreload(props2.src);
  const onDuration = useCallback15((src, durationInSeconds) => {
    setDurations({ type: "got-duration", durationInSeconds, src });
  }, [setDurations]);
  const durationFetched = durations[getAbsoluteSrc(preloadedSrc)] ?? durations[getAbsoluteSrc(props2.src)];
  if (loop4 && durationFetched !== undefined) {
    const mediaDuration = durationFetched * fps;
    return jsx33(Loop, {
      durationInFrames: calculateLoopDuration({
        endAt,
        mediaDuration,
        playbackRate: props2.playbackRate ?? 1,
        startFrom
      }),
      layout: "none",
      name,
      children: jsx33(Video, {
        ...propsOtherThanLoop,
        ref,
        onVideoFrame,
        _remotionInternalNativeLoopPassed: true
      })
    });
  }
  if (typeof startFrom !== "undefined" || typeof endAt !== "undefined") {
    validateStartFromProps(startFrom, endAt);
    const startFromFrameNo = startFrom ?? 0;
    const endAtFrameNo = endAt ?? Infinity;
    return jsx33(Sequence, {
      layout: "none",
      from: 0 - startFromFrameNo,
      showInTimeline: false,
      durationInFrames: endAtFrameNo,
      name,
      children: jsx33(Video, {
        pauseWhenBuffering: pauseWhenBuffering ?? false,
        onVideoFrame,
        ...otherProps,
        ref
      })
    });
  }
  validateMediaProps(props2, "Video");
  if (environment.isRendering) {
    return jsx33(VideoForRendering, {
      onDuration,
      onVideoFrame: onVideoFrame ?? null,
      ...otherProps,
      ref
    });
  }
  return jsx33(VideoForPreview, {
    onlyWarnForMediaSeekingError: false,
    ...otherProps,
    ref,
    onVideoFrame: onVideoFrame ?? null,
    pauseWhenBuffering: pauseWhenBuffering ?? false,
    onDuration,
    _remotionInternalStack: stack ?? null,
    _remotionInternalNativeLoopPassed: _remotionInternalNativeLoopPassed ?? false,
    _remotionDebugSeeking: _remotionDebugSeeking ?? false,
    showInTimeline: showInTimeline ?? true,
    onAutoPlayError: onAutoPlayError ?? undefined,
    crossOrigin
  });
};
var Video = forwardRef11(VideoForwardingFunction);
addSequenceStackTraces(Video);
// src/index.ts
checkMultipleRemotionVersions();
var Experimental = {
  Clipper,
  Null,
  useIsPlayer
};
var proxyObj = {};
var Config = new Proxy(proxyObj, {
  get(_, prop) {
    if (prop === "Bundling" || prop === "Rendering" || prop === "Log" || prop === "Puppeteer" || prop === "Output") {
      return Config;
    }
    return () => {
      console.warn("\u26A0\uFE0F  The CLI configuration has been extracted from Remotion Core.");
      console.warn("Update the import from the config file:");
      console.warn();
      console.warn("- Delete:");
      console.warn('import {Config} from "remotion";');
      console.warn("+ Replace:");
      console.warn('import {Config} from "@remotion/cli/config";');
      console.warn();
      console.warn("For more information, see https://www.remotion.dev/docs/4-0-migration.");
      process.exit(1);
    };
  }
});
addSequenceStackTraces(Sequence);
export {
  watchStaticFile,
  useVideoConfig,
  useCurrentScale,
  useCurrentFrame,
  useBufferState,
  staticFile,
  spring,
  registerRoot,
  random,
  prefetch,
  measureSpring,
  interpolateColors,
  interpolate,
  getStaticFiles,
  getRemotionEnvironment,
  getInputProps,
  delayRender,
  continueRender,
  cancelRender,
  Video,
  VERSION,
  Still,
  Series,
  Sequence,
  OffthreadVideo,
  Loop,
  Internals,
  Img,
  IFrame,
  Freeze,
  FolderContext,
  Folder,
  Experimental,
  Easing,
  Config,
  Composition,
  Audio,
  Artifact,
  AbsoluteFill
};

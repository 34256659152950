import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useAuth } from "@/src/helpers/auth.hook";
import { Link } from "react-router-dom";

type Props = {
  selectEmailSignup: () => void;
};

export function SignupOptionsCard({ selectEmailSignup }: Props) {
  const { loginWithGoogle } = useAuth();
  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-2xl text-center">Sign up</CardTitle>
        {/* <CardDescription>Sign up with any of these methods</CardDescription> */}
      </CardHeader>
      <CardContent className="mt-4 grid gap-4">
        <Button
          className="w-full"
          variant="default"
          onClick={() => loginWithGoogle()}
        >
          Sign up with Google
        </Button>
        {process.env.ENABLE_EMAIL_SIGNUP === "true" && (
          <Button
            className="w-full"
            variant="default"
            onClick={() => selectEmailSignup()}
          >
            Sign up with Email
          </Button>
        )}
      </CardContent>
      <CardFooter>
        <CardDescription className="text-sm">
          Already have an account?
        </CardDescription>
        <Button asChild variant="link" className="px-1">
          <Link to="/login">Login</Link>
        </Button>
      </CardFooter>
    </Card>
  );
}

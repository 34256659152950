import { Word } from "@/src/types";
import { Sequence, useVideoConfig } from "remotion";

export default function SeriesWordRenderer({
  words,
  renderWord,
}: {
  words: Word[];
  renderWord: (word: Word) => React.ReactNode;
}) {
  const { fps } = useVideoConfig();
  return (
    <>
      {words.map((word, index: number) => {
        return (
          <Sequence
            key={index}
            from={(word.start / 1000) * fps}
            durationInFrames={
              (word.end / 1000) * fps - (word.start / 1000) * fps
            }
          >
            <div className="w-full flex flex-col items-center justify-end px-8 py-16 pb-[300px]">
              {renderWord(word)}
            </div>
          </Sequence>
        );
      })}
    </>
  );
}

import { INPUT_CLASSNAMES, Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import clsx from "clsx";
import { useId, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { useMediaQuery } from "react-responsive";

const ColorCircle = ({
  color,
  interactive = false,
}: {
  color: string;
  interactive?: boolean;
}) => (
  <div
    className={clsx("inline-block w-8 h-8 rounded-full ring-1 ring-black/50", {
      "hover:opacity-80 transition-opacity cursor-pointer": interactive,
    })}
    style={{
      backgroundColor: color,
    }}
  ></div>
);

type ColorPalette = string[];

const PRESET_COLOR_PALETTES: ColorPalette[] = [
  ["#006769", "#40A578", "#9DDE8B", "#E6FF94"],
  ["#6F4E37", "#A67B5B", "#ECB176", "#FED8B1"],
  ["#164863", "#427D9D", "#9BBEC8", "#DDF2FD"],
  ["#264653", "#2A9D8F", "#E9C46A", "#F4A261", "#E76F51"],
  ["#E63946", "#F1FAEE", "#A8DADC", "#457B9D", "#1D3557"],
  ["#CDB4DB", "#FFC8DD", "#FFAFCC", "#BDE0FE", "#A2D2FF"],
  ["#355070", "#6D597A", "#B56576", "#E56B6F", "#EAAC8B"],
];

type Props = {
  color: string;
  setColor: (color: string) => void;
};

export function ColorPickerButton({ color, setColor }: Props) {
  const hexCodeInputId = useId();

  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  return (
    <Popover>
      <PopoverTrigger className="flex">
        <ColorCircle color={color} interactive />
      </PopoverTrigger>
      <PopoverContent
        className="p-4 font-outfit"
        side={isMobile ? "top" : "right"}
        sideOffset={16}
        collisionPadding={24}
      >
        <div className="flex flex-col">
          <h4 className="font-medium">Select color</h4>
          <p className="text-sm text-gray-500">
            Enter a hex code or click on a preset color or choose a custom
            color.
          </p>
          <Tabs defaultValue="presets" className="w-full mt-4">
            <TabsList>
              <TabsTrigger value="presets">Presets</TabsTrigger>
              <TabsTrigger value="hex">Hex</TabsTrigger>
              <TabsTrigger value="custom">Custom</TabsTrigger>
            </TabsList>
            <TabsContent value="hex">
              <div className="mt-4 flex items-center gap-4">
                <Label
                  className="text-base font-medium text-primary"
                  htmlFor={hexCodeInputId}
                >
                  Hex code
                </Label>
                <HexColorInput
                  className={clsx("flex-1 uppercase", INPUT_CLASSNAMES)}
                  id={hexCodeInputId}
                  prefixed
                  color={color}
                  onChange={setColor}
                />
              </div>
            </TabsContent>
            <TabsContent value="presets">
              <div className="mt-4 flex flex-col gap-2">
                {PRESET_COLOR_PALETTES.map((palette, index) => (
                  <div key={index} className="flex gap-2">
                    {palette.map((color) => (
                      <div
                        key={color}
                        onClick={() => {
                          setColor(color);
                        }}
                      >
                        <ColorCircle color={color} interactive />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </TabsContent>
            <TabsContent value="custom">
              <div className="mt-4 lit-colorpicker">
                <HexColorPicker color={color} onChange={setColor} />
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </PopoverContent>
    </Popover>
  );
}

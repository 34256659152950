import { Button } from "@/components/ui/button";
import { CaptionStylesConfig } from "../../caption-styles";
import { CaptionThemeCard } from "./theme-card";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";

type Props = {
  captionStyle: string;
  setCaptionStyle: (style: string) => void;
};

const QUICK_SELECT_STYLES = 3;

function getQuickSelectStyles(selectedStyle: string) {
  const selectedStyleIndex = CaptionStylesConfig.findIndex(
    (style) => style.key === selectedStyle
  );
  if (selectedStyleIndex < 0 || selectedStyleIndex < QUICK_SELECT_STYLES) {
    return CaptionStylesConfig.slice(0, QUICK_SELECT_STYLES);
  }
  return [
    CaptionStylesConfig[selectedStyleIndex],
    ...CaptionStylesConfig.slice(0, QUICK_SELECT_STYLES - 1),
  ];
}

export function CaptionThemeSelectorCarousel({
  captionStyle,
  setCaptionStyle,
}: Props) {
  const cardRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  useEffect(() => {
    if (!isMobile) {
      // This causes jump on mobile, so just doing this on desktop
      cardRefs.current[captionStyle]?.scrollIntoView({
        behavior: "instant",
      });
    }
  }, [isMobile]);

  return (
    <div className="flex items-stretch overflow-x-scroll horizontal-visible-scrollbar pb-4">
      <div className="flex-1 flex justify-between">
        {CaptionStylesConfig.map((styleConfig) => (
          <CaptionThemeCard
            ref={(el: HTMLDivElement) => {
              cardRefs.current[styleConfig.key] = el;
            }}
            key={styleConfig.key}
            styleConfig={styleConfig}
            selected={captionStyle === styleConfig.key}
            onClick={() => setCaptionStyle(styleConfig.key)}
          />
        ))}
      </div>
    </div>
  );
}

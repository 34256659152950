import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAuth } from "@/src/helpers/auth.hook";

export function EmailSignupCard() {
  const { signupWithEmail } = useAuth();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    const email = form.email.value;
    const password = form.password.value;
    await signupWithEmail(email, password);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Card className="w-full min-w-48">
        <CardHeader>
          <CardTitle className="text-2xl">Sign up with email</CardTitle>
          <CardDescription>
            Enter your email and choose a password
          </CardDescription>
        </CardHeader>
        <CardContent className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="name@company.com"
              required
            />
          </div>
          <div className="grid gap-2">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              placeholder="••••••••"
              required
            />
          </div>
        </CardContent>
        <CardFooter>
          <Button className="w-full" variant="default" type="submit">
            Sign up
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
}

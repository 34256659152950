import { PlayerRef, CallbackListener } from "@remotion/player";
import { useCallback, useEffect, useState } from "react";

const LOG_PLAYER_EVENTS = false && process.env.NODE_ENV === "development";

export function usePlayer(
  playerRef: React.RefObject<PlayerRef>,
  { durationInFrames, fps }: { durationInFrames: number; fps: number },
  { listenForTimeUpdate = false } = {}
) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isBuffering, setIsBuffering] = useState(false);

  const togglePlay = useCallback(() => {
    if (playerRef.current) {
      if (playerRef.current.isPlaying()) {
        playerRef.current.pause();
      } else {
        playerRef.current.play();
      }
    }
  }, [playerRef]);

  const seekTo = useCallback(
    (time: number) => {
      if (playerRef.current) {
        playerRef.current.seekTo(time * fps);
      }
    },
    [playerRef, fps]
  );

  const requestFullscreen = useCallback(() => {
    if (playerRef.current) {
      playerRef.current.requestFullscreen();
    }
  }, [playerRef]);

  useEffect(() => {
    if (!playerRef.current) {
      return;
    }
    setDuration(durationInFrames / fps);
    const onPlay: CallbackListener<"play"> = () => {
      LOG_PLAYER_EVENTS && console.log("playing");
      setIsPlaying(true);
    };
    const onRateChange: CallbackListener<"ratechange"> = (e) => {
      LOG_PLAYER_EVENTS && console.log("ratechange", e.detail.playbackRate);
    };
    const onVolumeChange: CallbackListener<"volumechange"> = (e) => {
      LOG_PLAYER_EVENTS && console.log("new volume", e.detail.volume);
    };

    const onPause: CallbackListener<"pause"> = () => {
      LOG_PLAYER_EVENTS && console.log("pausing");
      setIsPlaying(false);
    };

    const onSeeked: CallbackListener<"seeked"> = (e) => {
      LOG_PLAYER_EVENTS && console.log("seeked to " + e.detail.frame);
    };

    const onTimeupdate: CallbackListener<"timeupdate"> = (e) => {
      LOG_PLAYER_EVENTS && console.log("time has updated to " + e.detail.frame);
      setCurrentTime(e.detail.frame / fps);
    };

    const onEnded: CallbackListener<"ended"> = () => {
      LOG_PLAYER_EVENTS && console.log("ended");
      setIsPlaying(false);
    };

    const onError: CallbackListener<"error"> = (e) => {
      LOG_PLAYER_EVENTS && console.log("error", e.detail.error);
      setIsPlaying(false);
    };

    const onFullscreenChange: CallbackListener<"fullscreenchange"> = (e) => {
      LOG_PLAYER_EVENTS &&
        console.log("fullscreenchange", e.detail.isFullscreen);
      setIsFullscreen(e.detail.isFullscreen);
    };

    const onScaleChange: CallbackListener<"scalechange"> = (e) => {
      LOG_PLAYER_EVENTS && console.log("scalechange", e.detail.scale);
    };

    const onMuteChange: CallbackListener<"mutechange"> = (e) => {
      LOG_PLAYER_EVENTS && console.log("mutechange", e.detail.isMuted);
    };

    playerRef.current.addEventListener("play", onPlay);
    playerRef.current.addEventListener("ratechange", onRateChange);
    playerRef.current.addEventListener("volumechange", onVolumeChange);
    playerRef.current.addEventListener("pause", onPause);
    playerRef.current.addEventListener("ended", onEnded);
    playerRef.current.addEventListener("error", onError);
    playerRef.current.addEventListener("fullscreenchange", onFullscreenChange);
    playerRef.current.addEventListener("scalechange", onScaleChange);
    playerRef.current.addEventListener("mutechange", onMuteChange);

    // See below for difference between `seeked` and `timeupdate`
    playerRef.current.addEventListener("seeked", onSeeked);
    if (listenForTimeUpdate) {
      playerRef.current.addEventListener("timeupdate", onTimeupdate);
    }

    return () => {
      // Make sure to clean up event listeners
      if (playerRef.current) {
        playerRef.current.removeEventListener("play", onPlay);
        playerRef.current.removeEventListener("ratechange", onRateChange);
        playerRef.current.removeEventListener("volumechange", onVolumeChange);
        playerRef.current.removeEventListener("pause", onPause);
        playerRef.current.removeEventListener("ended", onEnded);
        playerRef.current.removeEventListener("error", onError);
        playerRef.current.removeEventListener(
          "fullscreenchange",
          onFullscreenChange
        );
        playerRef.current.removeEventListener("scalechange", onScaleChange);
        playerRef.current.removeEventListener("mutechange", onMuteChange);
        playerRef.current.removeEventListener("seeked", onSeeked);
        if (listenForTimeUpdate) {
          playerRef.current.removeEventListener("timeupdate", onTimeupdate);
        }
      }
    };
  }, [listenForTimeUpdate, fps, durationInFrames, playerRef]);

  return {
    isPlaying,
    isBuffering,
    isFullscreen,
    currentTime,
    duration,
    seekTo,
    togglePlay,
    requestFullscreen,
  };
}

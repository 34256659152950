import { Link } from "react-router-dom";
import { CircleUser, Film, Folders, Menu } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Logo } from "../components/logo";
import { CHECKOUT_SESSION_URL } from "../helpers/billing";
import { useUserStore } from "../stores/user.store";
import { useAuth } from "../helpers/auth.hook";
import { useEffect } from "react";
import { cn } from "@/lib/utils";
import { User, UserCredits } from "../types";
import { useManagedApi } from "../helpers/managed-api.hook";

type Props = {
  activePage?: "projects" | "exports";
  children: React.ReactNode;
};

type SidebarProps = {
  activePage: string;
  credits: UserCredits;
  freeMinutesRemaining: number;
};

type UserMenuProps = {
  user: User;
  logout: () => Promise<void>;
};

type DesktopSidebarProps = SidebarProps & UserMenuProps;

export default function LayoutWithSidebar({ activePage, children }: Props) {
  const { logout } = useAuth();

  const { user, credits, fetchUser } = useUserStore();

  const fetchUserM = useManagedApi(fetchUser);

  useEffect(() => {
    fetchUserM();
  }, []);

  const freeMinutesRemaining = credits
    ? Math.max(
        Math.round((credits.secondsAllocated - credits.secondsUsed) / 60),
        0
      )
    : 0;

  return (
    <div className="grid min-h-svh w-full md:grid-cols-[280px_1fr]">
      <DesktopSidebar
        activePage={activePage}
        credits={credits}
        freeMinutesRemaining={freeMinutesRemaining}
        user={user}
        logout={logout}
      />
      <div className="flex flex-col min-w-0">
        <header className="fixed w-full flex h-14 items-center gap-4 bg-muted px-4 md:hidden z-50">
          <MobileSidebar
            activePage={activePage}
            credits={credits}
            freeMinutesRemaining={freeMinutesRemaining}
          />
          <div className="flex h-14 items-center px-4 flex-1 justify-center">
            <Link to="/" className="flex items-center gap-2 font-semibold">
              <Logo withText />
            </Link>
          </div>
          <UserMenu user={user} logout={logout} />
        </header>
        <main className="flex flex-1 pt-14 md:pt-0 overflow-auto">
          {children}
        </main>
      </div>
    </div>
  );
}

const UserMenu = ({ user, logout }: UserMenuProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" size="icon" className="rounded-full">
          <CircleUser className="h-5 w-5" />
          <span className="sr-only">Toggle user menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>
          {!!user ? user.name || user.email : "Account"}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => logout()}>Logout</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const MobileSidebar = ({
  activePage,
  credits,
  freeMinutesRemaining,
}: SidebarProps) => {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="icon" className="shrink-0 md:hidden">
          <Menu className="h-5 w-5" />
          <span className="sr-only">Toggle navigation menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="flex flex-col bg-muted">
        <div className="flex h-14 items-center px-4 lg:h-[60px] lg:px-6">
          <Link to="/" className="flex items-center gap-2 font-semibold">
            <Logo withText />
          </Link>
        </div>
        <div className="flex-1">
          <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
            <Link
              to="/projects"
              className={cn(
                "flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary",
                activePage === "projects" && "text-primary bg-slate-300/20"
              )}
            >
              <Folders className="h-4 w-4" />
              Projects
            </Link>
            <Link
              to="/exports"
              className={cn(
                "flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary",
                activePage === "exports" && "text-primary bg-slate-300/20"
              )}
            >
              <Film className="h-4 w-4" />
              Exports
            </Link>
          </nav>
        </div>
        <div className="mt-auto">
          <Card>
            {credits && (
              <CardHeader>
                <CardDescription>
                  {credits.free ? "Free minutes" : "Minutes"} remaining:{" "}
                  {freeMinutesRemaining}
                </CardDescription>
              </CardHeader>
            )}
            <CardContent>
              <form action={CHECKOUT_SESSION_URL} method="POST">
                <Button size="sm" className="w-full">
                  Buy minutes
                </Button>
              </form>
            </CardContent>
          </Card>
        </div>
      </SheetContent>
    </Sheet>
  );
};

const DesktopSidebar = ({
  activePage,
  credits,
  freeMinutesRemaining,
  user,
  logout,
}: DesktopSidebarProps) => {
  return (
    <div className="hidden bg-muted/40 md:block">
      <div className="flex h-full max-h-svh flex-col gap-2">
        <div className="flex h-14 items-center px-4 lg:h-[60px] lg:px-6">
          <Link to="/" className="flex items-center gap-2 font-semibold">
            <Logo withText />
          </Link>
        </div>
        <div className="flex-1">
          <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
            <Link
              to="/projects"
              className={cn(
                "flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary",
                activePage === "projects" && "text-primary bg-muted"
              )}
            >
              <Folders className="h-4 w-4" />
              Projects
            </Link>
            <Link
              to="/exports"
              className={cn(
                "flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary",
                activePage === "exports" && "text-primary bg-muted"
              )}
            >
              <Film className="h-4 w-4" />
              Exports
            </Link>
          </nav>
        </div>
        <div className="mt-auto px-4 py-2">
          <Card x-chunk="dashboard-02-chunk-0">
            {credits && (
              <CardHeader className="p-2 pt-0 md:p-4">
                <CardDescription>
                  {credits.free ? "Free minutes" : "Minutes"} remaining:{" "}
                  {freeMinutesRemaining}
                </CardDescription>
              </CardHeader>
            )}
            <CardContent className="p-2 pt-0 md:p-4 md:pt-0">
              <form action={CHECKOUT_SESSION_URL} method="POST">
                <Button size="sm" className="w-full">
                  Buy minutes
                </Button>
              </form>
            </CardContent>
          </Card>
        </div>
        <div className="border-t py-2">
          <nav className="flex flex-col px-2 text-sm font-medium">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Link
                  to="#"
                  className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary"
                >
                  <CircleUser className="h-4 w-4" />
                  {!!user ? user.name || user.email : "Account"}
                </Link>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => logout()}>
                  Logout
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </nav>
        </div>
      </div>
    </div>
  );
};

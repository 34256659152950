import { SequencesRenderer } from "@/src/components/caption-renderer/sequences-renderer";
import {
  CaptionStyleComponentProps,
  CaptionStyleSpec,
  ColorPropertyValue,
  PropertyValue,
} from "@/src/types";
import { useMemo } from "react";
import Color from "color";

const Properties = {
  textColor: {
    label: "Text",
    propertyType: "color",
  },
  shadowColor: {
    label: "Shadow",
    propertyType: "color",
  },
} as const;
type Property = keyof typeof Properties;

export const SlantPhrasesSpec = {
  properties: Properties,
} satisfies CaptionStyleSpec<Property>;

export function SlantPhrasesDefaultValues(): Record<Property, PropertyValue> {
  return {
    textColor: "#FDE157",
    shadowColor: "#000000",
  };
}

export function SlantPhrasesComponent({
  values,
  phrases,
}: CaptionStyleComponentProps<Property>) {
  const textColor = values.textColor as ColorPropertyValue;
  const shadowColor = values.shadowColor as ColorPropertyValue;
  if (!textColor || !shadowColor) {
    console.error("Missing required options");
    return null;
  }
  const shadowRgb = useMemo(() => {
    const rgb = Color(shadowColor).rgb().array();
    if (!rgb) {
      console.error("Invalid shadow color");
      return null;
    }
    return rgb;
  }, [shadowColor]);

  const dropShadowColor = `rgba(${shadowRgb?.join(", ")}, 1)`;
  return (
    <SequencesRenderer
      sequences={phrases}
      rootClassName="px-8 py-16 pb-64"
      sequenceTextClassName="text-center text-[72px] px-10 py-10 rounded-[32px] leading-[1.2]"
      renderWord={(word, index, highlighted) => (
        <span
          key={index}
          style={{
            fontFamily: "Josefin Sans",
            fontSize: 96,
            fontStyle: "italic",
            fontWeight: 600,
            color: textColor,
            // drop shadow
            filter: `drop-shadow(-1px 1px 0px ${dropShadowColor}) drop-shadow(-1px 1px 0px ${dropShadowColor}) drop-shadow(1px 4px 0px ${dropShadowColor}) drop-shadow(1px 1px 0px ${dropShadowColor}) drop-shadow(1px 1px 0px ${dropShadowColor})`,
          }}
        >
          {word.text}{" "}
        </span>
      )}
    />
  );
}

import { useToast } from "@/components/ui/use-toast";
import { Loader2Icon } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getExports } from "../api/lit-captions";
import { ExportWithDetails } from "../types";
import Layout from "./layout";
import { useManagedApi } from "../helpers/managed-api.hook";

export default function ExportsPage() {
  const [exports, setExports] = useState<ExportWithDetails[]>([]);
  const [loading, setLoading] = useState(true);

  const getExportsM = useManagedApi(getExports);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const exports = await getExportsM();
        setExports(exports);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Layout activePage="exports">
      <div className="flex justify-center flex-1">
        <div className="flex-1 max-w-4xl px-8 pt-8 pb-4 flex flex-col gap-8">
          <div className="flex justify-between">
            <h1>Exports</h1>
          </div>
          {loading ? (
            <div className="flex items-center gap-2">
              <Loader2Icon className="w-6 h-6 animate-spin" />
              <p>Fetching your exports...</p>
            </div>
          ) : exports.length > 0 ? (
            <div className="flex gap-10 flex-wrap overflow-auto max-h-[80svh] vertical-visible-scrollbar">
              {exports.map((exportData) => {
                const { id, snapshotUrl, project } = exportData;
                return (
                  <div
                    key={id}
                    className="group flex flex-col gap-1 justify-start w-min items-center relative"
                  >
                    <Link to={`/exports/${id}`} className="flex flex-col gap-1">
                      <div
                        className="h-[320px] w-[180px] bg-slate-100 rounded-md bg-cover"
                        style={
                          snapshotUrl && {
                            // snapshot as background
                            backgroundImage: `url(${snapshotUrl})`,
                          }
                        }
                      ></div>
                      <small
                        className="line-clamp-2 text-wrap truncate text-left"
                        title={project.name}
                      >
                        {project.name}
                      </small>
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm">
              <div className="flex flex-col items-center gap-2 text-center">
                <p className="text-sm text-muted-foreground">
                  You haven't exported any of your projects yet.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

import { UnauthorizedError } from "../api/lit-captions";
import { useCallback, useRef } from "react";
import { useAuthStore } from "../stores/auth.store";
import { useToast } from "@/components/ui/use-toast";

export function useManagedApi<T extends (...args: any[]) => Promise<any>>(
  apiCall: T
): T {
  const setLoggedIn = useAuthStore((state) => state.setLoggedIn);

  const apiCallRef = useRef(apiCall);

  // This is fine
  if (apiCallRef.current !== apiCall) {
    apiCallRef.current = apiCall;
  }

  return useCallback(
    async (...args: Parameters<T>): Promise<Awaited<ReturnType<T>>> => {
      try {
        return await apiCallRef.current(...args);
      } catch (error) {
        console.error(error);
        if (error instanceof UnauthorizedError) {
          setLoggedIn(false);
        }
        throw error;
      }
    },
    [setLoggedIn]
  ) as T;
}

import { Link, useSearchParams } from "react-router-dom";
import Layout from "./layout";
import { Button } from "@/components/ui/button";

export function PostCheckout() {
  const [searchParams] = useSearchParams();

  const success = searchParams.get("success");
  const canceled = searchParams.get("canceled");
  return (
    <Layout>
      <div className="flex justify-center flex-1">
        <div className="flex-1 px-8 pt-8 pb-4 flex flex-col gap-8">
          <div className="space-y-4">
            <h1>
              {success
                ? "Payment successful"
                : canceled
                  ? "Payment canceled"
                  : "???"}
            </h1>
            <div>
              {success && (
                <span className="text-green-700">
                  Thank you for your purchase!
                </span>
              )}
              {canceled && (
                <span className="text-red-500">Your payment was canceled.</span>
              )}
              {!success && !canceled && (
                <span className="text-gray-800">
                  We don't know why you're here. You shouldn't be.
                </span>
              )}
            </div>
          </div>
          <Link to="/">
            <Button variant="default">Go to Dashboard</Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

import { Link } from "react-router-dom";
import Layout from "./layout";
import { Button } from "@/components/ui/button";

export function NotFound() {
  return (
    <Layout>
      <div className="flex justify-center flex-1">
        <div className="flex-1 px-8 mt-40 pb-4 flex flex-col items-center gap-8">
          <div className="flex justify-between">
            <h1>Page not found</h1>
          </div>
          <Link to="/">
            <Button variant="default">Back to Home</Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
}
